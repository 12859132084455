import Vue from 'vue'
import Router from 'vue-router'
import store from "../store";
import tool from '@/assets/js/tool'

Vue.use(Router)


const router = new Router({
  mode: 'hash',
  base: '/',
  routes: [
    {
      path: "/",
      redirect: "/entrance",
    },
    {
      path: '/index',
      component: resolve => require(['@/components/entrance'], resolve),
      meta: {
        keepAlive: true
      }
    },
    //页面入口 entrance
    {
      path: '/entrance',
      component: resolve => require(['@/components/entrance'], resolve),
      meta: {
        keepAlive: false
      }
    },
    //下载中心
    {
      path: '/downloadCenter',
      component: resolve => require(['@/components/downloadCenter'], resolve),
      meta: {
        keepAlive: false
      }
    },
    //Video tutorial 视频教程
    {
      path: '/tutorial',
      component: resolve => require(['@/components/tutorial'], resolve),
      meta: {
        keepAlive: false
      }
    },
    // 效率工具
    {
      path: '/fficiencyTools',
      component: resolve => require(['@/components/fficiencyTools'], resolve),
      meta: {
        keepAlive: false
      }
    },
    {
      path: '/PT',
      component: resolve => require(['@/components/trans'], resolve),
      meta: {
        keepAlive: false
      }
    },
    {
      path: '/PS',
      component: resolve => require(['@/components/patentSearch'], resolve),
      meta: {
        keepAlive: false
      }
    },
    //hostingPlatform 中小微企业知识产权托管平台
    {
      path: '/hostingPlatform',
      component: resolve => require(['@/components/hostingPlatform'], resolve),
      meta: {
        keepAlive: false
      }
    },
    //maturity 中小微企业知识产权托管平台
    {
      path: '/maturity',
      component: resolve => require(['@/components/hostingPlatform/maturity'], resolve),
      meta: {
        keepAlive: false
      }
    },
    //商标大数据
    {
      path: "/brand",
      component: resolve => require(['@/components/brand'], resolve),
      // meta: {
      //   keepAlive: true,
      //   activity: true
      // },
    },
    //活动页
    {
      path: "/activity",
      component: resolve => require(["@/components/activity"], resolve),
      meta: {
        activity: true,
        keepAlive: true
      },
    },
    //活动落地成功
    {
      path: "/activitySuccess",
      component: resolve => require(["@/components/activitySuccess"], resolve),
      meta: {
        activity: true,
        keepAlive: true
      },
    },
    //可注册性分析报告
    {
      path: "/analysisReport",
      name: "analysisReport",
      component: resolve => require(["@/components/analysisReport"], resolve),
      meta: {
        keepAlive: true
      }
    },
    //招聘信息
    {
      path: "/recruitment",
      component: resolve => require(["@/components/recruitment"], resolve),
      meta: {
        activity: true,
        keepAlive: true
      },
    },
    //商标监控
    {
      path: "/TM",
      name: 'TM',
      component: resolve => require(["@/components/TrademarkMonitoring"], resolve),
      meta: {
        keepAlive: false
      }
    },
    //商标监控详情页
    {
      path: "/monitoringResult",
      component: resolve => require(["@/components/TrademarkMonitoring/monitoringResult"], resolve),
      meta: {
        keepAlive: false
      }
    },
    //商标监控结果页
    {
      path: "/monitorReport",
      component: resolve => require(["@/components/TrademarkMonitoring/monitorReport"], resolve),
      meta: {
        keepAlive: false
      }
    },
    //商标管理系统
    {
      path: "/TMS",
      component: resolve => require(["@/components/trademarkManagementSystem"], resolve),
      meta: {
        keepAlive: true
      }
    },
    //专利管理系统
    {
      path: "/PMS",
      component: resolve => require(["@/components/PatentManagementSystem"], resolve),
      meta: {
        keepAlive: true
      }
    },
    //智慧园区 Smart Park
    {
      path: "/smartPark",
      component: resolve => require(["@/components/smartPark"], resolve),
      meta: {
        keepAlive: true
      }
    },
    //法律文书
    {
      path: "/instrument",
      component: resolve => require(["@/components/instrument"], resolve)
    },
    //商标购买页
    {
      path: "/trademarkApply",
      component: resolve => require(["@/components/pay/translatePay"], resolve),
      meta: {
        keepAlive: false
      }
    },
    // 商标购买付款页
    {
      path: "/trademarkPayment",
      component: resolve => require(["@/components/pay/trademarkPayment"], resolve),
      meta: {
        keepAlive: false
      }
    },
    //对公支付  payment
    {
      path: "/payment",
      component: resolve => require(["@/components/pay/payment"], resolve),
      meta: {
        keepAlive: false
      }
    },
    //对公支付成功---上传凭证成功 uploadSuccee
    {
      path: "/uploadSuccee",
      component: resolve => require(["@/components/pay/uploadSuccee"], resolve),
      meta: {
        keepAlive: false
      }
    },
    {
      path: '/patentSearch/list',
      component: resolve => require(['@/components/patentSearch/list'], resolve),
      meta: {
        keepAlive: false
      }
    },
    {
      path: '/patentSearch/detail',
      component: resolve => require(['@/components/patentSearch/detail'], resolve),
      meta: {
        keepAlive: false
      }
    },
    {
      path: '/PQ',
      component: resolve => require(['@/components/query'], resolve),
      meta: {
        keepAlive: false
      }
    },
    {
      //官文对比
      path: '/contrast',
      component: resolve => require(['@/components/query/contrast'], resolve),
      meta: {
        keepAlive: false
      }
    },
    //改版商标查询
    {
      path: '/nPQ',
      component: resolve => require(['@/components/query/newIndex'], resolve),
      meta: {
        keepAlive: false
      }
    },
    //支持查询的国家
    {
      path: '/supportCountry',
      component: resolve => require(['@/components/supportCountry'], resolve),
      meta: {
        keepAlive: false
      }
    },
    //组合查询
    {
      path: '/combinedQuery',
      component: resolve => require(['@/components/combinedQuery'], resolve),
      meta: {
        keepAlive: false
      }
    },
    {
      path: '/query/detail',
      component: resolve => require(['@/components/query/detail'], resolve),
      meta: {
        keepAlive: false
      }
    },
    {
      path: '/query/announcementDetail',
      component: resolve => require(['@/components/query/announcementDetail'], resolve),
      meta: {
        keepAlive: false
      }
    },
    {
      path: '/query/otherTrademarkArchives',
      component: resolve => require(['@/components/query/otherTrademarkArchives'], resolve),
      meta: {
        keepAlive: false
      }
    },
    {
      path: '/AU',
      component: resolve => require(['@/components/about'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      path: '/services',
      name: 'services',
      component: resolve => require(['@/components/services'], resolve)
    },
    {
      path: '/annualFeeDetail',
      name: 'annualFeeDetail',
      component: resolve => require(['@/components/hostingPlatforms/annualFeeDetail'], resolve),
      meta: {
        keepAlive: true,
        activity: true
      }
    },
    {
      path: "/journalDetail",
      component: resolve => require(["../components/journal/details"], resolve),
      meta: {
        keepAlive: false
      }
    },
    {
      path: "/judgementDocumentDetail",
      component: resolve => require(["../components/judgementDocumentDetail"], resolve),
      meta: {
        keepAlive: false
      }
    },
    //AI产品落地页 aiLanding
    {
      path: "/aiLanding",
      component: resolve => require(["../components/aiLanding"], resolve),
      meta: {
        keepAlive: false
      }
    },
    //AI智能撰写 Intelligent Writing
    {
      path: "/IntelligentWriting",
      component: resolve => require(["../components/IntelligentWriting"], resolve),
      meta: {
        keepAlive: false
      }
    },
    //AI智能撰写 Intelligent Writing
    {
      path: "/IntelligentWriting/AI",
      component: resolve => require(["../components/IntelligentWriting/AI"], resolve),
      meta: {
        keepAlive: false
      }
    },
    {
      path: '/hostingPlatforms',
      component: resolve => require(['@/components/hostingPlatforms'], resolve),
      redirect: "/hostingPlatforms/home",
      children: [
        {
          path: '/hostingPlatforms/home',
          component: resolve => require(['@/components/hostingPlatforms/home'], resolve),
          meta: {
            activity: true,
            keepAlive: false
          }
        },
        {
          path: '/hostingPlatforms/annualFee',
          component: resolve => require(['@/components/hostingPlatforms/annualFee'], resolve),
          meta: {
            activity: true,
            keepAlive: false
          }
        },
        {
          path: '/hostingPlatforms/trademark',
          component: resolve => require(['@/components/hostingPlatforms/trademark'], resolve),
          meta: {
            activity: true,
            keepAlive: false
          }
        },
        {
          path: '/hostingPlatforms/softness',
          component: resolve => require(['@/components/hostingPlatforms/softness'], resolve),
          meta: {
            activity: true,
            keepAlive: false
          }
        },
        {
          path: '/hostingPlatforms/qualifications',
          component: resolve => require(['@/components/hostingPlatforms/qualifications'], resolve),
          meta: {
            activity: true,
            keepAlive: false
          }
        },
        {
          path: '/hostingPlatforms/institution',
          component: resolve => require(['@/components/hostingPlatforms/institution'], resolve),
          meta: {
            activity: true,
            keepAlive: false
          }
        },
        {
          path: '/hostingPlatforms/platformPay',
          component: resolve => require(['@/components/pay/platformPay'], resolve),
          meta: {
            activity: true,
            keepAlive: false
          }
        },
        //Information upload
        {
          path: '/hostingPlatforms/informationupload',
          component: resolve => require(['@/components/pay/informationupload'], resolve),
          meta: {
            activity: true,
            keepAlive: false
          }
        },
        {
          path: '/hostingPlatforms/myRenewal',
          component: resolve => require(['@/components/hostingPlatforms/myRenewal'], resolve),
          meta: {
            activity: true,
            keepAlive: false
          }
        },
        {
          path: '/hostingPlatforms/myAnnualFee',
          component: resolve => require(['@/components/hostingPlatforms/myAnnualFee'], resolve),
          meta: {
            activity: true,
            keepAlive: false
          },

        },
        //年份订单
        {
          path: '/hostingPlatforms/myAnnualFeeOrder',
          component: resolve => require(['@/components/hostingPlatforms/myAnnualFeeOrder'], resolve),
          meta: {
            activity: true,
            keepAlive: false
          }
        },
        //商标订单
        {
          path: '/hostingPlatforms/myRenewalOrder',
          component: resolve => require(['@/components/hostingPlatforms/myRenewalOrder'], resolve),
          meta: {
            activity: true,
            keepAlive: false
          }
        },
        {
          path: '/hostingPlatforms/trademark/bs',
          component: resolve => require(['@/components/hostingPlatforms/bsProcessTrademark'], resolve),
          meta: {
            keepAlive: false
          }
        },
        {
          path: '/hostingPlatforms/annualFee/bs',
          component: resolve => require(['@/components/hostingPlatforms/bsProcessAnnualFee'], resolve),
          meta: {
            keepAlive: false
          }
        }
      ]
    },
    { //专利详情页
      path: '/hostingPlatforms/patentDetail',
      component: resolve => require(['@/components/hostingPlatforms/patentDetail'], resolve),
      meta: {
        keepAlive: false
      }
    },
    {
      path: '/personalCenter',
      component: resolve => require(['@/components/personalCenter'], resolve),
      redirect: "/personalCenter/account",
      children: [
        {
          path: '/personalCenter/account',
          component: resolve => require(['@/components/personalCenter/account'], resolve),
          meta: {
            keepAlive: false
          }
        },
        {
          path: '/personalCenter/productService',
          component: resolve => require(['@/components/personalCenter/productService'], resolve),
          meta: {
            keepAlive: false
          }
        },
        {
          path: '/personalCenter/myServer',
          component: resolve => require(['@/components/personalCenter/myServer'], resolve),
          meta: {
            keepAlive: false
          }
        },
        {
          path: '/personalCenter/myOrder',
          component: resolve => require(['@/components/personalCenter/myOrder'], resolve),
          meta: {
            keepAlive: false
          }
        },
        {
          path: '/personalCenter/myInvoice',
          component: resolve => require(['@/components/personalCenter/myInvoice'], resolve),
          meta: {
            keepAlive: false
          }
        },
        {
          path: '/personalCenter/trademarkLog',
          component: resolve => require(['@/components/personalCenter/trademarkLog'], resolve),
          meta: {
            keepAlive: false
          }
        },
        {
          path: '/personalCenter/tLog',
          component: resolve => require(['@/components/personalCenter/tLog'], resolve),
          meta: {
            keepAlive: false
          }
        },
        {
          path: '/personalCenter/pLog',
          component: resolve => require(['@/components/personalCenter/pLog'], resolve),
          meta: {
            keepAlive: false
          }
        }
      ]
    },
    {
      path: '/changePassword',
      component: resolve => require(['@/components/account/changePassword'], resolve),
      meta: {
        keepAlive: false
      }
    },
    {
      path: '/modidyOrAuth',
      component: resolve => require(['@/components/account/modidyOrAuth'], resolve),
      meta: {
        keepAlive: false
      }
    },
    {
      path: '/legal',
      component: resolve => require(['@/components/legal'], resolve),
      redirect: '/legal/privacy',
      children: [{
        path: '/legal/privacy',
        component: resolve => require(['@/components/legal/privacy'], resolve),
        meta: {
          keepAlive: false
        }
      },
        {
          path: '/legal/term',
          component: resolve => require(['@/components/legal/term'], resolve),
          meta: {
            keepAlive: false
          }
        }
      ]
    },
    {
      path: '/pay/pay',
      component: resolve => require(['@/components/pay/pay'], resolve),
      meta: {
        keepAlive: false
      }
    },
    {
      path: '/pay/orderInfo',
      component: resolve => require(['@/components/pay/orderInfo'], resolve),
      meta: {
        keepAlive: false
      }
    },
    {
      path: '/pay/orderInfos',
      component: resolve => require(['@/components/pay/orderInfos'], resolve),
      meta: {
        keepAlive: false
      }
    },
    {
      path: '/pay/paySuccess',
      component: resolve => require(['@/components/pay/paySuccess'], resolve),
      meta: {
        keepAlive: false
      }
    },
    //trusteeshipPaySuccess
    {
      path: '/pay/trusteeshipPaySuccess',
      component: resolve => require(['@/components/pay/trusteeshipPaySuccess'], resolve),
      meta: {
        keepAlive: false
      }
    },
    {
      path: '/paySuccess',
      component: resolve => require(['@/components/pay/paySuccess006'], resolve),
      meta: {
        keepAlive: false
      }
    },
    {
      path: '/callback',
      component: resolve => require(['@/components/common/callback'], resolve),
      meta: {
        keepAlive: false
      }
    },
    {
      path: '/TD',
      component: resolve => require(['@/components/trademarkData/index'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      path: '/interpretPay',
      component: resolve => require(['@/components/pay/interpretPay'], resolve),
      meta: {
        keepAlive: false
      }
    },
    {
      path: '/pdfOnlinePreview',
      component: resolve => require(['@/components/onlinePreview/pdfOnlinePreview'], resolve),
      meta: {
        keepAlive: false
      }
    },
    {
      path: '/trademarkRejectionAI',
      component: resolve => require(['@/components/trademarkRejectionAi/TrademarkRejectionGenerate'], resolve),
      meta: {
        keepAlive: false
      }
    },
    {
      path: '/trademarkObjection',
      component: resolve => require(['@/components/trademarkRejectionAi/TrademarkObjection'], resolve),
      meta: {
        keepAlive: false
      }
    },
    {
      path: '/patentOA',
      component: resolve => require(['@/components/patentOAAi/patentOA'], resolve),
      meta: {
        activity: false
      }
    },
    {
      path: '/patentOASummaryRreviewOpinions',
      component: resolve => require(['@/components/patentOAAi/patentOASummaryReviewOpinions'], resolve),
      meta: {
        activity: false
      }
    },
    //新申请 New Application
    {
      path: '/oaApplication',
      component: resolve => require(['@/components/oaApplication/index'], resolve),
      meta: {
        activity: false
      }
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  }
})

// 解决vue项目路由出现message: "Navigating to current location (XXX) is not allowed"的问题
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

//是否更改头部导航条颜色
let headerTransBGArr = [
  '/', '/index', '/PI', '/AU', '/index/', '/PI/', '/AU/', '/journal', '/trademarkSearch', '/SC', '/BP', '/TI', '/TMS',
  '/AS', '/patentTranslation', '/psApi', '/PMS', '/PAMS', '/SPB', '/OCR', '/smartPark', '/digitalPlatform', '/enterpriseNetwork', '/EHR', '/solutions', '/patentSolutions', '/collaborationPlatform'
  , '/TD', '/AU', '/recruitment'
]
// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆

router.beforeEach((to, from, next) => {
  //路由切换要去掉loading
  store.commit("loadingBool", false);

  //看token是否存在
  let token = tool.getCookie("auth");
  //如果token不存在
  if (token == undefined || token == 'null' || token == '') {
    //用户登陆消息
    store.commit('setMe', '');
    //用户通知消息
    store.commit('setNoticeList', '');
    next();
  } else {
    next();
  }
  //设置导航条的背景颜色
  if (headerTransBGArr.includes(to.path)) {
    store.commit('setHeaderBgBool', true)
  } else {
    store.commit('setHeaderBgBool', false)
  }
});

router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
});


// router.afterEach((to, from, next) => {
//   // window.pageYOffset = 0
//   //window.scrollTo(0, 0)
//   document.body.scrollTop = 0
//   document.documentElement.scrollTop = 0;
// })


export default router;
