/*190129加入show0129,chosen0129*/
export let classifyData =
  [
    {
      "no": "01",
      "name": "化学品及肥料",
      "nameEn": "Industrial chemicals",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "02",
      "name": "颜料漆料涂料",
      "nameEn": "Pigments, paints, resin",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "03",
      "name": "洁护品化妆品",
      "nameEn": "Cosmetics, cleaning products",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "04",
      "name": "油脂照明燃料",
      "nameEn": "Industrial oils and fuels",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "05",
      "name": "医药婴儿食品",
      "nameEn": "Pharamaceuticals",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "06",
      "name": "金属五金器具",
      "nameEn": "Metals",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "07",
      "name": "机器马达部件",
      "nameEn": "Machines and machine tools",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "08",
      "name": "刀叉手工器具",
      "nameEn": "Cutlery, hand tools",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "09",
      "name": "电子声影防护",
      "nameEn": "Scientific, electronic devices",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "10",
      "name": "医疗护理器械",
      "nameEn": "Medical and nursing apparatus",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "11",
      "name": "照明烹饪卫生",
      "nameEn": "Lights, AC, heating, santiaries",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "12",
      "name": "运载工具部件",
      "nameEn": "Vehicles",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "13",
      "name": "火器军火烟火",
      "nameEn": "Firearms, fireworks",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "14",
      "name": "珠宝首饰钟表",
      "nameEn": "Jewelry, precious stones, watches",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "15",
      "name": "乐器及其配件",
      "nameEn": "Musical instruments",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "16",
      "name": "办公教学用品",
      "nameEn": "Office supplies, teaching materials",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "17",
      "name": "塑料绝缘材料",
      "nameEn": "Plastic and rubber products",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "18",
      "name": "箱包皮具皮革",
      "nameEn": "Leather products ",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "19",
      "name": "非金属的建材",
      "nameEn": "Non-metallic building materials",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "20",
      "name": "家具容器骨石",
      "nameEn": "Furnitures, containers, bone and horn",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "21",
      "name": "家居厨房器具",
      "nameEn": "Household and kitchen utensils",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "22",
      "name": "绳网布帆填料",
      "nameEn": "Ropes, nets, tents, canvas",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "23",
      "name": "纺织用纱和线",
      "nameEn": "Yarns and threads for textile use",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "24",
      "name": "日常用纺织品",
      "nameEn": "Textile for daily use",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "25",
      "name": "服装服饰鞋帽",
      "nameEn": "Clothing, footwear, headgear",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "26",
      "name": "花边配饰假发",
      "nameEn": "Lace, embroidery, false hair",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "27",
      "name": "地板料覆盖物",
      "nameEn": "Carpet, mats, wallpaper",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "28",
      "name": "游戏运动器具",
      "nameEn": "Games and toys",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "29",
      "name": "蔬果肉蛋奶油",
      "nameEn": "Cooked vegetables/fruits, meat/egg/cream",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "30",
      "name": "咖啡面点调料",
      "nameEn": "Coffee, pastry, condiments",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "31",
      "name": "农园林水产品",
      "nameEn": "Fresh fruit, vegetables, flowers",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "32",
      "name": "啤酒饮料糖浆",
      "nameEn": "Beer, beverages, syrups",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "33",
      "name": "烈酒含酒饮料",
      "nameEn": "Alcohol",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "34",
      "name": "烟草烟具火柴",
      "nameEn": "Tobacco, smoking articles",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "35",
      "name": "广告商业事务",
      "nameEn": "Advertising, office affairs",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "36",
      "name": "保险金融服务",
      "nameEn": "Insurance, financial affairs",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "37",
      "name": "建筑修理安装",
      "nameEn": "Construction, repair, installation",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "38",
      "name": "电信通信服务",
      "nameEn": "Telecommunications",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "39",
      "name": "运输旅行安排",
      "nameEn": "Transport, storage",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "40",
      "name": "材料水气处理",
      "nameEn": "Material treatment",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "41",
      "name": "教育娱乐文体",
      "nameEn": "Education, entertainment",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "42",
      "name": "设计咨询开发",
      "nameEn": "Design, consulting, development",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "43",
      "name": "餐饮住宿看管",
      "nameEn": "Food, accommodation, nursing",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "44",
      "name": "医疗美容园林",
      "nameEn": "Medical, beauty, horticulture services",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    },
    {
      "no": "45",
      "name": "法律社会服务",
      "nameEn": "Legal, security services",
      "isCheck": false,
      "isQuery": false,
      "show0129":false,
      "chosen0129":false,
    }
  ]
