import { stateBase } from './index'
import deepClone from '../../../../public/js/utils'
export default {
    // switchFn(state, parameter) {
    //   state.switch = parameter;
    // },
    queryItemsShowFn(state, parameter) {
        if (parameter && parameter.reset) {
            state.queryItemsShow = JSON.parse(JSON.stringify(stateBase.queryItemsShow))
        } else {
            Object.assign(state.queryItemsShow, parameter)
        }
        // console.log(JSON.stringify(state.queryItemsShow), 'state.queryItemsShow')
    },
    queryDataFn(state, parameter) {
        if (parameter && parameter.reset) {
            state.queryData = JSON.parse(JSON.stringify(stateBase.queryData))
        } else {
            Object.assign(state.queryData, parameter)
        }
        // console.log(state.queryData.countryList, 'state.queryData+++++++++++++++++++++++++++++')
    },
    ajaxResponseFn(state, parameter) {
        if (parameter && parameter.reset) {
            state.ajaxResponse = JSON.parse(JSON.stringify(stateBase.ajaxResponse))
        } else {
            Object.assign(state.ajaxResponse, parameter)
        }
    },
    responseDataFn(state, parameter) {
        if (parameter && parameter.reset) {
            state.responseData = JSON.parse(JSON.stringify(stateBase.responseData))
        } else {
            Object.assign(state.responseData, parameter)
        }
    },
    othersItemFn(state, parameter) {
        if (parameter && parameter.reset) {
            state.othersItem = JSON.parse(JSON.stringify(stateBase.othersItem))
        } else {
            Object.assign(state.othersItem, parameter)
        }
    },
    pageNum_Fn(state, parameter) {
        state.pageNum_ = parameter
    },
    pageSize_Fn(state, parameter) {
        state.pageSize_ = parameter
    },
    noResetFn(state, parameter) {
        if (parameter && parameter.reset) {
            // state.noReset=JSON.parse(JSON.stringify(stateBase.noReset));//不让重置,所以注释掉
        } else {
            Object.assign(state.noReset, parameter)
        }
    },
    queryBtnClickNumFn(state, parameter) {
        state.queryBtnClickNum = parameter
    },
    landForShowDateFn(state, parameter) {
        state.landForShowDate = parameter
    },
    /* 公共 */
    setClassifyShowFn(state) {
        // 如果用户没选择分类,则默认全部展示,反之,则展示选中的
        let length = state.queryData.classify.filter(item => item.isCheck).length
        state.queryData.classify.map(item => {
            if (length == 0) {
                item.show0129 = true
            } else {
                item.show0129 = item.isCheck
            }
            item.chosen0129 = false
        })
    },
    // 获取历史记录
    queryHistoryFn(state, parameter) {
        state.queryHistory = parameter
    },
    asideFormFn(state, parameter) {
        state.asideForm = parameter
    },
    applyPersonFn(state, parameter) {
        state.applyPerson = JSON.parse(JSON.stringify(parameter))
    },
    isFirstQueryFn(state, parameter) {
        state.queryData.isFirstQuery = parameter;
    },

}
