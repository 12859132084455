import Vue from 'vue'
import Vuex from 'vuex'
import tool from '@/assets/js/tool'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import modules from './modules'
Vue.use(Vuex)
    // 首先声明一个需要全局维护的状态 state,比如 我这里举例的resturantName
const state = {
    resturantName: 'lanternfish', // 默认值
    locale: 'cn',
    token: tool.getCookie("auth") ? tool.getCookie("auth") : '',
    me: null,
    // 通知消息
    noticeList: null,
    // 导航背景是否透明
    headerBgBool: true,
    LOADING: false,
    adSearchLogs:undefined,
    //专利翻译
    patentTranslation: true,
    //商标
    trademark: true,
    //专利检索
    patentSearch: true,
    //来源
    userSource: {
      name: 'app',
      token: 'YXBwOmFwcA==',
      path: 'pages/auth/index'
    },
    tmPay: null
}

// 注册上面引入的各大模块
const store = new Vuex.Store({
    state, // 共同维护的一个状态，state里面可以是很多个全局状态
    getters, // 获取数据并渲染
    actions, // 数据的异步操作
    mutations, // 处理数据的唯一途径，state的改变或赋值只能在这里
    modules
})

export default store // 导出store并在 main.js中引用注册。
