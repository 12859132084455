import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import zhCN from 'vxe-table/lib/locale/lang/zh-CN'
const cn = {
  "translationText": '专利翻译',
  "searchText": '专利检索',
  "queryText": '商标查询',
  "headers": {
    "navList": [
      {
        "text": "首页",
        "path": "/index",
        "position": 15,
        'showBool': true,
      },
      {
        "text": "商标",
        "path": "/solutions",
        "position": 15,
        'showBool': true,
        children: [
          {
            "text": "在线应用",
            "subTilte": true,
            'showBool': true
          }, {
            "text": "商标查询",
            "subTilte": false,
            "path": "/trademarkSearch",
            'showBool': true
          } , {
            "text": "法律文书",
            "subTilte": false,
            "path": "/Notice",
            'showBool': true
          }, {
            "text": "商品分类",
            "subTilte": false,
            "path": "/PQ?id=3",
            'showBool': true
          },{
            "text": "商标监控",
            "subTilte": false,
            "path": "/SC",
            'showBool': true
          }, {
            "text": "品牌保护",
            "subTilte": false,
            "path": "/BP",
            'showBool': true
          }, {
            "text": "API",
            "subTilte": true,
            'showBool': true
          }, {
            "text": "商标查询API",
            "subTilte": false,
            "path": "/TI",
            'showBool': true
          }, {
            "text": "流程化管理",
            "subTilte": true,
            'showBool': true
          }, {
            "text": "商标管理系统",
            "subTilte": false,
            "path": "/TMS",
            'showBool': true
          }
          // {
          //   "text": "效率工具",
          //   "subTilte":true,
          //   'showBool': true
          // },{
          //   "text": "商标自动化提交官申",
          //   "subTilte":false,
          //   // "path": "/AS",
          //   'showBool': true
          // }
        ]
      },
      {
        "text": "专利",
        "path": "/patentSolutions",
        "position": 15,
        'showBool': true,
        children: [
          {
            "text": "在线应用",
            "subTilte": true,
            'showBool': true
          }, {
            "text": "专利翻译",
            "subTilte": false,
            "path": "/patentTranslation",
            'showBool': true
          }, {
            "text": "专利检索",
            "subTilte": false,
            "path": "/searchTntroduction",
            'showBool': true
          }, {
            "text": "API",
            "subTilte": true,
            'showBool': true
          }, {
            "text": "专利翻译API",
            "subTilte": false,
            "path": "/psApi",
            'showBool': true
          }, {
            "text": "流程化管理",
            "subTilte": true,
            'showBool': true
          }, {
            "text": "专利管理系统",
            "subTilte": false,
            "path": "/PMS",
            'showBool': true
          }, {
            "text": "专利年费管理系统",
            "subTilte": false,
            "path": "/PAMS",
            'showBool': true
          },
          {
            "text": "效率工具",
            "subTilte": true,
            'showBool': true
          }, {
            "text": "机翻Trados插件",
            "subTilte": false,
            "path": "/Trados",
            'showBool': true
          }, {
            "text": "蓝灯鱼机翻word插件",
            "subTilte": false,
            "path": "/TradosWord",
            'showBool': true
          }, {
            "text": "蓝灯鱼机翻Excel插件",
            "subTilte": false,
            "path": "/Excel",
            'showBool': true
          }, {
            "text": "专利五书拆分",
            "subTilte": false,
            "path": "/SPB",
            'showBool': true
          }, {
            "text": "专利CPC附图一键压缩",
            "subTilte": false,
            "path": "/patentCPC",
            'showBool': true
          }
        ]
      },
      {
        "text": "数智平台",
        "path": "/collaborationPlatform",
        "position": 15,
        'showBool': true,
        children: [
          {
            "text": "智慧园区",
            "subTilte": false,
            "path": "/smartPark",
            'showBool': true
          }, {
            "text": "企业网盘订制",
            "subTilte": false,
            "path": "/enterpriseNetwork",
            'showBool': true
          }
          // {
          //   "text": "数字化平台",
          //     "subTilte":false,
          //   // "path": "/digitalPlatform",
          //   'showBool': true
          // }

          // ,{
          //   "text": "EHR管理系统订制(事务所版)",
          //     "subTilte":false,
          //   // "path": "/EHR",
          //   'showBool': true
          // }
        ]
      },
      {
        "position": 15,
        "text": "商标大数据",
        "path": "/brand",
        "showBool": true
      },
      {
        "position": 15,
        "text": "企业动态",
        "path": "/TD",
        "showBool": true
      },
      {
        "position": 15,
        "text": "关于我们",
        "path": "/AU",
        "showBool": true
      }
    ],
    "login": "登录 / 注册",
    "vxLogin": "绑定",
    "dropDownMenu": [{
      "text": "个人中心",
      "path": "/personalCenter"
    },
      {
        "text": "退出登录",
        "path": ""
      }
    ],
    'noData': '暂无通知消息'
  },
  "logins": {
    "fild": ['请输入手机号', '请输入密码', '立即注册', '忘记密码', '快捷登录', '请设置6位以上密码', '注册', '下一步', '请输入手机号'],
    "fild_sub": ['请再次输入密码', '提交', '登录', '用户名和密码登录', '注册完成', '修改成功', '返回登录', '已有账号，返回'],
    "form": {
      "phone": {
        "placeholder": "请输入手机号",
        "errorMessage": "手机号错误"
      },
      "code": {
        "placeholder": "请输入验证码",
        "errorMessage": "验证码错误"
      },
      "sendCodeText": "获取验证码",
      "resendText": "重新发送"
    },
    "AgreementBtn": "我已阅读并接受《蓝灯鱼用户协议》",
    "errorAgreeMessage": "请确认用户协议",
    "wechatDesc": "为了保证你账号的安全和正常使用，依《网络安全法》相关要求，账号需绑定手机",
    "tips": "请使用微信扫描二维码登录",
    "confirmTips": "提示：此手机号与其他微信存在绑定关系，请先去解绑",
    "confirmTipsText": '提示',
    "confirmButtonText": "去登录",
    "cancelButtonText": '取消'
  },
  "xfooter": {
    'title1': '关注我们',
    'title2': '业务咨询',
    'content': [
      [
        {
          img: '/images/index/cx.jpg',
          subTitle: '商标解决方案',
          path: '/solutions'
        },
        {
          img: '/images/index/cx.jpg',
          subTitle: '商标查询',
          path: '/trademarkSearch'
        },
        {
          img: '/images/index/jk.jpg',
          subTitle: '商标监控',
          path: '/SC'
        },
        {
          img: '/images/index/bh.jpg',
          subTitle: '品牌保护',
          path: '/BP'
        },
        {
          img: '/images/index/api.jpg',
          subTitle: '商标查询API',
          path: '/TI'
        },
        {
          img: '/images/index/gl.jpg',
          subTitle: '商标管理系统',
          path: '/TMS'
        }
      ],
      [
        {
          img: '/images/index/zhuanli1.png',
          subTitle: '专利解决方案',
          path: '/patentSolutions'
        },
        {
          img: '/images/index/zhuanli1.png',
          subTitle: '专利翻译',
          path: '/patentTranslation'
        },
        {
          img: '/images/index/zhuanli1.png',
          subTitle: '专利检索',
          path: '/searchTntroduction'
        },
        {
          img: '/images/index/zhuanli2.png',
          subTitle: '专利翻译API',
          path: '/psApi'
        },
        {
          img: '/images/index/zhuanli3.png',
          subTitle: '专利管理系统',
          path: '/PMS'
        },
        {
          img: '/images/index/zhuanli4.png',
          subTitle: '专利年费管理系统',
          path: '/PAMS'
        }, {
        subTitle: '机翻Trados插件',
        path: "/Trados"
      }, {
        subTitle: '蓝灯鱼机翻word插件',
        path: "/TradosWord"
      }, {
        subTitle: '蓝灯鱼机翻Excel插件',
        path: "/Excel"
      }, {
        subTitle: '专利五书拆分',
        path: "/SPB"
      }, {
        subTitle: '专利CPC附图一键压缩',
        path: "/patentCPC"
      }
      ],
      [
        {
          img: '/images/index/shuju1.png',
          subTitle: '数据协作平台',
          path: '/collaborationPlatform'
        },
        {
          img: '/images/index/shuju1.png',
          subTitle: '智慧园区',
          path: '/smartPark'
        },
        {
          img: '/images/index/shuju3.png',
          subTitle: '企业网盘订制',
          path: '/enterpriseNetwork'
        }
      ]
    ],
  },
  "index": {
    'bannerTitle1': '数字赋能知识产权, 智能驱动创新发展',
    'describe': "专注知产行业，提供业务场景解决方案",
    "more": '了解更多',
    'type': [
      {
        mold: '限时试用',
        describe: '专利翻译',
        // path: '/patentTranslation'
        path: '/PT'
      },
      {
        mold: '热门产品',
        describe: '商标查询',
        // path: '/trademarkSearch'
        path: '/PQ'
      },
      {
        mold: '免费体验',
        describe: '专利检索',
        // path: '/searchTntroduction'
        path: '/PS'
      },
      {
        mold: '立即查看',
        describe: '服务定价',
        path: '/personalCenter/productService'
      }
    ],
    'product_title': '产品与服务',
    'product': [
      {
        name: '商标',
        isShow: true,
        left: 26
      },
      {
        name: '专利',
        isShow: false,
        left: 26
      },
      {
        name: '数据协作',
        isShow: false,
        left: 52
      }
    ],
    'content': [
      [
        {
          img: '/images/cancat/trademark1.jpg',
          subTitle: '商标查询',
          path: '/trademarkSearch'
        },
        {
          img: '/images/cancat/trademark2.jpg',
          subTitle: '商标监控',
          path: '/SC'
        },
        {
          img: '/images/cancat/trademark3.jpg',
          subTitle: '品牌保护',
          path: '/BP'
        },
        {
          img: '/images/cancat/trademark4.jpg',
          subTitle: '商标查询API',
          path: '/TI'
        },
        {
          img: '/images/cancat/trademark5.jpg',
          subTitle: '商标管理系统',
          path: '/TMS'
        },
        // {
        //   img:'/images/index/tj.jpg',
        //   subTitle:'商标自动提交审核',
        // }
      ],
      [
        {
          img: '/images/cancat/patent6.jpg',
          subTitle: '专利翻译',
          path: '/patentTranslation'
        },
        {
          img: '/images/cancat/patent7.jpg',
          subTitle: '专利检索',
          path: '/searchTntroduction'
        },
        {
          img: '/images/cancat/patent1.jpg',
          subTitle: '专利翻译API',
          path: '/psApi'
        },
        {
          img: '/images/cancat/patent2.jpg',
          subTitle: '专利管理系统',
          path: '/PMS'
        },
        {
          img: '/images/cancat/patent3.jpg',
          subTitle: '专利年费管理系统',
          path: '/PAMS'
        },
        {
          img: '/images/index/split.png',
          subTitle: '机翻Trados插件',
          path: '/Trados'
        },
        {
          img: '/images/index/split.png',
          subTitle: '蓝灯鱼机翻word插件',
          path: '/TradosWord'
        },
        {
          img: '/images/index/split.png',
          subTitle: '蓝灯鱼机翻Excel插件',
          path: '/Excel'
        },
        {
          img: '/images/index/split.png',
          subTitle: '专利五书拆分',
          path: '/SPB'
        },
        {
          img: '/images/index/compress.png',
          subTitle: '专利CPC附图一键压缩',
          path: '/patentCPC'
        }
      ],
      [
        {
          img: '/images/cancat/data1.jpg',
          subTitle: '智慧园区',
          path: '/smartPark'
        },
        // {
        //   img:'/images/index/shuju2.png',
        //   subTitle:'数字化平台',
        //   // path: '/digitalPlatform'
        // },
        {
          img: '/images/cancat/data3.jpg',
          subTitle: '企业网盘订制',
          path: '/enterpriseNetwork'
        }
        // {
        //   img:'/images/index/shuju4.png',
        //   subTitle:'EHR管理系统订制(事务所版)',
        // }
      ]
    ],
    'drive': {
      title: '技术驱动，拓展边界',
      technology: [
        {
          title: '深刻的行业理解',
          img: require('@/assets/images/index/hy.jpg')
        },
        {
          title: '多元化实践验证',
          img: require('@/assets/images/index/dy.jpg')
        },
        {
          title: 'AI技术落地',
          img: require('@/assets/images/index/ai.jpg')
        },
        {
          title: '探索创新边界',
          img: require('@/assets/images/index/js.jpg')
        }
      ]
    },
    'industry': {
      title: '专注知产行业，提供业务场景解决方案',
      patent: [
        {
          title: '商标',
          path: '/solutions'
        },
        {
          title: '专利',
          path: '/patentSolutions'
        },
        {
          title: '数据协作',
          path: '/collaborationPlatform'
        }
      ]
    },
    'patent': [
      {
        title: '商标',
        path: '/solutions'
      },
      {
        title: '专利',
        path: '/patentSolutions'
      },
      {
        title: '数据协作',
        path: '/collaborationPlatform'
      }
    ],
    'contact': {
      title: '联系我们',
      describe: '如需了解详情，您可留言咨询，我们的咨询顾问将为您提供更多的信息和资料',
      btn: '开始咨询'
    }
  },
  "solutions": {
    "bannerTitle1": "商标解决方案",
    'describe': "深入商标领域，提供创新解决方案",
    'introduction': {
      "title": "商标解决方案",
      'describe': "蓝灯鱼提供商标解决方案，目前已形成多个产品，商标查询、商标监控、品牌保护，助力保护企业和开发者权益，商标管理系统、自动化工具等可提高企业管理和工作效率",
    },
    tabs: {
      title1: '在线应用',
      title2: 'API',
      title3: '流程化管理',
      content: [
        [
          {
            path: '/trademarkSearch',
            img: require('@/assets/images/cancat/trademark1.jpg'),
            title: '商标查询'
          },
          {
            path: '',
            img: require('@/assets/images/cancat/trademark1.jpg'),
            title: '法律文书'
          },
          {
            path: '/PQ?id=3',
            img: require('@/assets/images/cancat/trademark1.jpg'),
            title: '商品分类'
          },
          {
            path: '/SC',
            img: require('@/assets/images/cancat/trademark2.jpg'),
            title: '商标监控'
          },
          {
            path: '/BP',
            img: require('@/assets/images/cancat/trademark3.jpg'),
            title: '品牌保护'
          }
        ],
        [
          {
            path: '/TI',
            img: require('@/assets/images/cancat/trademark4.jpg'),
            title: '商标查询API'
          }
        ],
        [
          {
            path: '/TMS',
            img: require('@/assets/images/cancat/trademark5.jpg'),
            title: '商标管理系统'
          }
        ]
      ]
    }
  },
  "trademarkSearch": {
    "bannerTitle1": "商标查询",
    'describe': "全球数据+AI引擎，商标注册有备无患",
    'button': "立即使用",
    'introduction': {
      "title": "产品简介",
      'describe1': '蓝灯鱼商标查询产品是一款基于全球43个国家/地区商标数据，可以覆盖99%以上的企业内外商标注册所需的办案国，同时支持图片查询和文字查询。',
      'describe2': '图片查询：基于全球商标图片，按照维也纳编码分类，对图片进行特征提取和机器学习，通过"以图搜图"AI智能方式查询，准确查找出近似图片。在机器学习、训练图片过程中，加入人工审查标准规则，将人对图片含义、图形结构进行总结，优化到特征提取的算法中，不断优化迭代训练模型。',
    },
    'advantage': {
      title: '产品优势',
      img: require('@/assets/images/patent/introduction1.png'),
      'advantage_content': [
        {
          crux: "43",
          value: '个国家'
        },
        {
          crux: "AI智能",
          value: '引擎'
        },
        {
          crux: "可注册性",
          value: '分析'
        },
        {
          crux: "灵活接入",
          value: '服务'
        }
      ]
    },
    'examples': {
      title: "产品示例",
      lun: [{
        imgSrc: '/images/index/query_index1.png',
        name: '动漫检索'
      }, {
        imgSrc: '/images/index/query_index2.png',
        name: '动物检索'
      }, {
        imgSrc: '/images/index/query_index3.png',
        name: '图形检索'
      }]
    },
    'price': {
      title: '产品定价',
      listData: [
        {
          categoryCode: "shangbiaoyewu",
          categoryName: "商标业务",
          currency: "CNY",
          existApply: false,
          imageUrl: "trademark_query.png",
          modeType: "4",
          price: '全球数据 周期自选',
          productName: "商标查询",
          publishType: "4",
          serviceDesc: "在线应用",
          button: true,
          btnName: "购买",
          serviceName: "商标查询"
        },
        {
          categoryCode: "shangbiaoyewu",
          categoryName: "商标业务",
          currency: "CNY",
          existApply: false,
          imageUrl: "trademark_query.png",
          modeType: "4",
          btnName: "试用",
          price: '全球数据3天，30次',
          productName: "商标查询",
          publishType: "4",
          serviceDesc: "在线应用",
          button: true,
          serviceName: "商标查询"
        },
        {
          categoryCode: "shangbiaoyewu",
          categoryName: "商标业务",
          currency: "CNY",
          existApply: false,
          imageUrl: "number1.png",
          modeType: "4",
          price: '联系我们',
          productName: "本地化部署",
          publishType: "4",
          serviceDesc: "提供商标图片、文字查询AI模型和应用，支持部署到本地，离线使用。",
          button: false,
          serviceName: "商标查询"
        },
        {
          categoryCode: "shangbiaoyewu",
          categoryName: "商标业务",
          currency: "CNY",
          existApply: false,
          imageUrl: "number2.png",
          modeType: "4",
          price: '联系我们',
          productName: "引擎定制",
          publishType: "4",
          serviceDesc: "匹配不同使用场景",
          button: false,
          serviceName: "商标查询"
        },
      ],
    },
    'cancat': '联系我们',
    'table': '信息表',
    'tableName': '《支持查询的国家/地区》',
    'statementPre': '商标申请范围、最新更新时间，详见',
    'statementafter': '免责声明：由于数据更新存在时间差，查询结果仅供参考，不具有法律效力。',
  },
  "SC": {
    "bannerTitle1": "商标监控",
    'describe': "大数据检索监控技术结合AI识图技术",
    'introduction': {
      "title": "产品简介",
      'describe1': '「蓝灯鱼商标监控产品」是一款利用大数据检索监控技术和AI识图技术，可以依据文字、图形、商标类别范围等分类，对比商标公告进行检索比对查找近似商标，以达到有效监控商标和预防恶意抢注的目的。',
    },
    'basicProcess': {
      title: '基础流程',
      img: require('@/assets/images/patent/basicprocess.jpg'),
      basicProcess_cen: [
        {
          value_1: '01',
          value_2: '设定条件',
          value_3: '拉丁文字、中文文字、日文文字、韩文文字或图形商标、类别范围',
        },
        {
          value_1: '02',
          value_2: '持续监控',
          value_3: 'AI智能算法持续监控商标局每期的公告商标，精准识别出相同近似商标',
        },
        {
          value_1: '03',
          value_2: '自动报告',
          value_3: '报告形式反馈监控结果',
        }
      ]
    },
    'advantage': {
      title: '产品优势',
      img: require('@/assets/images/patent/introduction2.png'),
      'advantage_content': [
        {
          crux: "AI算法，以图查图",
          value: '先进的AI识别技术，"以图查图"'
        },
        {
          crux: "商标大数据",
          value: '43个国家/地区商标数据持续更新，覆盖主要商标办案国'
        },
        {
          crux: "模型迭代，官方标准",
          value: '图形AI近似查询以驳回引证标作为模型训练依据，监控结果会更接近官方判定结论'
        }
      ]
    },
    'scenarios': {
      title: '应用场景',
      scenarios_cen: [
        {
          url: require('@/assets/images/patent/part1.jpg'),
          content_1: "监控侵权行为",
          content_2: "尽早发现相同或近似商标，在初审公告期提出异议，及时“截杀”近似商标，提前消除相同或近似商标可能对商标权人造成的潜在危害",
        },
        {
          url: require('@/assets/images/patent/part2.jpg'),
          content_1: "预防商标抢注",
          content_2: "及时了解自身商标被抢注的信息，迅速做出抢注的应对措施，避免因商标被抢注带来的损失",
        }
      ]
    },
    'cancat': '联系我们'
  },
  "BP": {
    "bannerTitle1": "品牌保护",
    'describe': "支持电商监控、域名监控、图片监控以及网页监控4种类型",
    'introduction': {
      "title": "产品简介",
      'describe1': '蓝灯鱼品牌保护产品通过对以互联网为载体的目标产品或标志，通过对全球140+主流电商平台以及1119种域名后缀监控，全网24小时跟踪检索，实现对电商、域名、网页全天候的监控，助力客户自动完成品牌保护行动和侵权证据留存。',
    },
    'basicProcess': {
      title: '基础流程',
      img: require('@/assets/images/patent/basicprocess.jpg'),
      basicProcess_cen: [
        {
          value_1: '01',
          value_2: '设定条件',
          value_3: '填写监控信息',
        },
        {
          value_1: '02',
          value_2: '持续监控',
          value_3: '监控涉嫌侵权商品，对假货信息进行证据保存',
        },
        {
          value_1: '03',
          value_2: '自动报告',
          value_3: '报告形式反馈监控结果',
        }
      ]
    },
    "title": '产品类型',
    "type": [
      {
        title: '电商监控',
        content: '将商品放在电商平台售卖是很多品牌的主要商业模式。假冒伪劣产品却层出不穷，不良商家借机牟取暴利，破坏品牌形象，损害企业利益。蓝灯鱼为客户提供电商监控服务，通过对全球140+主流电商平台监控，全天候监控涉嫌侵权商品，对假货信息进行证据保存，以报告的形式向客户反馈，对于电商平台的侵权假货可一键投诉，资深律师代客户开展维权行动。',
        isShow: true,
        imgSrc: '/images/patent/type1.png'
      },
      {
        title: '域名监控',
        content: '域名和商标一样重要，都是品牌的重要标志。蓝灯鱼覆盖1119种类型的域名后缀，通过品牌关键词查找相同/相似域名，进行7*24h域名监控，形成完整的监控报告向客户反馈，并进行证据留存，以便于客户采取进一步的维权措施。',
        isShow: false,
        imgSrc: '/static/assets/images/patent/type1.png'
      },
      {
        title: '图片监控',
        content: '侵权人利用盗取的logo图片或其他广告图片、产品图片、店铺照片等，损害品牌主的利益，蓝灯鱼通过全网检索查找与客户提供的相同/相似的图片，形成完整的监控报告向客户反馈，并进行证据保存，客户可据此开展进一步的维权行动。',
        isShow: false,
        imgSrc: '/static/assets/images/patent/type1.png'
      },
      {
        title: '网页监控',
        content: '蓝灯鱼7*24h全网智能监控使用客户品牌的网页，不给造假者留任何可趁之机，监控结果以报告的形式向客户反馈，并进行证据保存，监控报告包含涉嫌侵权的网站详细信息：网址，网站截图，侵权链接，IP地址，风险等级等。',
        isShow: false,
        imgSrc: '/static/assets/images/patent/type1.png'
      }
    ],
    'cancat': '联系我们'
  },
  "TI": {
    "bannerTitle1": "商标查询API",
    'describe': "基于全球商标数据，AI算法持续迭代",
    'button': "查看文档",
    'introduction': {
      "title": "产品简介",
      'describe1': '蓝灯鱼商标查询产品是一款基于全球43个国家/地区商标数据，可以覆盖99%以上的企业内外商标注册所需的办案国，同时支持图片查询和文字查询。',
      'describe2': '图片查询：基于全球商标图片，按照维也纳编码分类，对图片进行特征提取和机器学习，通过"以图搜图"AI智能方式查询，准确查找出近似图片。在机器学习、训练图片过程中，加入人工审查标准规则，将人对图片含义、图形结构进行总结，优化到特征提取的算法中，不断优化迭代训练模型。',
    },
    'advantage': {
      title: '产品优势',
      img: require('@/assets/images/patent/introduction.jpg'),
      'advantage_content': [
        {
          crux: "43",
          value: '个国家'
        },
        {
          crux: "AI智能",
          value: '引擎'
        },
        {
          crux: "专业",
          value: '合作伙伴'
        },
        {
          crux: "快速接入",
          value: '服务'
        }
      ]
    },
    'examples': {
      title: "产品示例",
      lun: [{
        imgSrc: '/images/index/query_index1.png',
        name: '动漫检索'
      }, {
        imgSrc: '/images/index/query_index2.png',
        name: '动物检索'
      }, {
        imgSrc: '/images/index/query_index3.png',
        name: '图形检索'
      }]
    },
    'process': {
      url: require("@/assets/images/patent/process.jpg"),
      title: "接入流程",
      content: ['登录', '立即登录', '联系蓝灯鱼', '提供登录账号，创建服务', '获取API密钥', '打开个人中心-账户信息', '开发调试', '查看API文档', '接入成功']
    },
    'cancat': '联系我们'
  },
  "TMS": {
    "bannerTitle1": "商标管理系统",
    'describe': "面向商标代理机构，案件流程全生命周期管理",
    'introduction': {
      "title": "产品简介",
      'describe1': '蓝灯鱼品牌保护产品通过对以互联网为载体的目标产品或标志，通过对全球140+主流电商平台以及1119种域名后缀监控，全网24小时跟踪检索，实现对电商、域名、网页全天候的监控，助力客户自动完成品牌保护行动和侵权证据留存。',
      'describe2': '自身特点：灵活安全的商标案卷及流程数据管理，任务办案时限自动提醒，自动生成报告、信函模板，自动官申提交，便捷的信函模板和文件模板配置，灵活的权限配置。'
    },
    'advantage': {
      'title': '产品优势',
      'advantage_content': ['案件流程全生命周期管理', '专业化', '智能化', '标准化'],
      'url': require('@/assets/images/patent/introduction1.png')
    },
    'price': {
      title: '产品定价',
      listData: [
        {
          categoryCode: "shangbiaoyewu",
          categoryName: "商标业务",
          currency: "CNY",
          existApply: false,
          imageUrl: "patent_mgr_system.png",
          modeType: "4",
          price: '全球数据 周期自选',
          productName: "专利翻译",
          publishType: "4",
          serviceDesc: "流程化管理",
          button: true,
          btnName: "购买",
          serviceName: "商标管理系统"
        }
      ],
    },
    'cancat': '联系我们'
  },
  "patentSolutions": {
    "bannerTitle1": "专利解决方案",
    'describe': "深入专利领域，提供创新解决方案",
    'introduction': {
      "title": "专利解决方案",
      'describe': "蓝灯鱼提供专利解决方案，目前已形成多个产品，专利翻译、专利检索、专利管理系统等，AI赋能，并提供标准化、智能化、高安全性的全流程专利管理",
    },
    tabs: {
      title1: '在线应用',
      title2: 'API',
      title3: '流程化管理',
      title4: '效率工具',
      content: [
        [
          {
            path: '/patentTranslation',
            img: require('@/assets/images/cancat/patent6.jpg'),
            title: '专利翻译'
          },
          {
            path: '/searchTntroduction',
            img: require('@/assets/images/cancat/patent7.jpg'),
            title: '专利检索'
          }
        ],
        [
          {
            path: '/psApi',
            img: require('@/assets/images/cancat/patent1.jpg'),
            title: '专利翻译API'
          }
        ],
        [
          {
            path: '/PMS',
            img: require('@/assets/images/cancat/patent2.jpg'),
            title: '专利管理系统'
          },
          {
            path: '/PAMS',
            img: require('@/assets/images/cancat/patent3.jpg'),
            title: '专利年费管理系统'
          }
        ],
        [
          {
            path: '/Trados',
            img: require('@/assets/images/cancat/split.png'),
            title: '机翻Trados插件'
          },
          {
            path: '/TradosWord',
            img: require('@/assets/images/cancat/split.png'),
            title: '蓝灯鱼机翻word插件'
          },
          {
            path: '/Excel',
            img: require('@/assets/images/cancat/split.png'),
            title: '蓝灯鱼机翻Excel插件'
          },
          {
            path: '/SPB',
            img: require('@/assets/images/cancat/split.png'),
            title: '专利五书拆分'
          },
          {
            path: '/patentCPC',
            img: require('@/assets/images/cancat/compress.png'),
            title: '专利CPC附图一键压缩'
          }
        ]
      ]
    }
  },
  "patentTranslation": {
    "bottom_hover": 'txt/word/pdf(仅限纯文字文件,字数小于2万字)',
    "source_language": '源语言：',
    "downloadFn": '下载结果',
    "choose_tips": "请选择需要下载的格式",
    "confirmDownType": '确认',
    "bannerTitle1": "专利翻译",
    'describe': "高质量翻译，源于对全球1.2亿专利数据的专业清洗和5000万+训练语料提取",
    'button': "立即使用",
    'introduction': {
      "title": "产品简介",
      'describe1': '蓝灯鱼专利翻译产品是基于1.2亿全球专利数据库和专利代理行业20多年专利翻译数据库，超过5000万语料数据提取，通过神经网络机器学习算法，训练出适用于专利申请文件、OA答复、对比文件、无效文件与专利参考文献等专利领域的AI翻译引擎，结果句式更加符合专利行业特点。',
      'describe2': '目前支持中英、英中、中日、日中四个语言方向。',
    },
    'advantage': {
      title: '产品优势',
      img: require('@/assets/images/patent/introduction2.png'),
      'advantage_content': [
        {
          crux: "千万级",
          value: '训练语料'
        },
        {
          crux: "20年",
          value: '行业经验沉淀'
        },
        {
          crux: "灵活多样",
          value: '接入服务'
        }
      ]
    },
    'examples': {
      title: "产品示例",
      lun: [{
        imgSrc: '/images/index/search_en.png',
        name: '专利翻译样例'
      }]
    },
    'price': {
      title: '产品定价',
      listData: [
        {
          categoryCode: "shangbiaoyewu",
          categoryName: "商标业务",
          currency: "CNY",
          existApply: false,
          imageUrl: "patent_translate.png",
          modeType: "4",
          price: '全球数据 周期自选',
          productName: "专利翻译",
          publishType: "4",
          serviceDesc: "在线应用/API",
          button: true,
          btnName: "购买",
          serviceName: "专利翻译"
        },
        {
          categoryCode: "shangbiaoyewu",
          categoryName: "商标业务",
          currency: "CNY",
          existApply: false,
          imageUrl: "number1.png",
          modeType: "4",
          price: '联系我们',
          productName: "本地化部署",
          publishType: "4",
          serviceDesc: "提供翻译模型和应用，支持部署到本地，离线使用。",
          button: false,
          serviceName: "本地化部署"
        },
        {
          categoryCode: "shangbiaoyewu",
          categoryName: "商标业务",
          currency: "CNY",
          existApply: false,
          imageUrl: "number3.png",
          modeType: "4",
          price: '联系我们',
          productName: "定制化训练",
          publishType: "4",
          serviceDesc: "可提供双语句对语料数据定制化训练模型，满足特定领域或语种的需要。",
          button: false,
          serviceName: "定制化训练"
        },
      ],
    },
    'cancat': '联系我们',
    "leftLanguage": [
      {
        "text": "自动检测",
        "languageDirection": "",
        "isActive": true
      },
      {
        "text": "中文",
        "languageDirection": "zh-cn",
        "isActive": false
      },
      {
        "text": "英文",
        "languageDirection": "en-us",
        "isActive": false
      },
      {
        "text": "日文",
        "languageDirection": "ja-jp",
        "isActive": false
      }
    ],
    "translateBtn": "译",
    "rightLanguage": [
      {
        "text": "中文",
        "languageDirection": "zh-cn",
        "isActive": false,
        "isDisable": false
      },
      {
        "text": "英文",
        "languageDirection": "en-us",
        "isActive": false,
        "isDisable": false
      },
      {
        "text": "日文",
        "languageDirection": "ja-jp",
        "isActive": false,
        "isDisable": false
      }
    ],
    "discernLan": [
      {
        "text": "中文",
        "languageDirection": "zh-cn",
        "isHow": false
      },
      {
        "text": "英文",
        "languageDirection": "en-us",
        "isHow": false
      },
      {
        "text": "日文",
        "languageDirection": "ja-jp",
        "isHow": false
      }
    ],
    "languageArr": [
      {
        "leftText": "中",
        "rightText": "英",
        "languageDirection": "zh-cn_en-us",
        "hoverBool": false
      },
      {
        "leftText": "英",
        "rightText": "中",
        "languageDirection": "en-us_zh-cn",
        "hoverBool": false
      },
      {
        "leftText": "中",
        "rightText": "日",
        "languageDirection": "zh-cn_ja-jp",
        "hoverBool": false
      },
      {
        "leftText": "日",
        "rightText": "中",
        "languageDirection": "ja-jp_zh-cn",
        "hoverBool": false
      }
    ],
    "placeholder": "每日免费翻译3000字，文本单次输入<3000字",
    "title": "原文",
    "errorTips": "您输入的字数超过了上限3000字，请删减",
    "origin": "译文（来自机器翻译）",
    "uploadBtn": "上传文档",
    "uploadNewBtn": "上传新文档",
    "cancelBtn": "取消",
    "inTranslation": "正在翻译中...",
    "confirmTitle1": "今日累计翻译已达到10次，可明日再来或者申请试用?",
    "confirmTitle2": "今日累计翻译已达到10次，可明日再来或者登录申请试用?",
    "confirmTitle3": "试用期已结束",
    "errMessage": "暂不支持该类型文件",
    "apply": "申请试用",
    "goLogin": "去登录",
    "tips": "提示",
    "tipsText": "您的翻译字数已用完",
    "goBuy": "去购买"
  },

  "searchTntroduction": {
    "bannerTitle1": "专利检索",
    'describe': "基于1.2亿全球专利数据训练语义模型，支持多种检索类型",
    'button': "立即使用",
    'introduction': {
      "title": "产品简介",
      'describe1': '蓝灯鱼专利检索语义检索产品基于1.2亿+全球专利数据库，结合人工智能自然语言技术、定位并拆解技术特征，通过知识图谱进行上下位同义词扩展，最终提取出精准的检索关键词。通过机器学习算法优化排序，提高专利检索结果的查全率和查准率。',
      'describe2': '蓝灯鱼专利检索同时支持关键词检索、字段检索和批量申请号（公开号）等检索方式。',
    },
    'advantage': {
      title: '产品优势',
      img: require('@/assets/images/patent/introduction1.png'),
      'advantage_content': [
        {
          crux: "1.2亿+",
          value: '全球专利数据'
        },
        {
          crux: "永久免费",
          value: ''
        },
        {
          crux: "语义智能",
          value: '检索'
        },
        {
          crux: "交互",
          value: '易用'
        }
      ]
    },
    'examples': {
      title: "产品示例",
      lun: [{
        imgSrc: '/images/index/searchEasy.png',
        name: '专利检索样例'
      }]
    },
    'cancat': '联系我们',
  },
  //专利翻译API
  "psApi": {
    "bannerTitle1": "专利翻译API",
    'describe': "高质量翻译，源于对全球1.2亿专利数据的专业清洗和5000万+训练语料提取",
    'button': "查看文档",
    'introduction': {
      "title": "产品简介",
      'describe1': '蓝灯鱼专利翻译产品是基于1.2亿全球专利数据库和专利代理行业20多年专利翻译数据库，超过5000万语料数据提取，通过神经网络机器学习算法，训练出适用于专利申请文件、OA答复、对比文件、无效文件与专利参考文献等专利领域的AI翻译引擎，结果句式更加符合专利行业特点。目前支持中英、英中、中日、日中四个语言方向。'
    },
    'advantage': {
      title: '产品优势',
      img: require('@/assets/images/patent/introduction1.png'),
      'advantage_content': [
        {
          value_1: '',
          crux: "千万级",
          value_2: '训练语料'
        },
        {
          value_1: '',
          crux: "20年",
          value: '行业经验沉淀'
        },
        {
          value_1: '灵活',
          crux: "多样接入",
          value_2: '服务'
        },
        {
          value_1: '持续',
          crux: "更新",
          value_2: '迭代'
        }
      ]
    },
    'examples': {
      title: "产品示例",
      lun: [{
        imgSrc: '/images/index/search_en.png',
        name: '专利翻译样例'
      }]
    },
    'process': {
      url: require("@/assets/images/patent/process.jpg"),
      title: "接入流程",
      content: ['登录', '立即登录', '联系蓝灯鱼', '提供登录账号，创建服务', '获取API密钥', '打开个人中心-账户信息', '开发调试', '查看API文档', '接入成功']
    },
    'price': {
      title: "产品定价",
      listData: [
        {
          categoryCode: "shangbiaoyewu",
          categoryName: "商标业务",
          currency: "CNY",
          existApply: false,
          imageUrl: "patent_translate.png",
          modeType: "4",
          price: '全球数据 周期自选',
          productName: "专利翻译",
          publishType: "4",
          serviceDesc: "在线应用/API",
          button: true,
          btnName: "购买",
          serviceName: "专利翻译"
        }
      ],
    },
    'cancat': '联系我们'
  },
  //专利管理系统
  "PMS": {
    "bannerTitle1": "专利管理系统",
    'describe': "面向专利代理机构，案件流程全生命周期管理",
    'introduction': {
      "title": "产品简介",
      'describe1': '蓝灯鱼专利管理系统是一款适用于不同发展阶段代理机构的专利案件全生命周期业务管理系统。',
      'describe2': '自身特点：可定制的标准流程管理、无缝对接官方系统，对接CPC自动收文，智能任务流程触发，稽核内部案件信息；弹性配置规则库，高效管理各种类型专利案件、自动时限监控、灵活信函模板配置、多角色权限管理，多维度综合查询、支持列表自定义精细查询。'
    },
    'advantage': {
      title: '产品优势',
      img: require('@/assets/images/patent/introduction1.png'),
      'advantage_content': [
        {
          value_1: '全生命周期管理'
        },
        {
          value_1: '灵活配置'
        },
        {
          value_1: '智能化'
        },
        {
          value_1: '标准化'
        }
      ]
    },
    'price': {
      title: "产品定价",
      listData: [
        {
          categoryCode: "shangbiaoyewu",
          categoryName: "商标业务",
          currency: "CNY",
          existApply: false,
          imageUrl: "patent_mgr_system.png",
          modeType: "4",
          price: '全球数据 周期自选',
          productName: "专利翻译",
          publishType: "4",
          serviceDesc: "流程化管理",
          button: true,
          btnName: "购买",
          serviceName: "专利管理系统"
        }
      ],
    },
    'cancat': '联系我们'
  },
  //专利年费管理系统
  "PAMS": {
    "bannerTitle1": "专利年费管理系统",
    'describe': "专利年费全生命周期缴费及缴费时限管理",
    'introduction': {
      "title": "产品简介",
      'describe1': '蓝灯鱼年费管理系统是一款可以单独使用或搭配专利管理系统完成国内外专利年费管理和监控的系统。',
      'describe2': '自身特点：自动年费时限监控提醒、自动年费费用计算、自动生成缴费报告、国外年费自定义配置、年费数据批量导入和导出。'
    },
    'advantage': {
      title: '产品优势',
      img: require('@/assets/images/patent/introduction1.png'),
      'advantage_content': [
        {
          value_1: '自定义国内外年费配置'
        },
        {
          value_1: '自动监控缴费提醒'
        },
        {
          value_1: '年费报告自动生成'
        },
        {
          value_1: '年费数据批量导入导出'
        }
      ]
    },
    'price': {
      title: "产品定价",
      listData: [
        {
          categoryCode: "shangbiaoyewu",
          categoryName: "商标业务",
          currency: "CNY",
          existApply: false,
          imageUrl: "annual_fee_mgr_system.png",
          modeType: "4",
          price: '全球数据 周期自选',
          productName: "专利翻译",
          publishType: "4",
          serviceDesc: "流程化管理",
          button: true,
          btnName: "购买",
          serviceName: "专利年费管理系统"
        }
      ],
    },
    'cancat': '联系我们'
  },
  //蓝灯鱼机翻Trados（2019版）插件
  "Trados": {
    "bannerTitle1": "蓝灯鱼机翻Trados（2019版）插件",
    'describe': "基于SDL Trados Studio的机器翻译插件，能够使得Trados软件直接调取「蓝灯鱼机器翻译引擎」",
    'button': '下载安装包',
    'introduction': {
      "title": "产品简介",
      'describe1': '蓝灯鱼机翻Trados插件（以下简称：Lanternfish Trados Plugin <LTP>）是一款基于SDL Trados Studio的机器翻译插件，能够使得Trados软件直接调取「蓝灯鱼机器翻译引擎」可以在翻译过程中低成本快速获取机器翻译结果，实现多语言的批量翻译。'
    },
    'advantage': {
      title: '产品优势',
      img: require('@/assets/images/patent/introduction1.png'),
      'advantage_content': [
        {
          value_1: '翻译效率提升'
        },
        {
          value_1: '降低翻译成本'
        },
        {
          value_1: '兼容性好'
        },
        {
          value_1: '免费送10W字'
        }
      ]
    },
    'productAcquisition': ['产品获取', '蓝灯鱼机翻Trados（2019版）插件', '下载安装包'],
    'cancat': '联系我们'
  },
  //蓝灯鱼机翻word插件
  "TradosWord": {
    "bannerTitle1": "蓝灯鱼机翻word插件",
    'describe': "基于Microsoft Office Word的机器翻译插件，能够使得word软件直接调取「蓝灯鱼机器翻译引擎」",
    'button': '下载安装包',
    'introduction': {
      "title": "产品简介",
      'describe1': '蓝灯鱼机翻Word插件（以下简称：Lanternfish word Plugin <LWP>）是一款基于Microsoft Office Word的机器翻译插件，能够使得word软件直接调取「蓝灯鱼机器翻译引擎」可以在翻译过程中低成本快速获取机器翻译结果，实现多语言的批量翻译。'
    },
    'advantage': {
      title: '产品优势',
      img: require('@/assets/images/patent/introduction1.png'),
      'advantage_content': [
        {
          value_1: '翻译效率提升'
        },
        {
          value_1: '降低翻译成本'
        },
        {
          value_1: '兼容性好'
        },
        {
          value_1: '免费送10W字'
        }
      ]
    },
    'productAcquisition': ['产品获取', '蓝灯鱼机翻Word插件', '下载安装包'],
    'cancat': '联系我们'
  },
  //蓝灯鱼机翻Excel插件
  "Excel": {
    "bannerTitle1": "蓝灯鱼机翻Excel插件",
    'describe': "基于Microsoft Office Excel的机器翻译插件，能够使得Excel软件直接调取「蓝灯鱼机器翻译引擎」",
    'button': '下载安装包',
    'introduction': {
      "title": "产品简介",
      'describe1': '蓝灯鱼机翻Excel插件（以下简称：Lanternfish Excel Plugin <LWP>）是一款基于Microsoft Office Excel的机器翻译插件，能够使得Excel软件直接调取「蓝灯鱼机器翻译引擎」可以在翻译过程中低成本快速获取机器翻译结果，实现多语言的批量翻译。'
    },
    'advantage': {
      title: '产品优势',
      img: require('@/assets/images/patent/introduction1.png'),
      'advantage_content': [
        {
          value_1: '翻译效率提升'
        },
        {
          value_1: '降低翻译成本'
        },
        {
          value_1: '兼容性好'
        },
        {
          value_1: '免费送10W字'
        }
      ]
    },
    'productAcquisition': ['产品获取', '蓝灯鱼机翻Excel插件', '下载安装包'],
    'cancat': '联系我们'
  },
  //专利五书拆分
  "SPB": {
    "bannerTitle1": "专利五书拆分",
    'describe': "自动拆分专利申请文件，符合CPC提交要求",
    'button': '下载安装包',
    'introduction': {
      "title": "产品简介",
      'describe1': '「蓝灯鱼专利五书拆分工具」是一款支持word版专利申请文件自动拆分为符合CPC提交要求的PDF格式“五书”的效率工具。',
      'describe2': '可应用于专利代理机构以及有专利申请业务并自行提交申请的企事业单位及科研院所等；'
    },
    'advantage': {
      title: '产品优势',
      img: require('@/assets/images/patent/introduction1.png'),
      'advantage_content': [
        {
          value_1: '一键拆分'
        },
        {
          value_1: '转换PDF格式'
        },
        {
          value_1: '自动命名'
        },
        {
          value_1: '高效便捷'
        }
      ]
    },
    'productAcquisition': ['产品获取', '蓝灯鱼专利五书拆分', '自动拆分专利申请文件，符合CPC提交要求', '下载安装包'],
    'cancat': '联系我们'
  },
  //专利CPC附图一键压缩
  "patentCPC": {
    "bannerTitle1": "专利CPC附图一键压缩",
    'describe': "一键附图压缩",
    'button': '下载安装包',
    'introduction': {
      "title": "产品简介",
      'describe1': '「蓝灯鱼专利CPC附图一键压缩工具」是一款针对专利申请中附图不符合CPC提交要求时，一键压缩生成符合合格图片的效率工具。',
      'describe2': '可应用于专利代理机构、企事业单位、高校、科研院所处理专利申请附图尺寸时使用；'
    },
    'advantage': {
      title: '产品优势',
      img: require('@/assets/images/patent/introduction1.png'),
      'advantage_content': [
        {
          value_1: '可按专利类型选择压缩要求'
        },
        {
          value_1: '像素可按尺寸和比例调整'
        },
        {
          value_1: '键转换并可转存PDF'
        },
        {
          value_1: '简便易用'
        }
      ]
    },
    'productAcquisition': ['产品获取', '蓝灯鱼专利CPC附图一键压缩', '一键附图压缩', '下载安装包'],
    'cancat': '联系我们'
  },
  //数据协作平台
  "collaborationPlatform": {
    "bannerTitle1": "数据协作平台",
    'describe': "智慧知产、数据互联",
    'introduction': {
      "title": "数据协作平台",
      'describe': "蓝灯鱼基于全球商标数据和1.2亿专利数据，目前已形成多个产品，并且支持企业订制化平台、云盘等",
    },
    type: [
      {
        path: "/smartPark",
        url: require('@/assets/images/cancat/data1.jpg'),
        name: '智慧园区'
      },
      {
        path: "/enterpriseNetwork",
        url: require('@/assets/images/cancat/data3.jpg'),
        name: '企业网盘定制'
      }
    ]
  },
  //智慧园区
  "smartPark": {
    "bannerTitle1": "智慧园区",
    'describe': "实时监控园区企业知识产权动态",
    'button': '立即了解',
    'introduction': {
      "title": "产品简介",
      'describe': "蓝灯鱼智慧园区管理平台是一款实时监控园区企业知识产权动态，园区知识产权保护态势，可用于通过分析园区企业知识产权情况，为园区知识产权服务提供管理工具。",
    },
    advantage: [
      '产品优势',
      '专注知识产权管理和服务',
      '基于大数据和AI技术',
      '全面整合供需方业务需求',
      '平台交互、方便快捷',
      '知识产权的产出管理、服务对接和实时统计监控。'
    ],
    cancat: '联系我们'
  },
  //企业网盘
  "enterpriseNetwork": {
    "bannerTitle1": "企业网盘",
    'describe': "云端共享，安全协作",
    'introduction': {
      "title": "产品简介",
      'describe1': "蓝灯鱼企业网盘提供文件云管理订制服务。通过安全便捷的存储、访问、分享，助力企业实现对文件的全面云管理。",
      'describe2': "蓝灯鱼企业网盘可以整合并将文件能力赋能给企业现有信息系统，如邮件系统、OA、ERP等，成为企业文件入口，并沉淀各系统内日常办公文件；对于企业的不同业务，可根据现有业务系统的不同属性配置元数据，使不同文件夹具有不同业务属性，实现现有业务系统与网盘的上传文件自动对接及信息存储。",
    },
    advantage: [
      '产品优势',
      '共享协作，权限灵活',
      '高效管理文件预览/上传/下载/删除等权限',
      '实时同步和共享资料，提高数据信息流转效率，团队高效协作',
      '搜索强大，快速定位',
      '支持关键字或者文件夹名称搜索，快速定位目标文件',
      '存储加密，数据安全',
      '传输全链路加密，文件安全',
      '登录地址限制，访问安全',
      '企业订制，全面记录',
      '企业订制组织架构管理',
      '全面记录信息，协作者间可通过操作日志查看操作记录'
    ],
    cancat: '联系我们'
  },
  //企业动态
  "TD": {
    "bannerTitle1": "企业动态"
  },
  //关于我们
  'AU': {
    "bannerTitle1": "关于蓝灯鱼",
    "content": {
      title: '关于蓝灯鱼',
      describe: "北京蓝灯鱼智能科技有限公司（以下简称「蓝灯鱼」），成立于2018年，致力于通过对人工智能和大数据的探索和研究，以数字科技的力量赋能知识产权行业。\n" +
        "            「蓝灯鱼」的产品和服务面向全球拥有和使用知识产权的用户，基于全球专利、商标大数据，依据大数据挖掘、人工智能深度学习算法和用户行业特性，提供技术先进、易用便捷的知识产权增值及效率产品。\n" +
        "            「蓝灯鱼」提供产品和服务包括：商标图形查询、专利翻译、专利检索、商标管理系统、专利管理系统、专利年费管理系统、商标自动提交官申、知识产权智慧园区管理、代理机构EHR管理系统、专利检索报告自动生成工具等知识产权系列软件产品。\n" +
        "            「蓝灯鱼」将会一直秉承“数据赋能知识产权、智能驱动创新发展”的发展理念，携手所有伙伴和用户，共同打造知识产权数字智能生态系统，助力产业创新发展。"
    },
    cancat: {
      title: "联系我们",
      cancat_cen: [
        {
          url: require('@/assets/images/cancat/name.jpg'),
          key: "公司名称：",
          value: '北京蓝灯鱼智能科技有限公司'
        },
        {
          url: require('@/assets/images/cancat/address.jpg'),
          key: "公司地址：",
          value: '北京市海淀区知春路甲48号盈都大厦3号楼3单元17C'
        },
        {
          url: require('@/assets/images/cancat/iphone.jpg'),
          key: "联系电话：",
          value: '010-5845 7888'
        },
        {
          url: require('@/assets/images/cancat/email.jpg'),
          key: "客服邮箱：",
          value: 'service@lanternfish.cn'
        },
        {
          url: require('@/assets/images/cancat/scanning.jpg'),
          key: "",
          value: '您可扫码添加我们的企业微信，联系我们。'
        }
      ],
      join: ['加入我们', '招聘信息']
    }
  },
  //商标查询
  'PQ': {
    'searchInput': ['请输入商标名称/申请号/申请人', '组合查询', '请选择', '请上传商标图样，<5MB JPG/PNG/GIF/TIFF格式'],
    'fild': ['是', '否', '有效', '无效', '新增', '温馨提示', '可选多个', '确认', '关闭', '已选条件：', '类别：', '国家：'],
    'queryModes': ['更多', '确认', '重置', '返回', '全部选择', '重置', '数据库', '全部选择', '全部取消'],
    'country': ['亚洲', '欧洲', '北美洲', '南美洲', '非洲', '大洋洲', '知识产权组织', '购买'],
    "accordion": ['申请人名称', '筛选', '清空', '类别', '申请日', '注册日', '商标有效性', '无效', '有效', '商标状态', '代理机构', '可注册分析报告', '第', '类', '近似度'],
    "details_item": ['状态', '申请日', '注册日', '申请人地址', '中文', '申请号', '申请人', '英文', '类别', '商品服务'],
    "right": ['申请人', '申请人地址', '申请号', '类别', '状态', '申请日', '注册日', '去续展', '大图预览'],
    "regno": ['申请号', '申请人', '自救中，暂无结果'],
    "statement": ['商标申请范围、最新更新时间，详见', '《支持查询的国家/地区》', '信息表', '免责声明：由于数据更新存在时间差，查询结果仅供参考，不具有法律效力。'],
    "firstList": [
      {text: "申请人精确", value: 0, param: 2, queryWay: "term", classControl: "", show: false},
      {text: "申请号精确", value: 1, param: 3, queryWay: "term", classControl: "", show: false},
      {text: "名称近似", value: 2, param: 1, queryWay: "", classControl: "", show: true},
      {text: "申请人", value: 3, param: 2, queryWay: "", classControl: "", show: true},
      {text: "图形近似", value: 4, param: "", queryWay: "", classControl: "", show: false},
      {text: "申请号", value: 5, param: 3, queryWay: "", classControl: "", show: true},
      {text: "代理机构", value: 6, param: 4, queryWay: "", classControl: "", show: true},
      {text: "图形要素", value: 7, param: 3, queryWay: "", classControl: "", show: true},
      {text: "商品服务", value: 8, param: 4, queryWay: "", classControl: "", show: true},
    ],//param,queryWay爲後臺需要,classControl爲類名控制需要;
    query_Items: [
      //动态新增 查询下拉列表
      {
        tmParams: "",
        input: "",
        options: [
          //   {
          //   value: "SBMC",
          //   label: "商标名称",
          //   disabled: false
          // },
          //   {
          //     value: "SBTP",
          //     label: "商标图片",
          //     disabled: false
          //   },
          // {
          //   value: "SQRMC",
          //   label: "申请人名称",
          //   disabled: false
          // },
          // {
          //   value: "SQRDZ",
          //   label: "申请人地址",
          //   disabled: false
          // },
          // {
          //   value: "SQH",
          //   label: "申请号",
          //   disabled: false
          // },
          {
            value: 'ZCH',
            label: '注册号',
            disabled: false
          },
          // {
          //   value: "LB",
          //   label: "类别",
          //   disabled: false
          // },
          // {
          //     value: "TXYSBM",
          //     label: "图形要素编码",
          //     disabled: false
          // },
          {
            value: "SQR",
            label: "申请日",
            disabled: false
          },
          {
            value: "ZCR",
            label: "注册日",
            disabled: false
          },
          // {
          //   value: "CSGGR",
          //   label: "初审公告日",
          //   disabled: false
          // },
          //{
          //   value: "ZCGGR",
          //  label: "注册公告日",
          //  disabled: false
          //},
          // {
          //   value: "ZYQDQR",
          //   label: "专用权到期日",queryModes
          //   disabled: false
          // },
          // {
          //   value: "YXQR",
          //   label: "优先权日",
          //   disabled: false
          // },
          // {
          //  value: "DLJG",
          //  label: "代理机构",
          //   disabled: false
          // },
          {
            value: "SBZT",
            label: "商标状态",
            disabled: false
          },
          // {
          //   value: "SFGYSB",
          //   label: "是否共有商标",
          //   disabled: false
          // },
          // {
          //   value: "SFYX",
          //   label: "是否有效",
          //   disabled: false
          // }
        ]
      }
    ],
    query_Itemsd: [
      //动态新增 查询下拉列表
      {
        tmParams: "",
        input: "",
        options: [
          {
            value: "SBMC",
            label: "商标名称",
            disabled: false
          },
          {
            value: "SBTP",
            label: "商标图片",
            disabled: false
          },
          {
            value: "SQRMC",
            label: "申请人名称",
            disabled: false
          },
          // {
          //   value: "SQRDZ",
          //   label: "申请人地址",
          //   disabled: false
          // },
          // {
          //   value: "SQH",
          //   label: "申请号",
          //   disabled: false
          // },
          // {
          //   value: "LB",
          //   label: "类别",
          //   disabled: false
          // },
          // {
          //     value: "TXYSBM",
          //     label: "图形要素编码",
          //     disabled: false
          // },
          {
            value: "SQR",
            label: "申请日",
            disabled: false
          },
          {
            value: "ZCR",
            label: "注册日",
            disabled: false
          },
          // {
          //   value: "CSGGR",
          //   label: "初审公告日",
          //   disabled: false
          // },
          //{
          //   value: "ZCGGR",
          //  label: "注册公告日",
          //  disabled: false
          //},
          // {
          //   value: "ZYQDQR",
          //   label: "专用权到期日",
          //   disabled: false
          // },
          // {
          //   value: "YXQR",
          //   label: "优先权日",
          //   disabled: false
          // },
          // {
          //  value: "DLJG",
          //  label: "代理机构",
          //   disabled: false
          // },
          // {
          //   value: 'ZCH',
          //   label: '注册号',
          //   disabled: false
          // },
          {
            value: "SBZT",
            label: "商标状态",
            disabled: false
          },
          // {
          //   value: "SFGYSB",
          //   label: "是否共有商标",
          //   disabled: false
          // },
          // {
          //   value: "SFYX",
          //   label: "是否有效",
          //   disabled: false
          // }
        ]
      }
    ],
    "dataType": [
      {
        type: 1,
        nameEn: "Industrial chemicals",
        name: '工业用化学品',
        title: '用于工业、科学、摄影、农业、园艺和林业的化学品;未加工人造合成树脂,未加工塑料物质;肥料;灭火用合成物;淬火和焊接用制剂;保存食品用化学品;鞣料;工业用粘合剂'
      },
      {
        type: 2,
        nameEn: "Pigments, paints, resin",
        name: '颜料油漆树脂',
        title: '颜料,清漆,漆;防锈剂和木材防腐剂;着色剂;媒染剂;未加工的天然树脂;绘画、装饰、印刷和艺术用金属箔及金属粉'
      },
      {
        type: 3,
        nameEn: "Cosmetics, cleaning products",
        name: '清洁化妆用品',
        title: '洗衣用漂白剂及其他物料;清洁、擦亮、去渍及研磨用制剂;不含药物的肥皂;香料,香精油,不含药物的化妆品,不含药物的润发乳;不含药物的牙膏'
      },
      {
        type: 4,
        nameEn: "Industrial oils and fuels",
        name: '油脂燃料能源',
        title: '工业用油和油脂;润滑剂;吸收、润湿和粘结灰尘用合成物;燃料（包括马达用燃料）和照明材料;照明用蜡烛和灯芯'
      },
      {
        type: 5,
        nameEn: "Pharamaceuticals",
        name: '医药卫生用品',
        title: '药品,医用和兽医用制剂;医用卫生制剂;医用或兽医用营养食物和物质,婴儿食品;人用和动物用膳食补充剂;膏药,绷敷材料;填塞牙孔用料,牙科用蜡;消毒剂;消灭有害动物制剂;杀真菌剂,除莠剂'
      },
      {
        type: 6,
        nameEn: "Metals",
        name: '金属五金器具',
        title: '普通金属及其合金,金属矿石;金属建筑材料;可移动金属建筑物;普通金属制非电气用缆线;金属小五金具;存储和运输用金属容器;保险箱'
      },
      {
        type: 7,
        nameEn: "Machines and machine tools",
        name: '机器机械设备',
        title: '机器和机床;马达和引擎（陆地车辆用的除外）机器联结器和传动机件（陆地车辆用的除外）非手动农业器具;孵化器;自动售货机'
      },
      {
        type: 8,
        nameEn: "Cutlery, hand tools",
        name: '餐具手工器具',
        title: '手工具和器具（手动的）刀、叉和勺餐具;随身武器;剃刀'
      },
      {
        type: 9,
        nameEn: "Scientific, electronic devices",
        name: '科学电子设备',
        title: '科学、航海、测量、摄影、电影、光学、衡具、量具、信号、检验（监督）、救护（营救）和教学用装置及仪器;处理、开关、转换、积累、调节或控制电的装置和仪器;录制、传送、重放声音或影像的装置;磁性数据载体,录音盘;光盘,DVD 盘和其他数字存储媒介;投币启动装置的机械结构;收银机,计算机器,数据处理装置,计算机;计算机软件;灭火设备'
      },
      {
        type: 10,
        nameEn: "Medical and nursing apparatus",
        name: '医疗护理器械',
        title: '外科、医疗、牙科和兽医用仪器及器械;假肢,假眼和假牙;矫形用物品;缝合用材料;残疾人专用治疗装置;按摩器械;婴儿护理用器械、器具及用品;性生活用器械、器具及用品'
      },
      {
        type: 11,
        nameEn: "Lights, AC, heating, santiaries",
        name: '照明空调卫浴',
        title: '照明、加热、蒸汽发生、烹饪、冷藏、干燥、通风、供水以及卫生用装置'
      },
      {
        type: 12,
        nameEn: "Vehicles",
        name: '交通运输工具',
        title: '运载工具;陆、空、海用运载装置'
      },
      {
        type: 13,
        nameEn: "Firearms, fireworks",
        name: '军火烟火爆竹',
        title: '火器;军火及弹药;爆炸物;烟火'
      },
      {
        type: 14,
        nameEn: "Jewelry, precious stones, watches",
        name: '珠宝首饰钟表',
        title: '贵重金属及其合金;首饰,宝石和半宝石;钟表和计时仪器'
      },
      {
        type: 15,
        nameEn: "Musical instruments",
        name: '乐器及其配件',
        title: '乐器'
      },
      {
        type: 16,
        nameEn: "Office supplies, teaching materials",
        name: '办公教学用品',
        title: '纸和纸板;印刷品;书籍装订材料;照片;文具和办公用品（家具除外）文具用或家庭用粘合剂;艺术家用或绘画用材料;画笔;教育或教学用品;包装和打包用塑料纸、塑料膜和塑料袋;印刷铅字,印版'
      },
      {
        type: 17,
        nameEn: "Plastic and rubber products",
        name: '塑料橡胶制品',
        title: '未加工和半加工的橡胶、古塔胶、树胶、石棉、云母及这些材料的代用品;生产用成型塑料和树脂制品;包装、填充和绝缘用材料;非金属软管和非金属柔性管'
      },
      {
        type: 18,
        nameEn: "Leather products ",
        name: '箱包皮具皮革',
        title: '皮革和人造皮革;毛皮;行李箱和背包;雨伞和阳伞;手杖;鞭、马具和鞍具;动物用项圈、皮带和衣服'
      },
      {
        type: 19,
        nameEn: "Non-metallic building materials",
        name: '非金属的建材',
        title: '非金属的建筑材料;建筑用非金属刚性管;柏油,沥青;可移动非金属建筑物;非金属碑'
      },
      {
        type: 20,
        nameEn: "Furnitures, containers, bone and horn",
        name: '家具容器骨石',
        title: '家具,镜子,相框;存储或运输用非金属容器;未加工或半加工的骨、角、鲸骨或珍珠母;贝壳;海泡石;黄琥珀'
      },
      {
        type: 21,
        nameEn: "Household and kitchen utensils",
        name: '家居厨房器具',
        title: '家用或厨房用器具和容器;梳子和海绵;刷子（画笔除外）制刷材料;清洁用具;未加工或半加工玻璃（建筑用玻璃除外）玻璃器皿、瓷器和陶器'
      },
      {
        type: 22,
        nameEn: "Ropes, nets, tents, canvas",
        name: '绳网帐篷帆布',
        title: '缆和绳;网;帐篷和防水遮布;纺织品或合成纤维材料制遮篷;帆;运输和贮存散装物用麻袋;衬垫和填充材料（纸或纸板、橡胶、塑料制除外）纺织用纤维原料及其替代品'
      },
      {
        type: 23,
        nameEn: "Yarns and threads for textile use",
        name: '纺织用纱和线',
        title: '纺织用纱和线'
      },
      {
        type: 24,
        nameEn: "Textile for daily use",
        name: '日常用纺织品',
        title: '织物及其替代品;家庭日用纺织品;纺织品制或塑料制帘'
      },
      {
        type: 25,
        nameEn: "Clothing, footwear, headgear",
        name: '服装服饰鞋帽',
        title: '服装,鞋,帽'
      },
      {
        type: 26,
        nameEn: "Lace, embroidery, false hair",
        name: '花边配饰假发',
        title: '花边和刺绣品,饰带和编带;纽扣,领钩扣,饰针和缝针;假花;发饰;假发'
      },
      {
        type: 27,
        nameEn: "Carpet, mats, wallpaper",
        name: '地毯席类墙纸',
        title: '地毯,地席,席类,油毡及其他铺地板材料;非纺织品制墙帷'
      },
      {
        type: 28,
        nameEn: "Games and toys",
        name: '游戏运动器具',
        title: '游戏器具和玩具;视频游戏装置;体育和运动用品;圣诞树用装饰品'
      },
      {
        type: 29,
        nameEn: "Cooked vegetables/fruits, meat/egg/cream",
        name: '蔬果肉蛋奶油',
        title: '肉,鱼,家禽和野味;肉汁;腌渍、冷冻、干制及煮熟的水果和蔬菜;果冻,果酱,蜜饯;蛋;奶和奶制品;食用油和油脂'
      },
      {
        type: 30,
        nameEn: "Coffee, pastry, condiments",
        name: '咖啡面点调料',
        title: '咖啡,茶,可可和咖啡代用品;米;食用淀粉和西米;面粉和谷类制品;面包、糕点和甜食;食用冰;糖,蜂蜜,糖浆;鲜酵母,发酵粉;食盐;芥末;醋,沙司（调味品）辛香料;冰'
      },
      {
        type: 31,
        nameEn: "Fresh fruit, vegetables, flowers",
        name: '水果蔬菜花卉',
        title: '未加工的农业、水产养殖业、园艺、林业产品;未加工的谷物和种子;新鲜水果和蔬菜,新鲜芳香草本植物;草木和花卉;种植用球茎、幼苗和种子;活动物;动物的饮食;麦芽'
      },
      {
        type: 32,
        nameEn: "Beer, beverages, syrups",
        name: '啤酒饮料糖浆',
        title: '啤酒;矿泉水和汽水以及其他不含酒精的饮料;水果饮料及果汁;糖浆及其他制饮料用的制剂'
      },
      {
        type: 33,
        nameEn: "Alcohol",
        name: '含酒精的饮料',
        title: '含酒精的饮料（啤酒除外）'
      },
      {
        type: 34,
        nameEn: "Tobacco, smoking articles",
        name: '烟草烟具火柴',
        title: '烟草;烟具;火柴'
      },
      {
        type: 35,
        nameEn: "Advertising, office affairs",
        name: '广告办公销售',
        title: '广告;商业经营;商业管理;办公事务'
      },
      {
        type: 36,
        nameEn: "Insurance, financial affairs",
        name: '保险金融服务',
        title: '保险;金融事务;货币事务;不动产事务'
      },
      {
        type: 37,
        nameEn: "Construction, repair, installation",
        name: '建筑修理安装',
        title: '房屋建筑;修理;安装服务'
      },
      {
        type: 38,
        nameEn: "Telecommunications",
        name: '电信通信服务',
        title: '电信'
      },
      {
        type: 39,
        nameEn: "Transport, storage",
        name: '运输仓储服务',
        title: '运输;商品包装和贮藏;旅行安排'
      },
      {
        type: 40,
        nameEn: "Material treatment",
        name: '材料处理服务',
        title: '材料处理'
      },
      {
        type: 41,
        nameEn: "Education, entertainment",
        name: '教育娱乐文体',
        title: '教育;提供培训;娱乐;文体活动'
      },
      {
        type: 42,
        nameEn: "Design, consulting, development",
        name: '设计咨询开发',
        title: '科学技术服务和与之相关的研究与设计服务;工业分析与研究;计算机硬件与软件的设计与开发'
      },
      {
        type: 43,
        nameEn: "Food, accommodation, nursing",
        name: '饮食住宿看护',
        title: '提供食物和饮料服务;临时住宿'
      },
      {
        type: 44,
        nameEn: "Medical, beauty, horticulture services",
        name: '医疗美容园林',
        title: '医疗服务;兽医服务;人或动物的卫生和美容服务;农业、园艺和林业服务'
      },
      {
        type: 45,
        nameEn: "Legal, security services",
        name: '法律安全服务',
        title: '法律服务;为有形财产和个人提供实体保护的安全服务;由他人提供的为满足个人需要的私人和社会服务'
      }
    ],
    // 亚洲
    cityOptions: [
      {
        city:'中国大陆',
        code:'CN',
        ingSrc:'/images/national/zgdl.jpg'
      },
      {
        city:'中国香港',
        code:'HK',
        ingSrc:'/images/national/zgxg.jpg'
      },
      {
        city:'印度尼西亚',
        code:'ID',
        ingSrc:'/images/national/ydnxy.jpg'
      },
      {
        city:'印度',
        code:'IN',
        ingSrc:'/images/national/yd.jpg'
      },
      {
        city:'日本',
        code:'JP',
        ingSrc:'/images/national/rb.jpg'
      },
      {
        city:'土耳其',
        code:'TR',
        ingSrc:'/images/national/trq.jpg'
      },
      {
        city:'韩国',
        code:'KR',
        ingSrc:'/images/national/hg.jpg'
      },
      {
        city:'中国澳门',
        code:'MO',
        ingSrc:'/images/national/am.jpg'
      },
      {
        city:'马来西亚',
        code:'MY',
        ingSrc:'/images/national/mlxy.jpg'
      },
      {
        city:'菲律宾',
        code:'PH',
        ingSrc:'/images/national/flb.jpg'
      },
      {
        city:'新加坡',
        code:'SG',
        ingSrc:'/images/national/xjp.jpg'
      },
      {
        city:'中国台湾',
        code:'TW',
        ingSrc:'/images/national/zgtw.png'
      },
      {
        city:'泰国',
        code:'TH',
        ingSrc:'/images/national/tgs.jpg'
      },
      {
        city:'阿拉伯联合酋长国',
        code:'AE',
        ingSrc:'/images/national/alq.jpg'
      }
    ],
    // 欧洲
    city: [
      {
        city:'奥地利',
        code:'AT',
        ingSrc:'/images/national/adl.JPG'
      },
      {
        city:'瑞士',
        code:'CH',
        ingSrc:'/images/national/rs.jpg'
      },
      {
        city:'德国',
        code:'DE',
        ingSrc:'/images/national/dg.jpg'
      },
      {
        city:'丹麦',
        code:'DK',
        ingSrc:'/images/national/dm.jpg'
      },
      {
        city:'挪威',
        code: 'NO',
        ingSrc:'/images/national/nw.jpg'
      },
      {
        city:'西班牙',
        code:'ES',
        ingSrc:'/images/national/xby.jpg'
      },
      {
        city:'欧盟',
        code:'EU',
        ingSrc:'/images/national/om.jpg'
      },
      {
        city:'芬兰',
        code:'FI',
        ingSrc:'/images/national/fl.jpg'
      },
      {
        city:'法国',
        code:'FR',
        ingSrc:'/images/national/fg.jpg'
      },
      {
        city:'英国',
        code:'GB',
        ingSrc:'/images/national/yg.jpg'
      },
      {
        city:'葡萄牙',
        code:'PT',
        ingSrc:'/images/national/pty.jpg'
      },
      {
        city:'希腊',
        code:'GR',
        ingSrc:'/images/national/xl.jpg'
      },
      {
        city:'匈牙利',
        code:'HU',
        ingSrc:'/images/national/xyl.jpg'
      },
      {
        city:'爱尔兰',
        code:'IE',
        ingSrc:'/images/national/ael.jpg'
      },
      {
        city:'冰岛',
        code:'IS',
        ingSrc:'/images/national/bd.jpg'
      },
      {
        city:'意大利',
        code:'IT',
        ingSrc:'/images/national/ydl.jpg'
      },
      {
        city:'瑞典',
        code:'SE',
        ingSrc:'/images/national/rd.jpg'
      },
      {
        city:'俄罗斯',
        code:'RU',
        ingSrc:'/images/national/elss.jpg'
      },
      {
        city:'比荷卢经济联盟',
        code:'BX',
        ingSrc:'/images/national/bhl.png'
      }
    ],
    // 北美洲
    cityOptionsd: [
      {
        city:'加拿大',
        code:'CA',
        ingSrc:'/images/national/jnd.jpg'
      },
      {
        city:'墨西哥',
        code:'MX',
        ingSrc:'/images/national/mxg.jpg'
      },
      {
        city:'美国',
        code:'US',
        ingSrc:'/images/national/mg.jpg'
      },
    ],
    // 南美洲
    cityOptionsds: [
      {
        city:'阿根廷',
        code:'AR',
        ingSrc:'/images/national/agt.jpg'
      },
      {
        city:'巴西',
        code:'BR',
        ingSrc:'/images/national/bx.jpg'
      },
      {
        city:'智利',
        code:'CL',
        ingSrc:'/images/national/zl.jpg'
      },
    ],
    // 非洲
    cityOpts: [
      {
        city:'南非',
        code:'ZA',
        ingSrc:'/images/national/nf.jpg'
      }
    ],
    // 大洋洲
    cityds: [
      {
        city:'澳大利亚',
        code:'AU',
        ingSrc:'/images/national/adly.jpg'
      },
      {
        city:'新西兰',
        code:'NZ',
        ingSrc:'/images/national/xxl.jpg'
      }
    ],
    //知识产权组织
    propertyOrganization: [
      {
        city:'世界知识产权组织国际局',
        code:'WO',
        ingSrc:'/images/national/zscq.jpg'
      }
    ],
    //提示
    Tips: ['请上传图片', '请输入文字', '请输入文字或者上传图片', '请输入图形要素编码']
  },
  //商标查询详情——中国大陆
  tra_detail_cn:{
    'btnPdf': 'PDF导出',
    'leftLi': ['图形','申请日期','国际注册日期','后期指定日期','优先权日期','初审公告日期','注册公告日期','专用权期限'],
    'classTable': ['类别','类似群组','商品／服务','业务名称','环节名称','没有前一个商标','没有下一个商标']
  },
  //商标查询详情——国外
  tra_detail_en:{
    'btnPdf': 'PDF导出',
    'leftLi': ['图形', '申请号', '注册号', '初审公告期号', '申请日期', '国际注册日期', '后期指定日期', '优先权日期', '初审公告日期', '注册日期', '专用权期限'],
    'classTable': ['申请人信息', '办案国/地区', '指定国', '联系人信息', '联系人名称', '联系人地址', '类别', '商品／服务', '查看更多', '商标状态'],
    'seniority_box': ['先前权', '国家', '申请号', '注册号', '优先权日期', '申请日期', '注册日期', '国际代码', '状态', '流程', '没有前一个商标', '没有下一个商标'],
    'fild': ['国家/地区',"商标名称","商标类型",'有效期截止日','注册公告日期','申请人国籍', '申请人名称', '申请人地址'],
    'fild_1':['指定国', '联系人国籍','注册日期']
  },
  //商标检索
  "PS": {
    "batch_search_con": ['共检索到', '个结果', '点击下载'],
    "batchFile": ['请输入一段话', '请输入关键词、一段话、号码等', '请输入号码，不超过2000个'],
    "advanced_search_wrap": ['申请号', 'For example：CN123456', '申请日', '开始日期', '结束日期', '公开号', '公开日'],
    "advancedTitle": ['标题/摘要', '例如：汽车', '法律状态', '请选择', '申请人', '例如：清华大学', '发明人', '例如：大张伟', '代理机构', '例如：康信', '代理人', '例如：张雷', 'IPC分类', '例如：F21H', '模板下载', '支持格式', '例如：CN123456 CN234567', '例如：CN123456'],
    "batchSearchType": ['申请号', '公开号', "号码之间请用换行、逗号或空格分隔", '清空', '检索', '请选择', '例如：手机 通讯', '新增栏位'],
    'advanced_recored_con': ['编号', '表达式名称', '检索数据库', '命中数量', '检索时间', '操作', '删除', '检索记录'],
    "searchTypeArr": [
      {
        name: '语义检索',
        currentTypeBool: true,
        type: 1
      },
      {
        name: '简单检索',
        currentTypeBool: false,
        type: 2
      },
      {
        name: '高级检索',
        currentTypeBool: false,
        type: 3
      }, {
        name: '批量检索',
        currentTypeBool: false,
        type: 4
      }
    ],
    "literatureArr": [
      {
        name: "关键词",
        type: "input_keywords",
        list: [
          {
            name: "标题/摘要",
            value: "not_cut_word"
          }
        ]
      },
      {
        name: "号码",
        type: "input",
        list: [
          {
            name: "申请号",
            value: "apply_no_all"
          },
          {
            name: "公开号",
            value: "pub_no_all"
          }
        ]
      },
      {
        name: "日期",
        type: "date",
        list: [
          {
            name: "申请日",
            value: "apply_date"
          },
          {
            name: "公开日",
            value: "pub_date"
          }
        ]
      }
    ],
    "advancedLegalArr": ["全部", "有效", "无效", "审中", "未确认"]
  },
  //商标检索列表页
  "PS_list": {
    "none":'暂无',
    'input_con': ['请输入一段话…', '请输入关键词或一段话…'],
    'add_keywords_select': '请选择',
    'add_keywords_title': ['例如：手机 通讯', '例如：CN123456 CN234567', '开始日期', '结束日期', '新增栏位', '关键词', '添加关键词', '全部', '更多', '筛选'],
    'list_con_middle_fixed': ['有效', '审核中', '无效', '未知', '相关度'],
    'des_info': ['公开号', '申请号', '发明人', '申请人', '申请日期', '专利度', '独权度', '方法度', '特征度'],
    'list_table': ['序号', '公开号', '标题', '公开日', '申请号', '申请日', '法律状态', '自救中，暂无结果'],
    'list_right_tab': ['公开号', '公开/公告日', '申请号', '申请日期', '申请人', '发明人', '专利类型', 'IPC分类号', '优先权国家', '优先权日', '摘要', '权利要求', '说明书'],
    'literatureArr': [
      {
        name: "关键词",
        type: "input_keywords",
        list: [
          {
            name: "标题/摘要",
            value: "not_cut_word"
          }
        ]
      },
      {
        name: "号码",
        type: "input",
        list: [
          {
            name: "申请号",
            value: "apply_no_all"
          },
          {
            name: "公开号",
            value: "pub_no_all"
          }
        ]
      },
      {
        name: "日期",
        type: "date",
        list: [
          {
            name: "申请日",
            value: "apply_date"
          },
          {
            name: "公开日",
            value: "pub_date"
          }
        ]
      }
    ],
    'detailTab': [
      {
        name: "著录项目"
      },
      {
        name: "权利要求"
      },
      {
        name: "说明书"
      },
      {
        name: "附图"
      }
    ]
  },
  //商标检索详情页
  'PS_detail': {
    'list_rigth_title': ['有效', '审核中', '无效', '未知'],
    'list_right_tab_con': ['公开号', '公开/公告日', '申请号', '申请日期', '申请人', '发明人', '专利类型', 'IPC分类号', '优先权国家', '优先权日', '摘要', '全文', '权利要求', '说明书', '附图'],
    'detailTab': [
      {
        name: "著录项目"
      },
      {
        name: "权利要求"
      },
      {
        name: "说明书"
      },
      {
        name: "附图"
      }
    ]
  },
  "patentSearch": {
    "none": "暂无",
    "confirmTitle1": "今日累计检索已达到100次，可明日再来或者申请试用?",
    "confirmTitle2": "今日累计检索已达到100次，可明日再来或者登录申请试用?",
    "confirmTitle3": "试用期已结束",
    "apply": "申请试用",
    "goLogin": "去登录",
    "tips": "提示",
    "cancelBtn": "取消",
    "goBuy": "去购买"
  },
  //可注册性分析报告
  "analysisReport": {
    top_title: ['可注册分析报告', '本报告为机器分析，仅供参考'],
    part1_title: ['可注册率总览', '查询商标'],
    result_title: ['可注册率高', '积极注册', '可注册率中', '尝试注册', '可注册率低', '谨慎注册'],
    proposal_title: ['可注册率建议', '类别', '简称', '标题', '注册成功率'],
    dataType: [
      {
        type:1,
        name:'工业用化学品',
        title:'用于工业、科学、摄影、农业、园艺和林业的化学品;未加工人造合成树脂,未加工塑料物质;肥料;灭火用合成物;淬火和焊接用制剂;保存食品用化学品;鞣料;工业用粘合剂'
      },
      {
        type:2,
        name:'颜料油漆树脂',
        title:'颜料,清漆,漆;防锈剂和木材防腐剂;着色剂;媒染剂;未加工的天然树脂;绘画、装饰、印刷和艺术用金属箔及金属粉'
      },
      {
        type:3,
        name:'清洁化妆用品',
        title:'洗衣用漂白剂及其他物料;清洁、擦亮、去渍及研磨用制剂;不含药物的肥皂;香料,香精油,不含药物的化妆品,不含药物的润发乳;不含药物的牙膏'
      },
      {
        type:4,
        name:'油脂燃料能源',
        title:'工业用油和油脂;润滑剂;吸收、润湿和粘结灰尘用合成物;燃料（包括马达用燃料）和照明材料;照明用蜡烛和灯芯'
      },
      {
        type:5,
        name:'医药卫生用品',
        title:'药品,医用和兽医用制剂;医用卫生制剂;医用或兽医用营养食物和物质,婴儿食品;人用和动物用膳食补充剂;膏药,绷敷材料;填塞牙孔用料,牙科用蜡;消毒剂;消灭有害动物制剂;杀真菌剂,除莠剂'
      },
      {
        type:6,
        name:'金属五金器具',
        title:'普通金属及其合金,金属矿石;金属建筑材料;可移动金属建筑物;普通金属制非电气用缆线;金属小五金具;存储和运输用金属容器;保险箱'
      },
      {
        type:7,
        name:'机器机械设备',
        title:'机器和机床;马达和引擎（陆地车辆用的除外）机器联结器和传动机件（陆地车辆用的除外）非手动农业器具;孵化器;自动售货机'
      },
      {
        type:8,
        name:'餐具手工器具',
        title:'手工具和器具（手动的）刀、叉和勺餐具;随身武器;剃刀'
      },
      {
        type:9,
        name:'科学电子设备',
        title:'科学、航海、测量、摄影、电影、光学、衡具、量具、信号、检验（监督）、救护（营救）和教学用装置及仪器;处理、开关、转换、积累、调节或控制电的装置和仪器;录制、传送、重放声音或影像的装置;磁性数据载体,录音盘;光盘,DVD 盘和其他数字存储媒介;投币启动装置的机械结构;收银机,计算机器,数据处理装置,计算机;计算机软件;灭火设备'
      },
      {
        type:10,
        name:'医疗护理器械',
        title:'外科、医疗、牙科和兽医用仪器及器械;假肢,假眼和假牙;矫形用物品;缝合用材料;残疾人专用治疗装置;按摩器械;婴儿护理用器械、器具及用品;性生活用器械、器具及用品'
      },
      {
        type:11,
        name:'照明空调卫浴',
        title:'照明、加热、蒸汽发生、烹饪、冷藏、干燥、通风、供水以及卫生用装置'
      },
      {
        type:12,
        name:'交通运输工具',
        title:'运载工具;陆、空、海用运载装置'
      },
      {
        type:13,
        name:'军火烟火爆竹',
        title:'火器;军火及弹药;爆炸物;烟火'
      },
      {
        type:14,
        name:'珠宝首饰钟表',
        title:'贵重金属及其合金;首饰,宝石和半宝石;钟表和计时仪器'
      },
      {
        type:15,
        name:'乐器及其配件',
        title:'乐器'
      },
      {
        type:16,
        name:'办公教学用品',
        title:'纸和纸板;印刷品;书籍装订材料;照片;文具和办公用品（家具除外）文具用或家庭用粘合剂;艺术家用或绘画用材料;画笔;教育或教学用品;包装和打包用塑料纸、塑料膜和塑料袋;印刷铅字,印版'
      },
      {
        type:17,
        name:'塑料橡胶制品',
        title:'未加工和半加工的橡胶、古塔胶、树胶、石棉、云母及这些材料的代用品;生产用成型塑料和树脂制品;包装、填充和绝缘用材料;非金属软管和非金属柔性管'
      },
      {
        type:18,
        name:'箱包皮具皮革',
        title:'皮革和人造皮革;毛皮;行李箱和背包;雨伞和阳伞;手杖;鞭、马具和鞍具;动物用项圈、皮带和衣服'
      },
      {
        type:19,
        name:'非金属的建材',
        title:'非金属的建筑材料;建筑用非金属刚性管;柏油,沥青;可移动非金属建筑物;非金属碑'
      },
      {
        type:20,
        name:'家具容器骨石',
        title:'家具,镜子,相框;存储或运输用非金属容器;未加工或半加工的骨、角、鲸骨或珍珠母;贝壳;海泡石;黄琥珀'
      },
      {
        type:21,
        name:'家居厨房器具',
        title:'家用或厨房用器具和容器;梳子和海绵;刷子（画笔除外）制刷材料;清洁用具;未加工或半加工玻璃（建筑用玻璃除外）玻璃器皿、瓷器和陶器'
      },
      {
        type:22,
        name:'绳网帐篷帆布',
        title:'缆和绳;网;帐篷和防水遮布;纺织品或合成纤维材料制遮篷;帆;运输和贮存散装物用麻袋;衬垫和填充材料（纸或纸板、橡胶、塑料制除外）纺织用纤维原料及其替代品'
      },
      {
        type:23,
        name:'纺织用纱和线',
        title:'纺织用纱和线'
      },
      {
        type:24,
        name:'日常用纺织品',
        title:'织物及其替代品;家庭日用纺织品;纺织品制或塑料制帘'
      },
      {
        type:25,
        name:'服装服饰鞋帽',
        title:'服装,鞋,帽'
      },
      {
        type:26,
        name:'花边配饰假发',
        title:'花边和刺绣品,饰带和编带;纽扣,领钩扣,饰针和缝针;假花;发饰;假发'
      },
      {
        type:27,
        name:'地毯席类墙纸',
        title:'地毯,地席,席类,油毡及其他铺地板材料;非纺织品制墙帷'
      },
      {
        type:28,
        name:'游戏运动器具',
        title:'游戏器具和玩具;视频游戏装置;体育和运动用品;圣诞树用装饰品'
      },
      {
        type:29,
        name:'蔬果肉蛋奶油',
        title:'肉,鱼,家禽和野味;肉汁;腌渍、冷冻、干制及煮熟的水果和蔬菜;果冻,果酱,蜜饯;蛋;奶和奶制品;食用油和油脂'
      },
      {
        type:30,
        name:'咖啡面点调料',
        title:'咖啡,茶,可可和咖啡代用品;米;食用淀粉和西米;面粉和谷类制品;面包、糕点和甜食;食用冰;糖,蜂蜜,糖浆;鲜酵母,发酵粉;食盐;芥末;醋,沙司（调味品）辛香料;冰'
      },
      {
        type:31,
        name:'水果蔬菜花卉',
        title:'未加工的农业、水产养殖业、园艺、林业产品;未加工的谷物和种子;新鲜水果和蔬菜,新鲜芳香草本植物;草木和花卉;种植用球茎、幼苗和种子;活动物;动物的饮食;麦芽'
      },
      {
        type:32,
        name:'啤酒饮料糖浆',
        title:'啤酒;矿泉水和汽水以及其他不含酒精的饮料;水果饮料及果汁;糖浆及其他制饮料用的制剂'
      },
      {
        type:33,
        name:'含酒精的饮料',
        title:'含酒精的饮料（啤酒除外）'
      },
      {
        type:34,
        name:'烟草烟具火柴',
        title:'烟草;烟具;火柴'
      },
      {
        type:35,
        name:'广告办公销售',
        title:'广告;商业经营;商业管理;办公事务'
      },
      {
        type:36,
        name:'保险金融服务',
        title:'保险;金融事务;货币事务;不动产事务'
      },
      {
        type:37,
        name:'建筑修理安装',
        title:'房屋建筑;修理;安装服务'
      },
      {
        type:38,
        name:'电信通信服务',
        title:'电信'
      },
      {
        type:39,
        name:'运输仓储服务',
        title:'运输;商品包装和贮藏;旅行安排'
      },
      {
        type:40,
        name:'材料处理服务',
        title:'材料处理'
      },
      {
        type:41,
        name:'教育娱乐文体',
        title:'教育;提供培训;娱乐;文体活动'
      },
      {
        type:42,
        name:'设计咨询开发',
        title:'科学技术服务和与之相关的研究与设计服务;工业分析与研究;计算机硬件与软件的设计与开发'
      },
      {
        type:43,
        name:'饮食住宿看护',
        title:'提供食物和饮料服务;临时住宿'
      },
      {
        type:44,
        name:'医疗美容园林',
        title:'医疗服务;兽医服务;人或动物的卫生和美容服务;农业、园艺和林业服务'
      },
      {
        type:45,
        name:'法律安全服务',
        title:'法律服务;为有形财产和个人提供实体保护的安全服务;由他人提供的为满足个人需要的私人和社会服务'
      }
    ],
  },
  //支持国家
  'supportCountry': ['支持查询的国家/地区', '国家/地区', '请选择', '查询', '重置', '最近更新时间', '申请日开始时间', '申请日结束时间'],
  //个人中心
  "personalCenter": {
    "slideBar": [
      {
        "text": "账户信息",
        "path": "/personalCenter/account"
      },
      {
        "text": "服务购买",
        "path": "/personalCenter/productService"
      },
      {
        "text": "我的服务",
        "path": "/personalCenter/myServer"
      },
      {
        "text": "我的订单",
        "path": "/personalCenter/myOrder"
      },
      // {
      //   "text": "续展订单",
      //   "path": "/personalCenter/myRenewal"
      // },
      {
        "text": "我的发票",
        "path": "/personalCenter/myInvoice"
      },
      {
        "text": "商标日志",
        "path": "/personalCenter/trademarkLog"
      },
      {
        "text": "翻译日志",
        "path": "/personalCenter/tLog"
      }
      // {
      //     "text": "专利日志",
      //     "path": "/personalCenter/pLog"
      // }
    ],
    "account": {
      "setPassword": "设置密码",
      "getKeyEvent": "获取API密钥",
      "headImgBtn": "更换图片",
      "contactTitle": "账号信息",
      "contactRightTitle": "申请服务",
      "contactAddBtn": "添加",
      "contactEditBtn": "编辑",
      "writeTitle": "填写",
      "account_key": ['商户ID', '请求密钥'],
      "product_title": '产品服务',
      "WElogin": "请使用微信扫描二维码登录",
      "form": {
        "userName": {
          "label": "姓名",
          "placeholder": "请填写您的真实姓名",
          "errorMessage": "请填写您的真实姓名"
        },
        "companyName": {
          "label": "公司/企业全称",
          "placeholder": "为方便认证，请与营业执照全称一致",
          "errorMessage": "请填写您的公司/企业全称"
        },
        "industry": {
          "label": "所属行业",
          "placeholder": "请输入所属行业",
          "errorMessage": "请输入所属行业"
        },
        "email": {
          "label": "联系邮箱",
          "placeholder": "请填写您的常用邮箱",
          "errorMessage": "邮箱错误"
        },
        "phone": {
          "label": "手机号码",
          "placeholder": "请填写常用手机号，便于我们联系到您",
          "errorMessage": "手机号错误"
        },
        "saveBtn": "保存",
        "submitBtn": "提交审核"
      },
      "success": "试用申请提交成功，审核后会通知您!",
      "successBtn": "返回我的服务",
      "Product_service": [
        [
          {
            operation: '立即了解',
            subTitle: '商标查询',
            path: '/trademarkSearch'
          },
          {
            operation: '立即了解',
            subTitle: '商标监控',
            path: '/SC'
          },
          {
            operation: '立即了解',
            subTitle: '品牌保护',
            path: '/BP'
          },
          {
            operation: '立即了解',
            subTitle: '商标查询API',
            path: '/TI'
          },
          {
            operation: '立即了解',
            subTitle: '商标管理系统',
            path: '/TMS'
          }
        ],
        [
          {
            operation: '立即了解',
            subTitle: '专利翻译',
            path: '/patentTranslation'
          },
          {
            operation: '立即了解',
            subTitle: '专利检索',
            path: '/searchTntroduction'
          },
          {
            operation: '立即了解',
            subTitle: '专利翻译API',
            path: '/psApi'
          },
          {
            operation: '立即了解',
            subTitle: '专利管理系统',
            path: '/PMS'
          },
          {
            operation: '立即了解',
            subTitle: '专利年费管理系统',
            path: '/PAMS'
          },
          {
            operation: '立即了解',
            subTitle: '机翻Trados插件',
            path: "/Trados"
          },
          {
            operation: '立即了解',
            subTitle: '蓝灯鱼机翻word插件',
            path: "/TradosWord"
          },
          {
            operation: '立即了解',
            subTitle: '蓝灯鱼机翻Excel插件',
            path: "/Excel"
          },
          {
            operation: '立即了解',
            subTitle: '专利五书拆分',
            path: "/SPB"
          },
          {
            operation: '立即了解',
            subTitle: '专利CPC附图一键压缩',
            path: "/patentCPC"
          }
        ],
        [
          {
            operation: '立即了解',
            subTitle: '智慧园区',
            path: '/smartPark'
          },
          {
            operation: '立即了解',
            subTitle: '企业网盘订制',
            path: '/enterpriseNetwork'
          }
        ]
      ],
      "stepsPhoneTitle": [
        "安全验证", "设置新手机号", "完成"
      ],
      "stepsWechatTitle": [
        "安全验证", "解除授权", "完成"
      ],
      "bindingWechatTitle": [
        "安全验证", "绑定微信", "完成"
      ],
      "successRevision": "修改成功",
      "successRelease": "解除成功",
      "backAccount": "返回个人中心",
      "submit": "提交",
      "submitValida": "提交验证",
      "wechatTip1": "请使用微信扫描二维码解除授权",
      "wechatTip2": "注：解除授权后，将不能使用微信登录",
      "wechatTip3": "请使用微信扫描二维码绑定账户",
      "wechatTip4": "注：绑定账户后，将可以使用微信扫码登陆",
      "wechatLogin": '微信授权登录'
    },
    "server": {
      "tabList": [
        {
          text: "商标",
          isActive: true,
          typeId: 27
        },
        {
          text: "专利",
          isActive: false,
          typeId: 28
        },
        {
          text: "免费试用",
          isActive: false,
          typeId: 26
        }
      ],
      field: ['起', '折', '元', '购买', '已申请', '申请', '商标查询']
    },
    "myServer": {
      tabList: [
        {
          text: "购买",
          isActive: true,
          typeId: 27,
          code: 1
        },
        {
          text: "试用",
          isActive: false,
          typeId: 28,
          code: 2
        }],
      field: ['服务编号', '服务名称', '开通时间', '到期时间', '使用情况(使用/总量)', '服务状态', '服务中', '已失效', '开启', '暂无订单，去', '购买']
    },
    "myOrder": {
      'my_order_header': '时间范围',
      'my_order_date': ['开始时间', '结束时间', '查询'],
      'my_order_main': ['订单编号', '创建时间', '支付方式', '对公支付', '订单金额', '元', '状态', '待审核', '订单完成', '审核拒绝'],
      'orderStatus': ['待支付', '待审核', '支付成功', '免支付', '审核拒绝', '订单完成', '操作', '查看', '支付'],
      'no_table_data': ['暂无订单，去', '购买'],
      'report_con': ['订单信息', '订单号', '创建时间', '订单状态', '订单金额', '产品信息', '产品名称', '总次数', '总字数', '总数'],
      'report_con_item': ['次', '字', '个', '每日限制', '次', '字', '时间限制', '一周', '一个月', '三个月', '半年', '一年', '天'],
      'report_list_item': ['支付信息', '支付时间', '支付方式', '支付金额']
    },
    "myInvoice": {
      "tabList": [
        {
          text: "订单记录",
          isActive: true,
          typeId: 27,
          code: 1
        },
        {
          text: "开票历史",
          isActive: false,
          typeId: 28,
          code: 2
        },
        {
          text: "发票抬头",
          isActive: false,
          typeId: 29,
          code: 3
        }],
      'my_order_main': ['申请开票', '订单类型', '购买服务', '支付时间', '支付金额', '元', '开票状态'],
      'addInvoice_table': ['未开票', '开票失败', '已申请', '开票中', '已邮寄', '开票失败', '操作', '修改申请', '备注', '开票信息'],
      'tableDatas': ['抬头', '纳税人识别号', '注册地址', '注册电话', '开户银行', '银行帐户', '操作', '暂无数据'],
      'tableceinfoData': ['发票抬头', '提交时间', '开票金额', '元', '开票状态', '已申请', '开票中', '已邮寄', '开票失败', '操作', '修改申请', '备注', '开票信息'],
      'forms': ['申请开票', '开票金额', '元', '抬头', '请输入账号', '提交', '重新申请', '修改信息', '默认', '暂无数据', '开票信息'],
      'form': [
        {
          label: '抬头',
          placeholder: '请输入抬头'
        },
        {
          label: '纳税人识别号',
          placeholder: '请输入纳税人识别号'
        },
        {
          label: '注册地址',
          placeholder: '请输入注册地址'
        },
        {
          label: '注册电话',
          placeholder: '请输入注册电话'
        },
        {
          label: '开户银行',
          placeholder: '请输入开户银行'
        },
        {
          label: '银行帐户',
          placeholder: '请输入银行帐户'
        }
      ],
      'message': ['请选择要申请开票的订单', '开票信息修改失败', '开票信息添加失败', '开票信息修改成功', '开票信息添加成功', '修改申请成功', '修改申请失败', '申请开票成功', '申请开票失败', '开票信息填写不全']
    },
    "trademarkLog": {
      'title': '时间范围',
      'startTime': '开始时间',
      'endTime': '结束时间',
      'query': '查询',
      'Query_content': ['查询内容', '查询时间', '产品名称', '发起类型', '网站', '接口']
    },
    "tLog": {
      'title': '时间范围',
      'startTime': '开始时间',
      'endTime': '结束时间',
      'query': '查询',
      'Query_content': ['翻译方向', '翻译时间', '内容类型', '翻译字数', '产品名称', '发起类型', '网站', '接口']
    }
  },
  "legal": {
    "h1": "法律条款",
    "label": "法律",
    "links": {
      "term": "用户协议",
      "privacy": "隐私政策"
    }
  },
  "trademarkData": {
    'countryGroup': [{
      'titleText': '快速通道',
      "activeBool": true,
      'conList': [{
        'conImg': '../../../images/trademarkData/icon_China.png',
        'checkedImg': '../../../images/trademarkData/icon_checked.png',
        'desc': '中国大陆',
        'isChecked': true,
        'country': 'China'
      },
        {
          'conImg': '../../../images/trademarkData/icon_Japan.png',
          'checkedImg': '../../../images/trademarkData/icon_checked.png',
          'desc': '日本',
          'isChecked': false,
          'country': 'Japan'
        },
        {
          'conImg': '../../../images/trademarkData/icon_Britain.png',
          'checkedImg': '../../../images/trademarkData/icon_checked.png',
          'desc': '英国',
          'isChecked': false,
          'country': 'Britain'
        },
        {
          'conImg': '../../../images/trademarkData/icon_America.png',
          'checkedImg': '../../../images/trademarkData/icon_checked.png',
          'desc': '美国',
          'isChecked': false,
          'country': 'America'
        }
      ]
    }, {
      'titleText': '亚洲',
      "activeBool": false,
      'conList': [{
        'conImg': '../../../images/trademarkData/icon_China.png',
        'checkedImg': '../../../images/trademarkData/icon_checked.png',
        'desc': '中国大陆',
        'isChecked': false,
        'country': 'China'
      },
        {
          'conImg': '../../../images/trademarkData/icon_HK.png',
          'checkedImg': '../../../images/trademarkData/icon_checked.png',
          'desc': '中国香港',
          'isChecked': false,
          'country': 'HK'
        },
        {
          'conImg': '../../../images/trademarkData/icon_Taiwan.png',
          'checkedImg': '../../../images/trademarkData/icon_checked.png',
          'desc': '中国台湾',
          'isChecked': false,
          'country': 'Taiwan'
        },
        {
          'conImg': '../../../images/trademarkData/icon_Japan.png',
          'checkedImg': '../../../images/trademarkData/icon_checked.png',
          'desc': '日本',
          'isChecked': false,
          'country': 'Japan'
        }
      ]
    }, {
      'titleText': '美洲',
      "activeBool": false,
      'conList': [{
        'conImg': '../../../images/trademarkData/icon_America.png',
        'checkedImg': '../../../images/trademarkData/icon_checked.png',
        'desc': '美国',
        'isChecked': false,
        'country': 'America'
      },
        {
          'conImg': '../../../images/trademarkData/icon_Canada.png',
          'checkedImg': '../../../images/trademarkData/icon_checked.png',
          'desc': '加拿大',
          'isChecked': false,
          'country': 'Canada'
        },

      ]
    }, {
      'titleText': '欧洲',
      "activeBool": false,
      'conList': [{
        'conImg': '../../../images/trademarkData/icon_France.png',
        'checkedImg': '../../../images/trademarkData/icon_checked.png',
        'desc': '法国',
        'isChecked': false,
        'country': 'France'
      },
        {
          'conImg': '../../../images/trademarkData/icon_Germany.png',
          'checkedImg': '../../../images/trademarkData/icon_checked.png',
          'desc': '德国',
          'isChecked': false,
          'country': 'Germany'
        },

      ]
    }],
    'typeGroup': [{
      'titleText': '大数据',
      "titleImg": '../../../images/trademarkData/icon_data.png',
      'conList': [{
        'conText': '总览图',
        'conActive': false
      },
        {
          'conText': '各省10年总览图',
          'conActive': true
        },
        {
          'conText': '各省详细柱状图',
          'conActive': false
        }
      ]
    }, {
      'titleText': '查企业',
      "titleImg": '../../../images/trademarkData/icon_enterprise.png',
      'conList': [{
        'conText': '企业分析图',
        'conActive': false
      },
        {
          'conText': '企业对比图',
          'conActive': false
        },
        {
          'conText': '45类前十名企业',
          'conActive': false
        }
      ]
    }, {
      'titleText': '查代理所',
      "titleImg": '../../../images/trademarkData/icon_agency.png',
      'conList': [{
        'conText': '代理所排名图',
        'conActive': false
      },
        {
          'conText': '马德里商标代理图',
          'conActive': false
        },
        {
          'conText': '代理所对比图',
          'conActive': false
        }
      ]
    }],
  },
  "query_lang": {
    /* 后续补充 */
    queryShowTable: '表格',
    queryShowIcon: '图标',
    Disclaimer: '免责声明：由于数据更新存在时间差，查询结果仅供参考，不具有法律效力。', // 免责声明
    Powered_by_Lanternfish: '由蓝灯鱼提供技术支持', // 技术支持
    more: '更多', // 更多
    packup: '收起', // 收起
    /* 主界面/快速查询 */ /// *主界面/快速查询*/
    Similar_mark: '近似商标', // 近似商标,
    Similar_mark_tips: '',
    Reg_No: '申请号', // 申请号,
    Trademark_name: '申请人', // 申请人,
    area: '中国大陆',
    Class: '类别', // 分类,
    // search:"search",//查询,
    exactQuery: '组合查询', // 高级查询,
    fastQuery: '快速查询', // 快速查询,
    xxx: '中国大陆', // 中国大陆,
    /* 高级查询 */ /// *高级查询*/
    Literal_elements_of_trademark: '商标文字', // 商标文字,
    Identical_search: '相同查询', // 精确查询,
    Contained_search: '包含查询', // 包含查询,
    Similar_search: '近似查询', // 近似查询,
    Trademark_specimen: '商标图形', // 商标图形,
    Click_to_upload_the_specimen: '拖拽图片或点击上传', // 拖拽图片或点击上传,
    chooseDate: '选择日期',
    Design_Code_Search: '图形要素编码', // 图形要素编码,
    App_Reg_No: '申请号/注册号', // 申请号/注册号,
    Applicant_name_Chinese: '申请人名称（中文）', // 申请人名称（中文）,
    Applicant_name_English: '申请人名称（英文）', // 申请人名称（英文）,
    Applicant_address_Chinese: '申请人地址（中文）', // 申请人地址（中文） ,
    Applicant_address_English: '申请人地址（英文）', // 申请人地址（英文） ,
    Publication_date: '初审公告日', // 初审公告日,
    app_Date: '申请日', // 申请日,
    Registration_Date: '注册日', // 注册日,
    Expiration_Date: '专用权到期日', // 专用权到期日,
    Domestic_Representative_or_Attorney_of_Record: '代理机构', // 代理机构,
    return_: '返回', // 返回,
    search: '查询', // 查询,
    reset: '重置',
    to: '到',
    /* 查询结果 */ /// *查询结果*/
    Selected_conditions: '查询条件', // 查询条件,
    Degree_of_similarity: '近似度', // 近似度,
    high: '高', // 高,
    middle: '中', // 中,
    low: '低', // 低,
    trademarkStatus: '商标状态', // 商标状态,
    Registered: '已注册', // 已注册,
    Pending_application: '申请中', // 申请中,
    Invalid: '无效', // 无效,
    Published_for_opposition: '公告中', // 公告中,
    Pending_opposition: '异议中', // 异议中,
    Pending_review_of_refusal: '驳回复审中', // 驳回复审中,
    Applicant_name: '申请人名称', // 申请人名称,
    Applicant_address: '申请人地址', // 申请人地址,
    App_No: '申请号', // 申请号,
    Goods_Services_: '商品/服务', // 商品/服务,
    Filing_Date: '申请日', // 申请日,
    Reg_Date: '注册日', // 注册日,
    Status: '状态', // 状态,
    Degree_of_satisfaction: '满意度', // 满意度,
    Completed_File: '完整档案', // 完整档案,
    Analysis_of_availability_of_registration: '查询报告', // xxx,
    Download_search_report: '导出到excel', // xxx,
    please_enter: '请输入',
    please_choose: '请选择',
    checkAll: '全选',
    cancel: '取消',
    confirm: '确定',
    /* 上传图片 */
    Kind_reminder: '温馨提示',
    uploadTips1: '请上传商标图样，JPG/PNG/GIF/TIFF格式',
    uploadTips2: '2.如已经上传文字，则无法同时上传商标',
    uploadTips_success: '图片上传成功,请继续选择条件,注意选择类别呦!',
    noData: '暂无数据',
    /* 通用 */
    Image_format_error: '文件格式错误', // 图片格式错误
    Search_successful: 'Success',
    The_file_is_too_large: '文件过大', // 文件过大
    /* 商标档案for丹丹 */
    Print: '打印', // 打印
    Report_wrong_information: '错误信息反馈', // 错误信息反馈
    Goods_Services: '商品/服务',
    Subclass: '类似群组',
    GazetteNoofRegistration: '注册公告期号',
    InternationalRegistrationDate: '国际注册日',
    PostdesignationDate: '后期指定日期',
    jsjmStatus: '商标状态',
    ddProcedure: '商标流程',
    ddPriority: '是否共有商标',
    ddSteps: '流程',
    dddate: '日期',
    ddyouxianquan: '优先权日期',
    ddPublication: '初审公告期号',
    // 可注册分析
    CommerceAndMarketAnalysis: '查询报告',
    TrademarkToBeSearched: '查询对象',
    TrademarkToBeScope: '查询范围',
    ClassEs: '选择类别',
    PresentationOfBelief: '您支付费用后1个工作日内会收到分析报告:',
    MobilePhone: '手机号',
    EmailAddress: '电子邮件',
    Total: '费用总和:',
    PayNow: '支付费用',
    qian: '元',
    fusm: '服务说明',
    fusmx: '我们会在收到订单后1个工作日内安排经验丰富的商标代理人为您出具详细的《商标查询报告》，包括对商标显著性、在先权利冲突等影响商标注册的潜在因素进行分析和建议，发到您的注册邮箱。',
    ContactInformation: '联系信息',
    CompleteNow: '立即填写',
    Incomplete: '未填写',
    PleaseUpload: '请上传商标图样',
    pleaseChoose: '请选择类别',
    At_least_one: '至少保留一个' // 至少保留一个
  },
  "follow_up_FE_lang": {
    A_larger_preview: '大图预览',
    please0: '如果原始文件不是英文，请上传英文翻译。',
    please1: '1) 请在右侧框中下载模板；',
    please2: '2) 请参照示例签字；',
    please3: '3) 请扫描已签名的，并上传至左侧框',
    complete_: '添加',
    edit: '修改',
    continue_: '继续',
    delete_: '删除',
    priority_: '优先权证明文件',
    scanned_copy: '扫描件',
    download_the_drafted_POA: '下载',
    Duplicate_of_applicant: '申请人重复', // 申请人重复
    trademark_at_least_one: '申请号/注册号,申请人名称,商标名称至少输入一个', // 申请号/注册号,申请人名称,商标至少输入一个
    Priority_category: '优先权类别', // 优先权类别
    operation: '操作', // operation
    Cannot_contain_special_characters: '不能包含特殊字符', // 不能包含特殊字符
    Analysis_of_trademark: '查询报告', // 可注册性分析
    Schedule_renewal: '预定', // 预定续展
    Renewal: '正常', // 正常续展
    Extension_renewal: '宽展期续展', // 宽展期续展
    Unable_to_renew: '不可续展', // 不可续展
    Amount_paid: '实收金额', // 实收金额
    reset: '重置', // 重置
    Please_note: '温馨提示', // 温馨提示
    Please_note2: '温馨提示:  最多可勾选5个', // 温馨提示:  最多可勾选5个
    applicantNumLimit: '申请人共同申请人加起来不能超过10个',
    confirm: '确认', // 确认
    close: '关闭', // 关闭
    enter_error: '输入错误', // 输入错误
    enter_fomat_nice: '请输入,如03;09;38;', //
    enter_fomat_vienna: '请输入,如4.1;6.3;最多输入5个;', // 最多输入5个位自己翻译;
    enter_fomat_no: '请输入申请号,如000001;000002;...国际商标请在申请号前增加字母G', // 请输入申请号,如No.1;No.2
    enter_fomat_reg_no: '请输入注册号,如000001;000002;...', // 请输入申请号,如No.1;No.2
    xuzhan1: '部分商标已经提交续展申请,请勿重复提交.', // 部分商标已经提交续展申请,请勿重复提交;
    Price_error: '价格错误',
    next: '下一步', // 下一步
    isRenewal2: '此商标已经提交续展申请,请勿重复提交;',
    isRenewal: '部分商标已经提交续展申请,请勿重复提交;',
    xuzhan2: '说明：根据国家知识产权局规定，非标准优先权需要上传/邮寄优先权原件', // 说明：根据中国大陆商标局规定，非标准优先权需要邮寄优先权原件
    both_words_and_marks: '有关键词则不能上传图片', // 有关键词则不能上传图片
    /*  appNameCn:"",//申请人中文
      appNameEn:"",//申请人英文
      appAdressCn:"",//申请人地址中文
      appAdressEn:"",//申请人地址英文 */
    Mainland_China: '中国大陆',
    Hong_Kong: '中国香港',
    Trademark_Application: '中国大陆商标注册',
    Hong_Kong_Applications: '中国香港商标注册',
    Analysis__Availability: '查询报告',
    Trademark_Renewal: '商标续展',
    to: '至',
    Pending_application: '申请中',
    Preliminary_published: '初审公告中',
    Pending_opposition: '异议中',
    Registered: '已注册',
    Invalid: '无效',
    Other: '其他情形'

  },
  "reg_query_lang": {

    Upload_again: '重选',
    Rotate: '旋转',
    Flip: '镜像',
    Confirm: '确定',
    Creation_Date: '创建日期',
    Service_fees: '服务时长',
    Total_Due: '费用',
    Expiration_Date: '截止日期',
    Payment: '付费方式',
    havePriority: '(优先权)',
    please_upload: '请上传',
    de: '的',
    business_license: '营业执照',
    priority: '优先权证明',
    notarial_documents: '公正文件',
    power_of_attorney: '委托书', // 委托书The power of attorney
    Successfully_added: '添加成功', // 添加成功
    Abnormal_communication: '通讯异常', // 通讯异常
    xxx_s_business_license: '请上传xxx的营业执照', // 请上传xxx的营业执照
    upload_the_priority_: '请上传优先权证明', // 请上传优先权证明
    pload_the_notarial_documents: '请上传公证文件', // 请上传公证文件
    mailing_company: '请填写邮寄公司', // 请填写邮寄公司
    mailing_list: '请填写邮寄单号', // 请填写邮寄单号
    Submitted_successfully: '提交成功', // 提交成功
    Submitted_failure: '提交失败', // 提交失败
    name_of_trademark: '请输入商标名称', // 请输入商标名称
    trademark_image: '请上传或生成商标图片', // 请上传或生成商标图片
    trademark_images_black_and_white: '请上传或生成黑白商标图片', // 请上传或生成黑白商标图片
    color_of_the_trademark_contains: '请填写商标包含的颜色', // 请填写商标包含的颜色
    Description_of_the_mark: '请填写商标说明', // 请填写商标说明
    contain_non_Latin: '是否包含拉丁字符', // 是否包含拉丁
    country_Territory_Area: '请选择优先权国家', // 请选择优先权国家
    priority_date: '请选择优先权日', // 请选择优先权日
    Application_No: '请输入优先权申请号', // 请输入优先权申请号
    priority_category_list: '请将优先权类别列表填写完整', // 请将优先权类别列表填写完整
    priority_category_list_HK: '请填写优先权类别', // 请填写优先权类别
    the_product_category: '请选择商品类目', // 请选择商品类目
    tips_keywords: '请输入关键词', // 请输入关键词
    Belongs_to_field_and_niche: '请选择所属领域和细分领域', // 请选择所属领域和细分领域
    At_least_one: '至少保留一个', // 至少保留一个
    name_of_applicant: '请输入申请人名称', // 请输入申请人名称
    address_in_detail: '请输入详细地址', // 请输入详细地址
    Social_unified_coding: '请输入正确的统一社会信用代码', // 请输入正确的社会统一编码
    id_number: '请输入证件号码', // 请输入证件号码
    Certificate_number_format_error: '证件号码格式错误', // 证件号码格式错误
    enter_the_country: '请输入国家', // 请输入国家
    plicant_name_en: '请输入申请人名称(英文)', // 请输入申请人名称(英文)
    address_en: '请输入申请人地址英文', // 请输入申请人地址英文
    address_cn: '请输入申请人地址英文', // 请输入申请人地址中文
    plicant_name_cn: '请输入申请人名称中文', // 请输入申请人名称中文
    contact_person: '请输入联系人', // 请输入联系人
    phone_number: '请输入联系电话', // 请输入联系电话
    Save_Success: '保存成功', // 保存成功
    name_of_the_recipient: '请输入收件人名称', // 请输入收件人名称
    Save_failed: '保存失败', // 保存失败
    right_range_of_time: '请选择正确的时间段', // 请选择正确的时间段
    tips_which: '商标文字/商标图形/图样要素编码/申请号/注册号/申请人名称/申请人地址/代理机构,有且只能选一个', // 商标文字/商标图形/图样要素编码/申请号/注册号/申请人名称/申请人地址/代理机构,有且只能选一个
    tips_which2: '商标文字/商标图形/图样要素编码/申请号/注册号/申请人名称/申请人地址/代理机构,至少选一个',
    full_class_query: '没有选择类别,默认全类查询', // 没有选择分类,默认全类查询
    Data_Exception: '数据异常', // 数据异常
    Empty_Data: '查询为空', // 查询为空
    result_and_then_filter: '请确认查询到结果后,再进行筛选;', // 请确认查询到结果后,再进行筛选;
    tips_1: '没有满足当前近似度的数据', // 没有满足当前近似度的数据
    classification_first: '请先选择类别', // 请先选择分类
    CountriesAndRegions_first: '请先选择国家/地区', // 请先选择国家
    upload_your_query: '请上传查询图片', // 请上传查询图片
    upload_pictures: '请上传图片', // 请上传图片
    query_is_successful: '查询成功', // 查询成功
    regno_is_repeat: '基础信息申请号重复', // 基础信息申请号重复
    tmapplicant_is_repeat: '申请人与基础信息申请不一致', // 申请人与基础信息申请不一致
    SubmitSuccess: '提交成功', // 意见反馈提交成功
    SubmitFail: '提交失败' // 意见反馈提交失败
  },
  "personal_lang2": {
    debitmonitor: '商标监控',
    monitor: '商标监控库',
    globalApplication: '海外商标注册',
    Dateofexpiry: '到期日',
    myOrders: '我的订单',
    DiscountCoupon: '我的感恩券',
    returnHome: '回到主页',
    returnKx: '返回康信',
    trademarkColors: '商标颜色',
    etc: '(多个)',
    OfficialCommunication: '官方来文:',
    SubmitOfficial: '提交官方:',
    // music: '网易云音乐3',//网易云音乐
    noAccount: '您的公司还没有企业账号，',
    NoEnterprise: '创建企业账号',
    myorderWelcome: '欢迎您,',
    myorderAll: '全部订单',
    myorderSelectAll: '全选',
    myorderSeviceName: '服务名称',
    OrderAmount: '订单总额',
    ServiceFees: '服务费',
    myorderStatus: '全部状态',
    Operations: '操作',
    orderMoney: '￥',
    ddOfficialdocuments: '官方文件',
    // 官文管理页面
    serach: '搜索',
    trademarkName: '商标名称',
    appliNo: '申请号',
    GuanwenClass: '类别',
    Documentname: '官文名称',
    Guanwenapplicnt: '申请人',
    OrderNo1: '订单号',
    Trademark: '商标',
    Appliacnat: '申请人',
    PleaseChoose: '请选择',
    fflakf: '申请退款后则无法撤销，您是否确认退款？请您输入退款原因',
    ddprompt: '提示',
    refunsWhy: '退款原因',
    typesOfSevice: '案件类型',
    OurRefNo: '康信卷号',
    YourRefNo: '客户卷号',
    Progresss: '进度',

    NoteDD: '提示',
    Download: '下载',
    DocumentName: '官文名称',
    DateReceived: '收录时间',
    Deadline: '绝限时间',

    // Huawei:'华为',
    // huaweiCom:'华为通信科技有限公司',
    // 订单详情页
    TrackingInfo: '邮寄信息',
    OrderIformation: '订单信息',
    caseformation: '案件信息',
    Receipt: '发票',
    printOrder: '打印订单',
    TrademarkInformation: '商标信息',
    PatentInformation: '专利信息',
    //  安全设置页面
    corpname: '公司名称',
    lianxiren: '联系人',
    SecurityLevel: '安全级别',
    LoginPassword: '登录密码',
    longLoginPassword: '安全性高的密码可以使您的账号更安全，建议您定期的更改密码',
    LoginPasswordmodify: '修改',
    EmailVerification: '账号邮箱',
    EmailVerificationBind: '建议您绑定邮箱，以便于接收给您发送的重要通知',
    binding: '绑定',
    securityPhone: '安全手机',
    jajeueaosj: '您验证的手机',
    securiwodnsls: '若丢失或停用，请立即更换，避免账户被盗',
    ServicePromotion: '安全服务提示',
    jejeneoettta: '1. 确认您登录的是康信网址https://service.kangxin.com/，注意防范进入钓鱼网站，不要轻信各种即时通讯工具发送的服务或支付链接，谨防网络诈骗。',
    jianuie: '2. 建议您安装杀毒软件，并定期更新操作系统等软件补丁，确保账户及交易安全。',
    enterPassword: '请输入不少于八位的数字字母组合密码',
    incosistern: '两次输入密码不一致',
    emptyNewPassword: '新密码不能为空',
    tuxing: '图形验证码不能为空',
    getYanzheng: '验证码不能为空',

    //  消息页面

    //  邮寄地址页面
    CountryArea: '所在地区',
    Address: '详细地址',
    PostCode: '邮政编码',
    ContactPerson: '收货人',
    Telephone: '电话',
    Operation: '操作',
    add: '添加',
    delete: '删除',
    AddressDefault: '设为默认',
    defaultAddress: '默认地址',
    //    邮寄地址页面的提示
    ContactPersonEmpty: '收货人不能为空',
    TelephoneeMPTY: '电话不能为空',
    TelephoneSuccess: '请输入正确的电话',
    //  企业认证页面
    companyName: '企业名称',
    MailingAddress: '邮寄地址',
    OfficeTelephone: '办公电话',
    EnterContactPerson: '指定联系人',
    enterMobilePhone: '指定手机号',
    enterEmailAddress: '电子邮件',
    enterBusiness: '营业执照',
    companyNamesEmpty: '企业名称不能为空',
    emailEmpty: '邮政编码不能为空',
    emailUnSuccess: '请输入正确的邮政编码',
    addressEmpty: '详细地址不能为空',
    officePhoneEmpty: '办公电话不能为空',
    officePhoneUnSuess: '请输入正确的办公电话',
    emailssEmpty: '电子邮件不能为空',
    emailssUnSuccess: '请输入正确的电子邮件',
    enterpriseYijng: '您的认证正在审核中',
    SuccessfullySubmit: '提交成功',
    SuccessFulluSuek: '已经提交成功，我们将在2个工作日内完成审核。',
    shenhetongguo: '您的审核已通过',
    enterFirishs: '恭喜,您的企业信息认证已完成',
    enejkie: '请上传图片格式营业执照，需<10MB仅限JPG、PNG、',
    fkofafk: 'GIF、TIFF格式',
    jsjsn: '提交',
    rejected: '您的认证被驳回',
    Reasons: '驳回理由',
    //  账号信息页面
    addressz: '地址',
    city: '城市',
    youbian: '邮编',
    county: '国家',
    accountLevel: '安全级别',
    accountEmail: '邮箱',
    accountName: '名称',
    accountMobilePhone: '手机号',
    Gender: '性别',
    male: '男',
    Female: '女',
    currency: '显示币种',
    Ipfrim: '您是',
    enterprises: '企业',
    Agency: '代理所',
    Indiv: '个人',
    ModifyInformation: '修改信息',
    modifyChangePass: '修改登录密码',
    ddsuccess: '成功',
    //  修改密码页面
    VerifyIdentity: '身份验证',
    setNewPassword: '设置新密码',
    authenComplete: '完成',
    authenticaRetri: '密码找回途径',
    VerificationCode: '验证码',
    Captchas: '图形验证码',
    //  修改手机号
    phoneModify: '绑定新手机',
    newMobile: '新手机号',
    //  修改邮箱
    emailModify: '绑定新邮箱',
    newEmail: '新邮箱',
    jjskdpff: '恭喜，修改成功!',
    fanhuizhuye: '返回主页',
    duanxin: '短信验证码',

    noData: '暂无数据',
    deleteScues: '删除成功',
    Successful: '操作成功',
    Unsuccessful: '操作失败',
    getVercitive: '获取验证码',
    null: '无',
    reqiureDocu: '委托材料',
    businessLincese: '营业执照',
    IDcardMerge: '身份证复印件',
    IDcard: '身份证',
    NotarizedDocument: '公证文件',
    PriorityDocument: '优先权证明',
    POA: '委托书',
    Literatrademark: '商标名称',
    Havelikeness: '肖像权',
    DescriptionMark: '商标说明',
    BusinessSector: '所属领域',
    Prioritys: '优先权',
    PrioritysCountry: '优先权国家',
    PriorityDate: '优先权日',
    ApplicationNo: '优先权申请号',
    RefNo: '康信卷号',
    Customized: '自定义商品',
    Applicantname: '申请人',
    Latestupdates: '最新进度',
    // 账单
    OrderNo: '订单号',
    DNNo: '账单号',
    DNdate: '账单日',
    PaymentStatus: '支付状态',
    PaymentDate: '支付日期',
    CasesType: '案件类型',
    DebitTotal: '金额（人民币）',
    Unpaid: '未付款',
    Paid: '已付款',
    To: '致',
    Date: '日期',
    DNNos: '制单编号',
    Re: '案由',
    Trademarks: '商标',
    Classss: '类别',
    DESCRIPTION: '收费项目',
    AMOUNT: '收费金额',
    SERVICESFEES: '代理费',
    Subtotal: '小计',
    OFFICIALFEES: '官方费用',
    TOTALDUE: '总计',
    OrderNos: '订单号',
    ChinaMainland: '中国大陆商标注册',
    HongKongs: '香港商标注册',
    Searchservice: '查询服务',
    Designs: '图形',
    DEBITNOTE: '账单',
    InvoiceStatus: '发票状态',
    // 发票状态
    Unbilled: '未开票',
    Auditing: '申请审核中',
    Audited: '审核通过',
    UnAudited: '审核未通过',
    Invoiced: '已开票',
    Mailed: '已邮寄',
    //  未完成案件
    TypeofCase: '案件类型',
    deadline: '官方绝限',
    Country: '国家',
    Mark: '商标',
    MainlandChina: '中国大陆',
    HongKong: '中国香港',
    TrademarkRenewal: '商标续展',
    TrademarkApplication: '商标注册',
    AnalysisAvail: '查询报告',
    Continue: '填写',
    deleteSure: '确定删除这条信息吗',
    TradematkImg: '商标',
    SelectedGoodsServices: '商品/服务项目',

    //  注册首页马丹丹
    dd1TrademarkRenewal: '商标续展',
    ddServiceIntroduction: '服务简介 :',
    ddprocedures: '商标有效期满后续继续使用的，应及时办理商标续展手续。续展可办理的时间期限为商标期满前12个月内，如该期限内未及时办理的可在商标到期日后6个月内（即“宽展期”）办理',
    ddApplicationProcess: '申请流程 :',
    ddSubmitInfor: '提交申请---形式审查---实质审查---核准'

  },
  /* 注册 */
  "register_lang": {
    hasPortaryal: '', // 肖像权提示
    discount_coupon: '感恩券',
    auto_fill: '确认', // 自动填充
    Selected_GoodsServices: '已选商品服务',
    /* 主页面 */
    Order_Form: '填写信息',
    Select_Trademarks: '选择商标',
    Confirmation: '订单确认',
    Upload_Documents: '提供文件',
    Submit: '确认通知方式',
    /* 商标信息 */
    Trademark_Information: '商标信息',
    Type_of_trademark: '商标类型',
    Type_of_trademark2: '商标构成',
    Character: '文字',
    Design: '图形',
    Character_and_Design: '文字及图形',
    Name_of_trademark: '商标名称',
    Name_of_trademark_ploceholder: '请输入商标名称',
    Sample_of_trademark: '商标图样',
    // Manually_generated: "手动上传",
    Upload_your_own: '手动上传',
    Automatically_generated: '自动生成',
    uploadReminder1: '请上传商标图样，JPG/PNG格式，文件大小须<2MB',
    uploadReminder2: '像素要求不低于400x400（px）',
    seeExample: '查看示例',
    SampleReminder1: '如上传图样为黑白，取得注册后可以更换商标颜色使用；',
    SampleReminder2: '如上传图样为彩色，取得注册后只能按照该彩色图样使用。',
    Portrait: '上传图样是否包含肖像',
    PortraitYes: '是',
    PortraitNo: '否',
    yes: '是',
    no: '否',
    language: '语言',
    Series_of_marks: '是否是系列商标', // 是否是系列商标
    Number_of_marks_in_the_series: '系列号', // 序列号
    Description_of_the_mark: '商标说明',
    DescriptionReminder: '中文无',
    Not_the_Latin_alphabet_or_Chinese_characters: '中文无,非拉丁字母或非中文字符',
    Language: '中文无',
    Transliteration: '中文无',
    Phonetic_Pronunciation: '中文无',
    Business_Sector1: '所属领域',
    Business_Sector2: '细分领域',
    Priority: '优先权',
    PriorityYes: '有',
    PriorityNo: '无',
    Suggested_class: '推荐注册',
    All_class_registrations: '全类注册',
    Clear: '清除',

    plaseChoose: '请选择',
    Please_enter: '请输入',
    Please_select_the_same_applicant: '请选择同一申请人',
    /* 自动生成图片 */
    create: '点击自动生成', // 点击自动生成
    regenerate: '重新生成', // 重新生成

    /* 上传商标 */
    Trademark_Specimen: '提交商标的颜色',
    Color: '彩色',
    Black_and_White: '黑白',
    colorTips1: '如上传图样为黑白，取得注册后可以更换商标颜色使用；',
    colorTips2: '如上传图样为彩色，取得注册后只能按照该彩色图样使用。',
    reupload: '重新上传',
    containColor: '请说明都包含哪些颜色',
    /* 有优先权 */
    Country_Territory_Area: '优先权国家',
    Priority_Date: '优先权日',
    Priority_Date_WARN: '',
    Application_No: '优先权申请号',
    Class_: '类别',
    Goods_Services_English: '商品/服务项目',
    Goods_Services_Chinese: '商品/服务项目(中文)',
    /* 无优先权 */
    Class_and_Goods_Services: '类别及商品／服务项目：',
    Class: '类别',
    Selected_Goods_Services: '已选商品／服务项目',
    one_upload_one: '提交方式说明：为方便使用，按照一标一类提交至官方',
    Search_by_keywords: '关键词搜索',
    add: '添加',
    delete_: '删除',
    /* 结束语 */
    total: '费用总和 :',
    rmb: '元',
    save: '保存',
    // 注册-确认提交方式
    subconfirm: '您的订单已提交!',
    lookOrder: '查看列表订单',
    againorder: '再来一单',
    confirm2: '温馨提示:为及时通知案件进展,请您完善联系信息',
    confirm3: '手机号',
    email: '电子邮件',
    notApplicable: '未填写',
    updatenow: '立即填写',
    address: '邮寄地址',
    confirm5: '邮编',
    confirm5_two: '收件人',
    confirm5_three: '其他电话',
    confirm6: '保存',
    confirm7: '确定',
    noData1: '暂无数据',
    /* 申请人信息 */
    Applicant_Information: '申请人信息',
    Type_of_applicant: '申请人类型',
    Organization: '企业',
    Individual: '自然人',
    Type_of_applicant_ID: '申请人证件类型',
    Photo_ID: '身份证',
    Passport: '护照',
    Other: '其他',
    Belongs_to_The_country: '所属国家/地区',
    Find_Chinese_name_and_address: '',
    Find_Chinese_name_and_address_tips: '',
    Applicant_name_En: '申请人名称（英文）',
    Applicant_name_En_tips: '',
    Applicant_name_En_tips2: '',
    Applicant_address_En: '申请人地址（英文）',
    Applicant_address_En_tips: '',
    Add_joint_applicant: '添加共同申请人',
    Preview: '预览',
    Save_and_proceed: '下一步',
    // 注册-预览页面
    tradeInfo: '商标信息',
    tradeType: '商标类型',
    tradeName: '商标名称',
    TheArea: '所属领域',
    PriorityDa: '优先权日',
    applicntMsg: '申请人信息',
    applicnt: '申请人',
    applicntType: '申请人类型',
    appcotry: '国家/地区',
    appEname: '申请人名称（英文）',
    category: '类别及商品/服务项目',
    appEaddress: '申请人地址（英文）',
    Applicant_name_Chinese: '申请人名称（中文）', // 申请人名称（中文）,
    Applicant_address_Chinese: '申请人地址（中文）', // 申请人地址（中文） ,
    applicantID: '申请人证件类型',
    PostCoder: '邮政编码',
    Contperson: '联系人',
    Tphone: '联系电话',
    appliCant: '申请人',
    Joinapp: '共同申请人信息',
    sure: '确定',
    // area1:"中国大陆",
    class1: '类',
    noClass: '第',
    heibai: '商标黑白图样',
    tishi: '提交成功',
    /* 提供材料 */
    submit_btn: '确认并提交',
    appname: '申请人名称',
    SpecimenOfTrademark: '商标原图样',
    changyong: '已保存至常用地址',
    noChinese: '',
    Copy_of_Business_Certificate: '营业执照复印件',
    id_or_passport: '/身份证/护照',
    English_Translation: '英文翻译',
    Priority_document: '优先权证明',
    Notarized_and_legalized_document: '公证书证明',
    Power_of_Attorney: '委托书',
    Mailing_company: '邮寄公司:',
    Tracking_number: '邮寄单号:',
    ReUpload: '上传',
    Preview_: '预览',
    who_Power_of_Attorney: '的委托书',
    Mainland_China_Application: '中国大陆商标注册',
    Hong_Kong_Application: '中国香港商标注册',
    Mainland_China_Trademark_Renewal: '中国大陆商标续展',

    Priority_DateDan: '优先权日期',
    //  注册首页 马丹丹
    ddmonths: '个月',
    ddyears: '年',
    dApplicationTimeline: '申请周期',
    Protectionperiod: '保护年限',
    Elementswhich: '可作为商标注册的要素',
    dApplicationprinciple: '申请原则',
    ddinclude: '为申请人提供在全球提交商标注册申请的服务，服务包括：',
    ddseverses: '商标申报 官方文件通知 案件进展跟进 时限监控 商标',
    ddzhengsh: '注册证书邮寄',
    Countryselection: '选择办理国家',
    Commonlycountry: '热门国家/地区',
    Countriesselected: '已选国家',
    ddFee: '费用',
    Applynow: '立即办理',
    zeroRMB: '元起',
    seeMore: '查看更多',
    dFAQAnswers: '常见问题解答',
    Please_enter_correct: '请输入正确' // 请输入正确

  },
  update_lang: {
    btnRenewal: '续展',
    ccc: '',
    /* 主页面 */
    Select_trademark_to_renew: '选择续展商标',
    Confirmation: '支付费用',
    Upload_Documents: '提供文件',
    Submit: '确认通知方式',
    search: '查询',
    placeholder_1: '申请号/注册号,如No.1;No.2',
    placeholder_2: '申请人名称',
    placeholder_3: '商标名称',
    placeholder_4: '类别',
    placeholder_5: '续展状态',
    placeholder_6: '代理机构',
    placeholder_7: '专用权到期日',
    placeholder_8: '专用权到期日',
    placeholder_9: '注册日',
    placeholder_10: '注册日',
    label1: '全部',
    label2: '可续展',
    label3: '不可续展',
    table_checkAll: '全选',
    table_pictorial_trade_mark: '商标图案', // 商标图案
    table_Applicant_name: '申请人', // 申请人
    table_App_No: '申请号', // 申请号
    table_Class: '类别', // 类别
    table_Status: '状态', // 状态
    table_Date_of_expiry: '有效期截止日', // 有效期截止日
    table_Renewal_limitations: '宽展期', // 续展绝限
    table_Operating_state: '操作状态', // 操作状态
    table_Operating_1: '操作', // 操作
    btn_Confirm_renewal: '商标续展', // 确认续展
    btn_Confirm_monitoring: '商标监控', // 确认监控
    btn_Confirm_review: '驳回复审', // 确认驳回复审
    btn_Confirm_alteration: '商标变更', // 确认变更
    btn_Confirm_assignment: '商标转让', // 确认转让
    btn_cancel: '取消', // 取消
    btn_delete: '删除', // 删除
    have_selected: '已选择续展商标', // 已选择续展商标
    Unified_social_credit_code: '统一社会信用代码', // 统一社会信用代码
    btn_Save: '保存',
    btn_Return: '返回',
    btn_submit: '提交',
    total: '费用总和',
    shuoming: 'The mark is not open for renewal now, but we will monitor the status and file the renewal when available.'
  },
  preview_lang :{
    Trademark_Color: 'Trademark Color',
    xiaoxiang: "Does the mark contain a person's likeness",
    DescriptionHasCharacter: 'Description Has Character',
    Customized: 'Customized',
    TrademarkSpecimen: 'Color of Trademark',
    Case_Status: 'Case Status',
    More: 'More',
    Outstanding: 'Outstanding Debit Notes',
    Deadlines: 'Deadlines',
    Quick_Search: 'Quick Search',
    Advanced_Search: 'Advanced Search',
    Mainland_China_Application: 'Mainland China Application',
    Hong_Kong_Application: 'Hong Kong Application',
    Trademark_enewal: 'Trademark Renewal',
    Class: 'Class',
    Country: 'Country',
    Cases: 'Cases',
    Applicant: 'Applicant',
    Deadline: 'Deadline',
    to: 'to',
    Status: 'Status',
    Search: 'Search',
    newCases: 'New Cases',
    CaseStatus: 'Case Status',
    OfficialDocuments: 'Official Documents',
    DebitNotes: 'Debit Notes',
    ApplicantInformation: "Applicant's Information",
    MailingAddress: 'Mailing Address',
    AccountInformation: 'Account Information',
    SecuritySettings: 'Security Settings',
    Notifications: 'Notifications',
    order: 'See Records',
    subcg: 'Submission successful !',
    Return: 'Return',
    LogOut: 'Log out',
    bjtitle: 'Kangxin eService Platform Schedule of Fees（USD）',
    Case: 'Case',
    OfficialFee: 'Official fee',
    ServiceFee: 'Service fee',
    Total: 'Total',
    SimilarMarkSearch: 'Similar mark search',
    perYear: 'per year',
    perHalfYear: 'per half year',
    perQuarter: 'per quarter',
    perMonth: 'per month',
    perCase: 'per case',
    AvailabilityAnalysis: 'Availability analysis',
    Registration: 'Registration',
    MainlandChina: 'Mainland China',
    FirstClass: 'First class',
    EachAdditionalClass: 'Each additional class',
    AdditionalItems: 'Additional items',
    Priority: 'Priority',
    HongKong: 'Hong Kong',
    Renewal: 'Renewal',
    MainlandChinax: 'China-Renewal',
    BeforeExpirationDate: 'Before expiration date',
    MainlandChinad: 'Hong Kong - Renewal',
    DuringGracePeriod: 'During grace period',
    plaseCountry: 'Please enter country',
// 确认通知页面
    /* tishi:'您的商标注册含有优先权，请最晚于',
    tishi1:'邮寄优先权原件至我司。', */
    need: 'You might need to:',
    msg: 'Complete contact information ',
    msg1: 'Complete client docket number',
    msg2: 'See all cases',
    msg3: 'Recommended services',
    dlzc: 'Mainland China Application',
    xgzc: 'Hong Kong Applications',
    xbxz: 'Trademark Renewal',
    kzcfx: 'Analysis of availability of registration',
    Cancel: 'Cancel',
    Save: 'Save',
    markfour: 'Mark',
    classfour: 'Class',
    reffour: 'Ref.No.',
    youreffour: 'Your Ref.No.',
    youxianquantitle: 'After you mail the original priority document to us, please fill out the following information.',
    Mailing_company1: 'Mailing company:',
    Tracking_No1: 'Tracking No.:',
    commparedds: 'Our mailing address is:',
    Sun: 'Samiko Sun',
    kangxin: 'Kangxin Partners, P.C.',
    kangxinAddress: 'Floor 16, Tower A, InDo Building, A48 Zhichun Road, Haidian District,',
    kode: 'Beijing 100098, P.R. China',
    Priority_Doc: 'Priority Doc',
    com: 'Complete',
    regNum: 'Reg. No.',
    kxNum: '',
    keNum: 'Client Number',
    appIdNum: 'Applicant ID number',
    // 专利年费
    PatentNo: '',
    Patentee: '',
    PaymentLimit: '',
    PatentName: '',
    Patentpersonal: '',
    zhuanliName: '',
    Paymentyear: '',
    zhuanliren: '',
    zonge: 'Total',
    refnianfei: '',
    minjin: '',
    maxjin: '',
    famin: '',
    leixin: '',
    orderDate: ''
  },
  /* 我的中心 */
  personal_lang1: {
    // music: '网易云音乐3',//网易云音乐
    // 申请人信息页面
    applicantTop: '添加申请人',
    update: '修改',
    del: '删除',
    confirme: '确定',
    can: '取消',
    information: '确定要删除这条信息吗?',
    noDate: '暂无数据',
    serach: '搜索',
    Organization: '企业',
    Individual: '自然人',
    Photo_ID: '身份证',
    Passport: '护照',
    Other: '其他',
    Default: '默认申请人',
    setDefault: '设为默认',
    Successful: '操作成功',
    Unsuccessful: '操作失败',
    plaseC: '请选择',
    // 添加申请人信息页面
    modyfi_type: '申请人类型',
    radio1: '企业',
    radio2: '自然人',
    cotry: '所属国家/地区',
    appcliantName: '申请人姓名/名称',
    applicntAddress: '申请人地址',
    Applicant_name_Chinese: '',
    Applicant_address_Chinese: '',
    Telephone: '联系电话',
    ApplicantEmail: '申请人邮箱',
    Contactperson: '联系人',
    PostalCode: '邮政编码',
    baocun: '保存',
    add_address: '营业执照地址',
    uploadFiles: '上传文件',
    TypesOfYourIdentification: '申请人证件类型',
    seeExample1: '查看示例',
    submit_btn1: '确认并提交',
    fanhui: '返回',
    xgcg: '修改成功',
    xgsb: '修改失败',
    PostCodeIsRequired: '邮政编码不能为空!',
    PostCoded: '请输入正确的邮政编码!',
    telePhone: '联系电话不能为空!',
    telePhoned: '请输入正确的电话号码!',
    contant: '联系人不能为空!',
    businessLicenseAddress: '营业执照地址不能为空!',
    appliName: '申请人名称不能为空!',
    appliAddress: '申请人地址不能为空!',
    IdType: '申请人证件类型不能为空!',
    bccg: '保存成功',
    bcxb: '保存失败',
    IdCard: '身份证号码格式错误',
    msg: '数据格式错误',
    picture: '请上传图片',
    Cancel: '取消',
    // 发票信息

    coverCharge: '服务费',
    totals: '订单总额',
    operate: '操作',
    com: '查看详情',
    orderNumber: '订单号:',
    money: '¥'
  },
  ...zhLocale,
  ...zhCN
}


export default cn
