// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css';
import 'swiper/css/swiper.css'
import './assets/css/animate.min.css'
import './assets/fonts/TheFont.css'
import App from './App'
//移入商标查询store
import store from "./store";
import router from './router'

import EventBus from './bus'

Vue.use(EventBus)
Vue.prototype.$EventBus = EventBus

//视频播放插件vue-video-player
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)

// elementUI
import ElementUI from 'element-ui';
Vue.use(ElementUI);

import VueFullscreen from 'vue-fullscreen'
Vue.use(VueFullscreen)

// seo
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)

// VueI18n
import i18n from './i18n/i18n'

// axios
import axios from 'axios'
Vue.prototype.$axios = axios;

// swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)

// echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts;

//vant
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

//DOM转PDF
import getPdf from '../public/js/domPdf'
Vue.use(getPdf)




import './assets/fonts/Roboto.css'

import tool from './assets/js/tool'
Vue.prototype.tool = tool;

Vue.config.productionTip = false

import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
//use
Vue.use(mavonEditor)

import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import Prism from 'prismjs';

VueMarkdownEditor.use(vuepressTheme, {
  Prism,
  extend(md) {
  },
});
Vue.use(VueMarkdownEditor);



//安装 xe-utils
import 'vxe-table'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'

Vue.use(VXETable)
// VXETablePluginElement
import VXETablePluginElement from 'vxe-table-plugin-element'
import 'vxe-table-plugin-element/dist/style.css'
// ...

VXETable.use(VXETablePluginElement)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  i18n,
  components: {
    App
  },
  template: '<App/>',
  mounted() {
    document.dispatchEvent(new Event('custom-render-trigger'));
  }
})

// 加入百度统计
router.beforeEach((to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }
  next()
})
