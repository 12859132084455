import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
import Vue from "vue";

export default{
  install (Vue, options) {
    Vue.prototype.getPdf =  function (id,title) {
      document.documentElement.scrollTop = 0;
      html2Canvas(document.querySelector(`#${id}`), {
        // allowTaint: true
        scrollX: 0, scrollY: 0,
        useCORS:true//看情况选用上面还是下面的，
      }).then(function (canvas) {
        const imgData = canvas.toDataURL('image/png');
        // 获取实际内容的宽高
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;

        // 创建一个合适大小的PDF
        const pdf = new JsPDF('p', 'pt', [imgWidth, imgHeight]);
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

        pdf.save( title + '.pdf');
        Vue.prototype.$EventBus.$emit('pdf', '1')
        }
      )
    }
  }
}
