import Vue from 'vue'
/* import ElementUI from 'element-ui';
Vue.use(ElementUI);
*/
import moment from 'moment'
import { Message } from 'element-ui'

import VueI18n from 'vue-i18n'
// import { rootImgUrl, rootImgUrl_qiufeng } from '../service/rootUrl'
import html2canvas from 'html2canvas'
import { Canvas2Image } from '../js/canvas2image'
// import { tbOrder } from '../service/tbOrder'
/**
 加密
 **/
// import aesjs from 'aes-js'

// Vue.use(VueI18n)
// export const i18n = new VueI18n({
//     locale: window.localStorage.lang ? JSON.parse(window.localStorage.lang).lang : 'zh-CN', // 语言标识
//     messages: {
//         'zh-CN': require('../../src/lang/zh'), // 中文语言包
//         'en-US': require('../../src/lang/en') // 英文语言包
//     }
// })
export const downloadFn = function(urls) {
    let datas = '';
    let arr = [];
    if (urls.id == undefined) {
        urls.map((item) => {
            arr.push(item.id);
        })
    } else {
        datas = urls.id;

    }
    let api = "";
    let par = "";
    if (urls.id == undefined) {
        api = "downloadFiles";
    } else {
        api = "downloadFile";
    }
    this[api](
            urls.id != undefined ? datas : arr
        )
        .then((res) => { // 处理返回的文件流
            if (res.data) {
                const content = res.data;
                const blob = new Blob([content]);
                let fileName = res.request.getResponseHeader('Content-Disposition-fileName') || "report.pdf"; //暂时先兼容为此,待修改;
                fileName = decodeURIComponent(fileName.replace(/\+/g, " "));
                if ('download' in document.createElement('a')) { // 非IE下载
                    const elink = document.createElement('a');
                    elink.download = unescape(fileName);
                    elink.style.display = 'none';
                    elink.href = URL.createObjectURL(res.data);
                    document.body.appendChild(elink);
                    elink.click();
                    URL.revokeObjectURL(elink.href); // 释放URL 对象
                    document.body.removeChild(elink);
                } else { // IE10+下载
                    navigator.msSaveBlob(blob, fileName)
                }
            }
        })
        .catch((error) => {
            console.log(error);
        })
        // }

};
/**
 下载(多个)文件/压缩包,保持原名称
 **/
export const downloadThenSaveInitialFileName = function(urls) {
    // if (this.aggas == true) {
    //   this.$message("文件正在下载中，请勿重复点击。")
    // } else {

    if (!urls) return;
    let api = "";
    let par = "";
    if (typeof urls == 'object' && urls.length != 1) {
        api = "multiFileDownload";
        par = "urls"
    } else if (typeof urls == 'object' && urls.length == 1) {
        api = "download2";
        par = "fileUrl";
        urls = urls[0];
    } else {
        api = "download2";
        par = "fileUrl"
    }
    this.aggas = true;
    this[api]({
            [par]: urls
        })
        .then((res) => { // 处理返回的文件流
            if (res.data) {
                const content = res.data;
                const blob = new Blob([content]);
                let fileName = res.request.getResponseHeader('Content-Disposition-fileName') || "report.pdf"; //暂时先兼容为此,待修改;
                fileName = decodeURIComponent(fileName.replace(/\+/g, " "));
                if ('download' in document.createElement('a')) { // 非IE下载
                    const elink = document.createElement('a');
                    elink.download = unescape(fileName);
                    elink.style.display = 'none';
                    elink.href = URL.createObjectURL(res.data);
                    document.body.appendChild(elink);
                    elink.click();
                    URL.revokeObjectURL(elink.href); // 释放URL 对象
                    document.body.removeChild(elink);
                } else { // IE10+下载
                    navigator.msSaveBlob(blob, fileName)
                }
                this.aggas = false;
            }
        })
        .catch((error) => {
            console.log(error);
        })
        // }

};
/**
 * 公共变量
 */
export const othersContent = {
    colors: ['#93bcf2', '#b8e4be', '#fbc05e', '#f5aaaa', '#fbe083', '#d9a1eb',
        '#fc898f', '#949dd4', '#bdaddb', '88dbf3'
    ],
    a: '如此添加',
    productTypes: {
        0: '中国大陆商标注册',
        1: '中国大陆商标注册',
        2: '中国香港商标注册',
        3: '海外商标注册',
        4: '查询报告',
        5: '商标续展',
        7: '商标异议',
        10: '商标监控',
        11: '全球商标续展',
        12: '商标转让',
        13: '商标变更',
        14: '驳回复审',
        16: '商标撤三',
        18: '马德里续展',
        17: '全球商标变更',
        26: '马德里商标注册',
        29: '补发注册证',
        30: '补发变更/转让/续展证明',
        31: '商标许可',
        32: '无效宣告',
    },
    countryTypes: {
        1: '中国', // 中国大陆
        2: '中国香港',
        3: '其他国家地区',
        4: '中国', // 中国大陆
        5: '中国', // 中国大陆
        10: '中国', // 中国大陆
        11: '其他国家地区'
    },
}

/**
 * 国家短码,英文名,中文名
 */
export const internationalDomainName = function(shortName) {
    shortName = shortName.toUpperCase();
    let obj = {
        "Al": {
            "chName": "阿尔巴尼亚",
            "ehName": "Albania",
            "internationalDomainName": "Al"
        },
        "DZ": {
            "chName": "阿尔及利亚",
            "ehName": "Algeria",
            "internationalDomainName": "DZ"
        },
        "AF": {
            "chName": "阿富汗",
            "ehName": "Afghanistan",
            "internationalDomainName": "AF"
        },
        "AR": {
            "chName": "阿根廷",
            "ehName": "Argentina",
            "internationalDomainName": "AR"
        },
        "AE": {
            "chName": "阿拉伯联合酋长国",
            "ehName": "United Arab Emirates",
            "internationalDomainName": "AE"
        },
        "Aruba": {
            "chName": "阿鲁巴",
            "ehName": "Aruba",
            "internationalDomainName": "Aruba"
        },
        "OM": {
            "chName": "阿曼",
            "ehName": "Oman",
            "internationalDomainName": "OM"
        },
        "AZ": {
            "chName": "阿塞拜疆",
            "ehName": "Azerbaijan",
            "internationalDomainName": "AZ"
        },
        "EG": {
            "chName": "埃及",
            "ehName": "Egypt",
            "internationalDomainName": "EG"
        },
        "ET": {
            "chName": "埃塞俄比亚",
            "ehName": "Ethiopia",
            "internationalDomainName": "ET"
        },
        "IE": {
            "chName": "爱尔兰",
            "ehName": "Ireland",
            "internationalDomainName": "IE"
        },
        "EE": {
            "chName": "爱沙尼亚",
            "ehName": "Estonia",
            "internationalDomainName": "EE"
        },
        "AD": {
            "chName": "安道尔共和国",
            "ehName": "Andorra",
            "internationalDomainName": "AD"
        },
        "AO": {
            "chName": "安哥拉",
            "ehName": "Angola",
            "internationalDomainName": "AO"
        },
        "AI": {
            "chName": "安圭拉岛",
            "ehName": "Anguilla",
            "internationalDomainName": "AI"
        },
        "AG": {
            "chName": "安提瓜和巴布达",
            "ehName": "Antigua and Barbuda",
            "internationalDomainName": "AG"
        },
        "AU": {
            "chName": "澳大利亚",
            "ehName": "Australia",
            "internationalDomainName": "AU"
        },
        "AT": {
            "chName": "奥地利",
            "ehName": "Austria",
            "internationalDomainName": "AT"
        },
        "BB": {
            "chName": "巴巴多斯",
            "ehName": "Barbados",
            "internationalDomainName": "BB"
        },
        "PG": {
            "chName": "巴布亚新几内亚",
            "ehName": "Papua New Guinea",
            "internationalDomainName": "PG"
        },
        "BS": {
            "chName": "巴哈马",
            "ehName": "Bahamas",
            "internationalDomainName": "BS"
        },
        "PK": {
            "chName": "巴基斯坦",
            "ehName": "Pakistan",
            "internationalDomainName": "PK"
        },
        "PY": {
            "chName": "巴拉圭",
            "ehName": "Paraguay",
            "internationalDomainName": "PY"
        },
        "BH": {
            "chName": "巴林",
            "ehName": "Bahrain",
            "internationalDomainName": "BH"
        },
        "PA": {
            "chName": "巴拿马",
            "ehName": "Panama",
            "internationalDomainName": "PA"
        },
        "BR": {
            "chName": "巴西",
            "ehName": "Brazil",
            "internationalDomainName": "BR"
        },
        "BY": {
            "chName": "白俄罗斯",
            "ehName": "Belarus",
            "internationalDomainName": "BY"
        },
        "BM": {
            "chName": "百慕大群岛",
            "ehName": "Bermuda",
            "internationalDomainName": "BM"
        },
        "BG": {
            "chName": "保加利亚",
            "ehName": "Bulgaria",
            "internationalDomainName": "BG"
        },
        "BX": {
            "chName": "比荷卢经济联盟",
            "ehName": "Benelux",
            "internationalDomainName": "BX"
        },
        "BE": {
            "chName": "比利时",
            "ehName": "Belgium",
            "internationalDomainName": "BE"
        },
        "IS": {
            "chName": "冰岛",
            "ehName": "Iceland",
            "internationalDomainName": "IS"
        },
        "PR": {
            "chName": "波多黎各",
            "ehName": "Bonair, Sint Eustatius and Saba",
            "internationalDomainName": "PR"
        },
        "PL": {
            "chName": "波兰",
            "ehName": "Poland",
            "internationalDomainName": "PL"
        },
        "BO": {
            "chName": "玻利维亚",
            "ehName": "Bolivia",
            "internationalDomainName": "BO"
        },
        "BQ": {
            "chName": "波内赫、圣尤斯特歇斯和萨巴群岛",
            "ehName": "Bonair, Sint Eustatius and Saba",
            "internationalDomainName": "BQ"
        },
        "BA": {
            "chName": "波斯尼亚-黑塞哥维那",
            "ehName": "Bosnia and Herzegovina",
            "internationalDomainName": "BA"
        },
        "BW": {
            "chName": "博茨瓦纳",
            "ehName": "Botswana",
            "internationalDomainName": "BW"
        },
        "BZ": {
            "chName": "伯利兹",
            "ehName": "Belize",
            "internationalDomainName": "BZ"
        },
        "BT": {
            "chName": "不丹",
            "ehName": "Bhutan",
            "internationalDomainName": "BT"
        },
        "BI": {
            "chName": "布隆迪",
            "ehName": "Burundi",
            "internationalDomainName": "BI"
        },
        "KP": {
            "chName": "朝鲜",
            "ehName": "Democratic People's Republic of Korea",
            "internationalDomainName": "KP"
        },
        "DK": {
            "chName": "丹麦",
            "ehName": "Denmark",
            "internationalDomainName": "DK"
        },
        "DE": {
            "chName": "德国",
            "ehName": "Germany",
            "internationalDomainName": "DE"
        },
        "TP": {
            "chName": "东帝汶",
            "ehName": "East Timor",
            "internationalDomainName": "TP"
        },
        "DO": {
            "chName": "多米尼加共和国",
            "ehName": "Dominican Republic",
            "internationalDomainName": "DO"
        },
        "DM": {
            "chName": "多米尼克",
            "ehName": "The Commonwealth of Dominica",
            "internationalDomainName": "DM"
        },
        "RU": {
            "chName": "俄罗斯联邦",
            "ehName": "Russian Federation",
            "internationalDomainName": "RU"
        },
        "EC": {
            "chName": "厄瓜多尔",
            "ehName": "Ecuador",
            "internationalDomainName": "EC"
        },
        "ER": {
            "chName": "厄立特里亚",
            "ehName": "Eritrea",
            "internationalDomainName": "ER"
        },
        "FR": {
            "chName": "法国",
            "ehName": "France",
            "internationalDomainName": "FR"
        },
        "PH": {
            "chName": "菲律宾",
            "ehName": "Philippines",
            "internationalDomainName": "PH"
        },
        "OA": {
            "chName": "非洲知识产权组织",
            "ehName": " African Intellectual Property Organization",
            "internationalDomainName": "OA"
        },
        "FJ": {
            "chName": "斐济",
            "ehName": "Fiji",
            "internationalDomainName": "FJ"
        },
        "FI": {
            "chName": "芬兰",
            "ehName": "Finland",
            "internationalDomainName": "FI"
        },
        "CV": {
            "chName": "佛得角群岛",
            "ehName": "Cape Verde",
            "internationalDomainName": "CV"
        },
        "FK": {
            "chName": "福克兰群岛",
            "ehName": "Falkland Islands",
            "internationalDomainName": "FK"
        },
        "GM": {
            "chName": "冈比亚",
            "ehName": "Gambia",
            "internationalDomainName": "GM"
        },
        "CG": {
            "chName": "刚果",
            "ehName": "Congo",
            "internationalDomainName": "CG"
        },
        "CO": {
            "chName": "哥伦比亚",
            "ehName": "Colombia",
            "internationalDomainName": "CO"
        },
        "CR": {
            "chName": "哥斯达黎加",
            "ehName": "Costa Rica",
            "internationalDomainName": "CR"
        },
        "GD": {
            "chName": "格林纳达",
            "ehName": "Grenada",
            "internationalDomainName": "GD"
        },
        "GE": {
            "chName": "格鲁吉亚",
            "ehName": "Georgia",
            "internationalDomainName": "GE"
        },
        "Bailiwick": {
            "chName": "根西岛",
            "ehName": "Bailiwick of Guernsey",
            "internationalDomainName": "Bailiwick"
        },
        "CU": {
            "chName": "古巴",
            "ehName": "Cuba",
            "internationalDomainName": "CU"
        },
        "GU": {
            "chName": "关岛",
            "ehName": "Guam",
            "internationalDomainName": "GU"
        },
        "GY": {
            "chName": "圭亚那",
            "ehName": "Guyana",
            "internationalDomainName": "GY"
        },
        "KZ": {
            "chName": "哈萨克斯坦",
            "ehName": "Kazakhstan",
            "internationalDomainName": "KZ"
        },
        "HT": {
            "chName": "海地",
            "ehName": "Haiti",
            "internationalDomainName": "HT"
        },
        "KR": {
            "chName": "韩国",
            "ehName": "Republic of Korea",
            "internationalDomainName": "KR"
        },
        "NL": {
            "chName": "荷兰",
            "ehName": "Netherlands",
            "internationalDomainName": "NL"
        },
        "ME": {
            "chName": "黑山",
            "ehName": "Montenegro",
            "internationalDomainName": "ME"
        },
        "HN": {
            "chName": "洪都拉斯",
            "ehName": "Honduras",
            "internationalDomainName": "HN"
        },
        "DJ": {
            "chName": "吉布提",
            "ehName": "Djibouti",
            "internationalDomainName": "DJ"
        },
        "KG": {
            "chName": "吉尔吉斯斯坦",
            "ehName": "Kyrgyzstan",
            "internationalDomainName": "KG"
        },
        "CA": {
            "chName": "加拿大",
            "ehName": "Canada",
            "internationalDomainName": "CA"
        },
        "GH": {
            "chName": "加纳",
            "ehName": "Ghana",
            "internationalDomainName": "GH"
        },
        "BL-Gaza": {
            "chName": "加沙地区",
            "ehName": "Gaza",
            "internationalDomainName": "BL-Gaza"
        },
        "KH": {
            "chName": "柬埔寨",
            "ehName": "Cambodia",
            "internationalDomainName": "KH"
        },
        "CZ": {
            "chName": "捷克共和国",
            "ehName": "Czech Republic",
            "internationalDomainName": "CZ"
        },
        "ZW": {
            "chName": "津巴布韦",
            "ehName": "Zimbabwe",
            "internationalDomainName": "ZW"
        },
        "QA": {
            "chName": "卡塔尔",
            "ehName": "Qatar",
            "internationalDomainName": "QA"
        },
        "KY": {
            "chName": "开曼群岛",
            "ehName": "Cayman Islands",
            "internationalDomainName": "KY"
        },
        "Kosovo": {
            "chName": "科索沃",
            "ehName": "Kosovo",
            "internationalDomainName": "Kosovo"
        },
        "KW": {
            "chName": "科威特",
            "ehName": "Kuwait",
            "internationalDomainName": "KW"
        },
        "HR": {
            "chName": "克罗地亚",
            "ehName": "Croatia",
            "internationalDomainName": "HR"
        },
        "KE": {
            "chName": "肯尼亚",
            "ehName": "Kenya",
            "internationalDomainName": "KE"
        },
        "CW": {
            "chName": "库拉索",
            "ehName": "Curacao",
            "internationalDomainName": "CW"
        },
        "LV": {
            "chName": "拉脱维亚",
            "ehName": "Latvia",
            "internationalDomainName": "LV"
        },
        "LS": {
            "chName": "莱索托",
            "ehName": "Lesotho",
            "internationalDomainName": "LS"
        },
        "LA": {
            "chName": "老挝",
            "ehName": "Lao People's Democratic Republic",
            "internationalDomainName": "LA"
        },
        "LB": {
            "chName": "黎巴嫩",
            "ehName": "Lebanon",
            "internationalDomainName": "LB"
        },
        "LR": {
            "chName": "利比里亚",
            "ehName": "Liberia",
            "internationalDomainName": "LR"
        },
        "LY": {
            "chName": "利比亚",
            "ehName": "Libya",
            "internationalDomainName": "LY"
        },
        "LT": {
            "chName": "立陶宛",
            "ehName": "Lithuania",
            "internationalDomainName": "LT"
        },
        "LI": {
            "chName": "列支敦士登",
            "ehName": "Liechtenstein",
            "internationalDomainName": "LI"
        },
        "RE": {
            "chName": "留尼旺",
            "ehName": "Reunion",
            "internationalDomainName": "RE"
        },
        "LU": {
            "chName": "卢森堡",
            "ehName": "Luxembourg",
            "internationalDomainName": "LU"
        },
        "RW": {
            "chName": "卢旺达",
            "ehName": "Rwanda",
            "internationalDomainName": "RW"
        },
        "RO": {
            "chName": "罗马尼亚",
            "ehName": "Romania",
            "internationalDomainName": "RO"
        },
        "MG": {
            "chName": "马达加斯加",
            "ehName": "Madagascar",
            "internationalDomainName": "MG"
        },
        "MV": {
            "chName": "马尔代夫",
            "ehName": "Maldives",
            "internationalDomainName": "MV"
        },
        "MT": {
            "chName": "马耳他",
            "ehName": "Malta",
            "internationalDomainName": "MT"
        },
        "MW": {
            "chName": "马拉维",
            "ehName": "Malawi",
            "internationalDomainName": "MW"
        },
        "MY": {
            "chName": "马来西亚",
            "ehName": "Malaysia",
            "internationalDomainName": "MY"
        },
        "MU": {
            "chName": "毛里求斯",
            "ehName": "Mauritius",
            "internationalDomainName": "MU"
        },
        "US": {
            "chName": "美国",
            "ehName": "United States of America",
            "internationalDomainName": "US"
        },
        "MN": {
            "chName": "蒙古",
            "ehName": "Mongolia",
            "internationalDomainName": "MN"
        },
        "MS": {
            "chName": "蒙特塞拉特岛",
            "ehName": "Montserrat Islands",
            "internationalDomainName": "MS"
        },
        "BD": {
            "chName": "孟加拉国",
            "ehName": "Bangladesh",
            "internationalDomainName": "BD"
        },
        "PE": {
            "chName": "秘鲁",
            "ehName": "Peru",
            "internationalDomainName": "PE"
        },
        "MM": {
            "chName": "缅甸",
            "ehName": "Burma",
            "internationalDomainName": "MM"
        },
        "MD": {
            "chName": "摩尔多瓦",
            "ehName": "Republic of Moldova",
            "internationalDomainName": "MD"
        },
        "MA": {
            "chName": "摩洛哥",
            "ehName": "Morocco",
            "internationalDomainName": "MA"
        },
        "MC": {
            "chName": "摩纳哥",
            "ehName": "Monaco",
            "internationalDomainName": "MC"
        },
        "MZ": {
            "chName": "莫桑比克",
            "ehName": "Mozambique",
            "internationalDomainName": "MZ"
        },
        "MX": {
            "chName": "墨西哥",
            "ehName": "Mexico",
            "internationalDomainName": "MX"
        },
        "NA": {
            "chName": "纳米比亚",
            "ehName": "Namibia",
            "internationalDomainName": "NA"
        },
        "ZA": {
            "chName": "南非",
            "ehName": "South Africa",
            "internationalDomainName": "ZA"
        },
        "NR": {
            "chName": "瑙鲁",
            "ehName": "Nauru",
            "internationalDomainName": "NR"
        },
        "NP": {
            "chName": "尼泊尔",
            "ehName": "Nepal",
            "internationalDomainName": "NP"
        },
        "NI": {
            "chName": "尼加拉瓜",
            "ehName": "Nicaragua",
            "internationalDomainName": "NI"
        },
        "NG": {
            "chName": "尼日利亚",
            "ehName": "Nigeria",
            "internationalDomainName": "NG"
        },
        "NO": {
            "chName": "挪威",
            "ehName": "Norway",
            "internationalDomainName": "NO"
        },
        "EU": {
            "chName": "欧盟",
            "ehName": "European Trademark Office",
            "internationalDomainName": "EU"
        },
        "PT": {
            "chName": "葡萄牙",
            "ehName": "Portugal",
            "internationalDomainName": "PT"
        },
        "MK": {
            "chName": "前南斯拉夫马其顿",
            "ehName": "The former Yugoslav Republic of Macedonia",
            "internationalDomainName": "MK"
        },
        "JP": {
            "chName": "日本",
            "ehName": "Japan",
            "internationalDomainName": "JP"
        },
        "SE": {
            "chName": "瑞典",
            "ehName": "Sweden",
            "internationalDomainName": "SE"
        },
        "CH": {
            "chName": "瑞士",
            "ehName": "Switzerland",
            "internationalDomainName": "CH"
        },
        "EV": {
            "chName": "萨尔瓦多",
            "ehName": "El Salvador",
            "internationalDomainName": "EV"
        },
        "WS": {
            "chName": "萨摩亚",
            "ehName": "Samoa",
            "internationalDomainName": "WS"
        },
        "RS": {
            "chName": "塞尔维亚",
            "ehName": "Serbia",
            "internationalDomainName": "RS"
        },
        "SL": {
            "chName": "塞拉利昂",
            "ehName": "Sierra Leone",
            "internationalDomainName": "SL"
        },
        "CY": {
            "chName": "塞浦路斯",
            "ehName": "Cyprus",
            "internationalDomainName": "CY"
        },
        "SC": {
            "chName": "塞舌尔",
            "ehName": "Seychelles",
            "internationalDomainName": "SC"
        },
        "SA": {
            "chName": "沙特阿拉伯",
            "ehName": "Saudi Arabia",
            "internationalDomainName": "SA"
        },
        "ST": {
            "chName": "圣多美和普林西比",
            "ehName": "Sao Tome and Principe",
            "internationalDomainName": "ST"
        },
        "LC": {
            "chName": "圣卢西亚",
            "ehName": "Saint Lucia",
            "internationalDomainName": "LC"
        },
        "SX": {
            "chName": "圣马丁",
            "ehName": "Sint Maarten (Dutch part)",
            "internationalDomainName": "SX"
        },
        "SM": {
            "chName": "圣马力诺",
            "ehName": "San Marino",
            "internationalDomainName": "SM"
        },
        "LK": {
            "chName": "斯里兰卡",
            "ehName": "Sri Lanka",
            "internationalDomainName": "LK"
        },
        "SK": {
            "chName": "斯洛伐克",
            "ehName": "Slovakia",
            "internationalDomainName": "SK"
        },
        "SI": {
            "chName": "斯洛文尼亚",
            "ehName": "Slovenia",
            "internationalDomainName": "SI"
        },
        "SZ": {
            "chName": "斯威士兰",
            "ehName": "Swaziland",
            "internationalDomainName": "SZ"
        },
        "SD": {
            "chName": "苏丹",
            "ehName": "Sudan",
            "internationalDomainName": "SD"
        },
        "SR": {
            "chName": "苏里南",
            "ehName": "Suriname",
            "internationalDomainName": "SR"
        },
        "SB": {
            "chName": "所罗门群岛",
            "ehName": "Solomon Islands",
            "internationalDomainName": "SB"
        },
        "SO": {
            "chName": "索马里",
            "ehName": "Somalia",
            "internationalDomainName": "SO"
        },
        "TJ": {
            "chName": "塔吉克斯坦",
            "ehName": "Tajikistan",
            "internationalDomainName": "TJ"
        },
        "TH": {
            "chName": "泰国",
            "ehName": "Thailand",
            "internationalDomainName": "TH"
        },
        "TZ1": {
            "chName": "坦桑尼亚（桑给巴尔）",
            "ehName": "Tanzania（Zanzibar）",
            "internationalDomainName": "TZ1"
        },
        "TZ": {
            "chName": "坦桑尼亚（坦噶尼喀）",
            "ehName": "Tanzania（Tanganyika）",
            "internationalDomainName": "TZ"
        },
        "TO": {
            "chName": "汤加",
            "ehName": "Tonga",
            "internationalDomainName": "TO"
        },
        "TT": {
            "chName": "特立尼达和多巴哥",
            "ehName": "Trinidad and Tobago",
            "internationalDomainName": "TT"
        },
        "TN": {
            "chName": "突尼斯",
            "ehName": "Tunisia",
            "internationalDomainName": "TN"
        },
        "TR": {
            "chName": "土耳其",
            "ehName": "Turkey",
            "internationalDomainName": "TR"
        },
        "TM": {
            "chName": "土库曼斯坦",
            "ehName": "Turkmenistan",
            "internationalDomainName": "TM"
        },
        "GT": {
            "chName": "危地马拉",
            "ehName": "Guatemala",
            "internationalDomainName": "GT"
        },
        "VE": {
            "chName": "委内瑞拉",
            "ehName": "Venezuela",
            "internationalDomainName": "VE"
        },
        "BN": {
            "chName": "文莱",
            "ehName": "Brunei",
            "internationalDomainName": "BN"
        },
        "UG": {
            "chName": "乌干达",
            "ehName": "Uganda",
            "internationalDomainName": "UG"
        },
        "UA": {
            "chName": "乌克兰",
            "ehName": "Ukraine",
            "internationalDomainName": "UA"
        },
        "UY": {
            "chName": "乌拉圭",
            "ehName": "Uruguay",
            "internationalDomainName": "UY"
        },
        "UZ": {
            "chName": "乌兹别克斯坦",
            "ehName": "Uzbekistan",
            "internationalDomainName": "UZ"
        },
        "ES": {
            "chName": "西班牙",
            "ehName": "Spain",
            "internationalDomainName": "ES"
        },
        "GR": {
            "chName": "希腊",
            "ehName": "Greece",
            "internationalDomainName": "GR"
        },
        "SG": {
            "chName": "新加坡",
            "ehName": "Singapore",
            "internationalDomainName": "SG"
        },
        "NZ": {
            "chName": "新西兰",
            "ehName": "New Zealand",
            "internationalDomainName": "NZ"
        },
        "HU": {
            "chName": "匈牙利",
            "ehName": "Hungary",
            "internationalDomainName": "HU"
        },
        "SY": {
            "chName": "叙利亚",
            "ehName": "Syrian Arab Republic",
            "internationalDomainName": "SY"
        },
        "JM": {
            "chName": "牙买加",
            "ehName": "Jamaica",
            "internationalDomainName": "JM"
        },
        "AM": {
            "chName": "亚美尼亚",
            "ehName": "Armenia ",
            "internationalDomainName": "AM"
        },
        "YE": {
            "chName": "也门",
            "ehName": "Yemen",
            "internationalDomainName": "YE"
        },
        "IQ": {
            "chName": "伊拉克",
            "ehName": "Iraq",
            "internationalDomainName": "IQ"
        },
        "IR": {
            "chName": "伊朗",
            "ehName": "Islamic Republic of Iran",
            "internationalDomainName": "IR"
        },
        "IL": {
            "chName": "以色列",
            "ehName": "Israel",
            "internationalDomainName": "IL"
        },
        "IT": {
            "chName": "意大利",
            "ehName": "Italy",
            "internationalDomainName": "IT"
        },
        "IN": {
            "chName": "印度",
            "ehName": "India",
            "internationalDomainName": "IN"
        },
        "ID": {
            "chName": "印度尼西亚",
            "ehName": "Indonesia",
            "internationalDomainName": "ID"
        },
        "GB": {
            "chName": "英国",
            "ehName": "United Kindom",
            "internationalDomainName": "GB"
        },
        "YSWEJQD": {
            "chName": "英属维尔京群岛",
            "ehName": "British Virgin Islands",
            "internationalDomainName": "YSWEJQD"
        },
        "JO": {
            "chName": "约旦",
            "ehName": "Jordan",
            "internationalDomainName": "JO"
        },
        "BL": {
            "chName": "约旦河西岸地区",
            "ehName": "West Bank",
            "internationalDomainName": "BL"
        },
        "VN": {
            "chName": "越南",
            "ehName": "Viet Nam",
            "internationalDomainName": "VN"
        },
        "ZM": {
            "chName": "赞比亚",
            "ehName": "Zambia",
            "internationalDomainName": "ZM"
        },
        "Jersey Isl": {
            "chName": "泽西岛",
            "ehName": "Bailiwick of Jersey",
            "internationalDomainName": "Jersey Isl"
        },
        "GI": {
            "chName": "直布罗陀",
            "ehName": "Gibraltar",
            "internationalDomainName": "GI"
        },
        "CL": {
            "chName": "智利",
            "ehName": "Chile",
            "internationalDomainName": "CL"
        },
        "CN": {
            "chName": "中国",
            "ehName": "China",
            "internationalDomainName": "CN"
        },
        "MO": {
            "chName": "中国澳门",
            "ehName": "Macao",
            "internationalDomainName": "MO"
        },
        "TW": {
            "chName": "中国台湾",
            "ehName": "Taiwan",
            "internationalDomainName": "TW"
        },
        "HK": {
            "chName": "中国香港",
            "ehName": "Hong Kong",
            "internationalDomainName": "HK"
        }
    };
    if (!obj[shortName]) return {
        "chName": shortName,
        "ehName": shortName,
        "internationalDomainName": shortName
    };
    else return obj[shortName];
};

/**
 * 存储localStorage
 */
export const setLocal = (name, content) => {
    if (!name) return
    if (typeof content !== 'string') {
        content = JSON.stringify(content)
    }
    window.localStorage.setItem(name, content)
}

/**
 * 获取localStorage
 */
export const getLocal = name => {
    if (!name) return
    if (!window.localStorage.getItem(name)) return ''
    try {
        return JSON.parse(window.localStorage.getItem(name))
    } catch (error) {
        // console.log(error);
        return window.localStorage.getItem(name)
    }
}

/**
 * 删除localStorage
 */
export const removeLocal = name => {
    if (!name) return
    window.localStorage.removeItem(name)
}

/**
 * 比较2个数组是否相同
 */
export const arrayIsEquals = (arr1, arr2) => {
    arr1.sort()
    arr2.sort()
    if (JSON.stringify(arr1) == JSON.stringify(arr2)) return true
    else return false
}

/**
 * 获取字符长度,用于图片生成文字
 */
export const getBLen = str => {
        var len = 0
        for (var i = 0; i < str.length; i++) {
            if (str.charCodeAt(i) > 127 || str.charCodeAt(i) == 94 || str.charCodeAt(i) == 87) {
                len += 2
            } else if ((str.charCodeAt(i) >= 65 && str.charCodeAt(i) <= 90)) {
                len += 1.3
            } else {
                len++
            }
        }
        if (len == 1) len = 1.8
        if (len == 1.3) len = 2
            // console.log(len);
        return len
    }
    /**
     * 获取style样式
     */
export const getStyle = (element, attr, NumberMode = 'int') => {
        let target
            // scrollTop 获取方式不同，没有它不属于style，而且只有document.body才能用
        if (attr === 'scrollTop') {
            target = element.scrollTop
        } else if (element.currentStyle) {
            target = element.currentStyle[attr]
        } else {
            target = document.defaultView.getComputedStyle(element, null)[attr]
        }
        // 在获取 opactiy 时需要获取小数 parseFloat
        return NumberMode == 'float' ? parseFloat(target) : parseInt(target)
    }
    /**
     * base64转文件
     */
export const convertBase64UrlToBlob = function(urlData) {
        var bytes = window.atob(urlData.split(',')[1]) // 去掉url的头，并转换为byte

        // 处理异常,将ascii码小于0的转换为大于0
        var ab = new ArrayBuffer(bytes.length)
        var ia = new Uint8Array(ab)
        for (var i = 0; i < bytes.length; i++) {
            ia[i] = bytes.charCodeAt(i)
        }
        return new Blob([ab], {
            type: 'image/png'
        })
    }
    /**
     * bolb转base64
     */
export const blobToDataURL = function(blob, callback) {
        var a = new FileReader()
        a.onload = function(e) {
            callback(e.target.result)
        }
        a.readAsDataURL(blob)
    }
    /**
     * 上传图片返回数据需要加上此url
     */
// import UploadPdf from '../../static/img/pdf1.png'
//
// export const updataUrl = (data, options) => {
//         let canhsu = options || {}
//         if (!data) return ''
//         else if (data.includes('/static/img/PDFshow.104dc7b.jpg')) return data
//         else if (data && data.includes('data:image')) return data
//         else if (data && data.split('.').pop(1).toUpperCase() == 'PDF' && !canhsu.pdfNeedHttp) return UploadPdf
//         else if (data && !data.includes('http://')) return  data
//         else return ''
//     }
    /**
     * 查询返回数据需要加上此url
     */
export const queryDataUrl = (data) => {
    // if (data && !data.includes("http://")) return rootImgUrl_qiufeng + data;
    if (data && data.includes('data:image')) return data
    else if (data && !data.includes('http://')) return  data
    else return ''
}
export const imgHeightWidth = function(url, classname) {
        return new Promise((resolve, reject) => {
            let name = 'c' + classname
            $('body').append('<img src="' + url + '" alt="" style="display: none" class="' + name + '">')
            $('.' + name).on('load', function() {
                let obj = {
                    width: $(this).width(),
                    height: $(this).height()
                }
                $(this).remove()
                resolve(obj)
            })
        })
    }
    /**
     * log_
     */
export const log_ = (data, number) => {
        let arr = ['demo', 'highQuery']
        if (arr.includes(number)) {
            // console.log(data);
        }
    }
    /**
     * 锁定滚动,适合dialog,暂时不用
     */
export const isScoll_ = (bol) => {
        if (bol) {
            $('html').css({
                'overflow-y': 'hidden'
            })
        } else {
            $('html').css({
                'overflow-y': 'visible'
            })
        }
    }
    /**
     * 锁定滚动,适合待定
     */
export const daiding_ = (bol) => {

}

export const compareKeyMergeArr = function(resultArr, newArr, key) {
    newArr.map((newItem) => {
            let filterArr = resultArr.filter(function(resultItem) {
                return resultItem[key] == newItem[key]
            })
            if (filterArr.length == 0) {
                resultArr.push(newItem)
            }
        })
        // console.log(JSON.stringify(resultArr));
    return resultArr
}

export const getQueryObject = function(url) {
    url = url == null ? window.location.href : url;
    const search = url.substring(url.lastIndexOf('?') + 1);
    const obj = {};
    const reg = /([^?&=]+)=([^?&=]*)/g;
    search.replace(reg, (rs, $1, $2) => {
        const name = decodeURIComponent($1)
        let val = decodeURIComponent($2)
        val = String(val)
        obj[name] = val
        return rs
    });
    return obj
}
export const htmlDecode = text => {
    var arrEntities = { 'lt': '<', 'gt': '>', 'nbsp': ' ', 'amp': '&', 'quot': '"' };
    return text.replace(/&(lt|gt|nbsp|amp|quot);/ig,
        function(all, t) {
            return arrEntities[t];
        }
    )
};
export const translates = text => {
    var temp = document.createElement("div");
    temp.innerHTML = text;
    var output = temp.innerText || temp.textContent;
    temp = null;
    return output;
};
export const codeToPunctuation = str => {
    str = str.replace(/&(nmb|cnm);/g, function(str, name) {
        switch (name) {
            case 'nmb':
                return '(';

            case 'cnm':
                return ')';
            case 'amp':
                return '&';

            case 'apos':
                return '\'';

            case 'gt':
                return '>';

            case 'lt':
                return '<';

            case 'quot':
                return '\"';
        }
    });
    return str;
};
export const $_GET = (function() {
        var url = window.document.location.href.toString()
        var u = url.split('?')
        if (typeof(u[1]) === 'string') {
            if (u[1].indexOf('#') != -1) {
                u = u[1].split('#')
                u = u[0].split('&')
            } else {
                u = u[1].split('&')
            }
            var get = {}
            for (var i in u) {
                var j = u[i].split('=')
                get[j[0]] = j[1]
            }
            return get
        } else {
            return {}
        }
    })()
    /**
     * 续展绝限,可续展状态
     */
    /* 根据日期推算续展绝限,可续展状态 */
export const extensionDiscription = (date) => {
    let validdate = moment(date).format('YYYY-MM-DD')
    let today = moment(new Date()).format('YYYY-MM-DD')
    let oneYearBefore = moment(date).subtract(1, 'years').calendar()
    oneYearBefore = moment(oneYearBefore).format('YYYY-MM-DD')
    let sixMonthAfter = moment(date).add(6, 'months').calendar()
    sixMonthAfter = moment(sixMonthAfter).format('YYYY-MM-DD')
    let status = ''
    if (today < oneYearBefore) {
        status = '预定续展'
    } else if (today >= oneYearBefore && today <= validdate) {
        status = '正常续展'
    } else if (today > validdate && today <= sixMonthAfter) {
        status = '宽展期续展'
    } else if (today > sixMonthAfter) {
        status = '不可续展'
    }
    if (!date) {
        validdate = oneYearBefore = sixMonthAfter = status = ''
    }
    return {
        validdate, // 专用权到期日
        oneYearBefore, // 专用权到期日一年前
        sixMonthAfter, // 专用权到日半年后
        status, // 是否可续展
        today // 今天
    }
}

/**
 * 上传文件校验
 */
/*
        let bol = this.formatCheck({
          file: file,
          suffix:["JPG","PNG","GIF","TIFF"],
          size:"2048000",
        });
除了file,其他都是要求
*/
export const formatCheck = function(obj) { // 传参对象,
    if (!obj.suffix) {
        obj.suffix = ['JPG', 'PNG', 'GIF', 'TIFF', 'JPEG', 'BMP', 'PDF', 'JPEG']
    }
    if (!obj.size) {
        obj.size = 2048000
    }
    var suffix = obj.file.name.split('.').pop().toUpperCase()
    let isHave = obj.suffix.includes(suffix)
    if (!isHave) {
        this.$message(this.$t('query_lang.Image_format_error'))
        return false
    }
    if (obj.file.size > obj.size) {
        this.$message(this.$t('query_lang.The_file_is_too_large'))
        return false
    }
    return true
}

export const formatCheck2 = function(obj) { // 传参对象,
        if (!obj.suffix) {
            obj.suffix = ['JPG', 'PNG', 'GIF', 'TIFF']
        }
        if (!obj.size) {
            obj.size = 2048000
        }
        var suffix = obj.file.name.split('.').pop().toUpperCase()
        let isHave = obj.suffix.includes(suffix)
        if (!isHave) {
            this.$message(this.$t('query_lang.Image_format_error'))
            return false
        }
        if (obj.file.size > obj.size) {
            this.$message(this.$t('query_lang.The_file_is_too_large'))
            return false
        }
        if (obj.pixel) {
            console.log('if')
            imgHeightWidth(obj.file.url, 'ceshipixel')
                .then((data) => {
                    if (data.height < obj.pixel.heigthMin || data.height > obj.pixel.heightMax) {
                        this.$message('图片尺寸不符')
                        return false
                    }
                    if (data.width < obj.pixel.widthMin || data.width > obj.pixel.widthMax) {
                        this.$message('图片尺寸不符')
                        return false
                    }
                    return true
                })
        } else {
            console.log('else')
            return true
        }

        /*
        this.formatCheck({
                file: file,
                suffix: ["JPG"],
                size: 204800,
                pixel: {
                  heightMax: 1500,
                  widthMax: 1500,
                  heigthMin: 400,
                  widthMin: 400,
                }
              });
        */
    }
    /**
     *前端翻译
     * **/
export const feTranslate = function(str) {
    if (!str) return str
    str = str.trim()
    i18n.locale = JSON.parse(window.localStorage.lang).lang // 再来一下,才稳定
    let result = ''
    switch (str) {
        case '企业':
            result = i18n.t('personal_lang1.Organization')
            break
        case '自然人':
            result = i18n.t('personal_lang1.Individual')
            break
        case '商标续展':
            result = i18n.t('translate_FE_lang.linshi1')
            break
        case '注册性分析':
        case '可注册性分析':
        case '可注册性分析':
            result = i18n.t('follow_up_FE_lang.Analysis_of_trademark')
            break
        case '中国大陆商标注册':
            result = i18n.t('translate_FE_lang.linshi2')
            break
        case '中国香港商标注册':
            result = i18n.t('translate_FE_lang.linshi3')
            break
        case '查询成功':
            result = i18n.t('query_lang.Search_successful')
            break
        case '预定续展':
            result = i18n.t('follow_up_FE_lang.Schedule_renewal')
            break
        case '正常续展':
            result = i18n.t('follow_up_FE_lang.Renewal')
            break
        case '宽展期续展':
            result = i18n.t('follow_up_FE_lang.Extension_renewal')
            break
        case '不可续展':
            result = i18n.t('follow_up_FE_lang.Unable_to_renew')
            break
        case '实收金额':
            result = i18n.t('follow_up_FE_lang.Amount_paid')
            break
        case '颜色组合': // 对应bug1682
            result = 'Color mark'
            break
        case '三维标志': // 对应bug1682
            result = '3D mark'
            break
        case '立体商标': // 对应bug1682
            result = '3D mark'
            break
        case '声音商标': // 对应bug1682
            result = 'Sound Mark'
            break
        case '图形': // 对应bug1682
            result = 'Design'
            break
        case '指定颜色': // 对应bug1682
            result = 'Claiming color protection'
            break
        default:
            result = str
    }
    return result
}
export const marketplaceTranslate = function(str) {
    let obj = {
        yihaodian: "一号店",
        vip: "唯品会",
        jd: "京东",
        dangdang: "当当",
    };
    return obj[str] || str;
};
/**
 存储订单状态
 **/
export const setOrderStatus = function(orderId, status) {
    let localStatus = getLocal('orderStaus') ? getLocal('orderStau s') : {}
    let orderStaus = Object.assign(localStatus, {
        [orderId]: status
    })
    setLocal('orderStaus', orderStaus)
}
export const setOrderStatus2 = () => {
        console.log(5, 'setOrderStatus2')
    }
    /**
     分页国际化
     **/
export const paginationEn = function() {
        /* 分页国际化; */
        if (this.$i18n && this.$i18n.locale == 'en-US') {
            this.$nextTick(() => {
                /* 共多少条 */
                /*      let text = $(".el-pagination__total").html();//共 3147 条
                      text = text.replace("共", "");
                      text = text.replace("条", '<span style="color: #454545;font-size: 14px;">results</span>');
                      $(".el-pagination__total").html(text); */
                /* 前往 */
                $('.go-to-page-l').remove()
                $('.el-input.el-pagination__editor.is-in-pagination').before('<span class="go-to-page-l" style="color: #454545;font-size: 14px;">Go to page </span>')
            })
        }
    }
    // 马丹丹分页
export const pagingnEn = function() {
        /* 分页国际化; */
        if (this.$i18n.locale == 'en-US') {
            this.$nextTick(() => {
                $('.go-to-page-l').remove()
                $('.el-input.el-pagination__editor.is-in-pagination').before('<span class="go-to-page-l" style="color: #454545;font-size: 14px;">Go to page </span>')
            })
        }
    }
    /**
     货币符号
     **/
export const currencySymbol = function(currency) {
        let obj = {
            CNY: 'RMB¥',
            USD: '$',
            EUR: '€'
                /*    GBP:"£",
                    CAD:"Can$",
                    JPY:"JPY¥",
                    AUD:"$A",
                    HKD:"HK$",
                    CHF:"SF", */
        }
        if (obj[currency] != undefined) return obj[currency]
        else return currency
    }
    /**
     全角转半角
     **/
export const ToCDB = function(str) {
    let tmp = ''
    for (let i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) > 65248 && str.charCodeAt(i) < 65375) {
            tmp += String.fromCharCode(str.charCodeAt(i) - 65248)
        } else {
            tmp += String.fromCharCode(str.charCodeAt(i))
        }
    }
    return tmp
}

// export const aesjsHappy = function(str, decryption) {
//     let key = [19, 5, 2, 5, 4, 2, 6, 8, 2, 4, 2, 0, 2, 0, 5, 5]
//     let text = str
//     if (!decryption) {
//         let textBytes = aesjs.utils.utf8.toBytes(text)
//         let aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5))
//         let encryptedBytes = aesCtr.encrypt(textBytes)
//         let encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes)
//         console.log(encryptedHex)
//     } else {
//         let encryptedBytes = aesjs.utils.hex.toBytes(text)
//         let aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5))
//         let decryptedBytes = aesCtr.decrypt(encryptedBytes)
//         let decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes)
//         console.log(decryptedText)
//     }
// }

/**
 this.domToBase64($(".preview")[0])
 .then((data)=>{
            console.log(data.src);
          })
 .catch((e)=>{
            console.log(e);
          });
 **/

export const domToBase64 = function(who) {
        let shareContent = who
        let width = shareContent.offsetWidth
        let height = shareContent.offsetHeight
        let canvas = document.createElement('canvas')
        let scale = 2
        canvas.width = width * scale
        canvas.height = height * scale
        canvas.getContext('2d').scale(scale, scale)
        let opts = {
            scale: scale,
            canvas: canvas,
            logging: false,
            width: width,
            height: height,
            useCORS: true // 别忘记提醒老汤设置图片配置CORS,给上传的图片设置即可;
        }
        return new Promise((resolve, reject) => {
            html2canvas(shareContent, opts)
                .then(canvas => {
                    let context = canvas.getContext('2d')
                    context.mozImageSmoothingEnabled = false
                    context.webkitImageSmoothingEnabled = false
                    context.msImageSmoothingEnabled = false
                    context.imageSmoothingEnabled = false
                    let a = Canvas2Image.convertToImage(canvas, canvas.width, canvas.height)
                    resolve(a)
                })
                .catch((e) => {
                    reject(e)
                })
        })
    }
    /**
     * 浏览器版本检测
     */
    /* alert("浏览器:" + getBroswer().broswer + "\n 版本:" + getBroswer().version); */
export const getBroswer = function() {
        var sys = {}
        var ua = navigator.userAgent.toLowerCase()
        var s;
        (s = ua.match(/edge\/([\d.]+)/)) ? sys.edge = s[1]: (s = ua.match(/rv:([\d.]+)\) like gecko/)) ? sys.ie = s[1] :
            (s = ua.match(/msie ([\d.]+)/)) ? sys.ie = s[1] :
            (s = ua.match(/firefox\/([\d.]+)/)) ? sys.firefox = s[1] :
            (s = ua.match(/chrome\/([\d.]+)/)) ? sys.chrome = s[1] :
            (s = ua.match(/opera.([\d.]+)/)) ? sys.opera = s[1] :
            (s = ua.match(/version\/([\d.]+).*safari/)) ? sys.safari = s[1] : 0

        if (sys.edge) return {
            broswer: 'Edge',
            version: sys.edge
        }
        if (sys.ie) return {
            broswer: 'IE',
            version: sys.ie
        }
        if (sys.firefox) return {
            broswer: 'Firefox',
            version: sys.firefox
        }
        if (sys.chrome) return {
            broswer: 'Chrome',
            version: sys.chrome
        }
        if (sys.opera) return {
            broswer: 'Opera',
            version: sys.opera
        }
        if (sys.safari) return {
            broswer: 'Safari',
            version: sys.safari
        }

        return {
            broswer: '',
            version: '0'
        }
    }
    /**
     * 系统检测
     */
export const getOsInfo = function() {
    var userAgent = navigator.userAgent.toLowerCase()
    var name = 'Unknown'
    var version = 'Unknown'
    if (userAgent.indexOf('win') > -1) {
        name = 'Windows'
        if (userAgent.indexOf('windows nt 5.0') > -1) {
            version = 'Windows 2000'
        } else if (userAgent.indexOf('windows nt 5.1') > -1 || userAgent.indexOf('windows nt 5.2') > -1) {
            version = 'Windows XP'
        } else if (userAgent.indexOf('windows nt 6.0') > -1) {
            version = 'Windows Vista'
        } else if (userAgent.indexOf('windows nt 6.1') > -1 || userAgent.indexOf('windows 7') > -1) {
            version = 'Windows 7'
        } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows 8') > -1) {
            version = 'Windows 8'
        } else if (userAgent.indexOf('windows nt 6.3') > -1) {
            version = 'Windows 8.1'
        } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows nt 10.0') > -1) {
            version = 'Windows 10'
        } else {
            version = 'Unknown'
        }
    } else if (userAgent.indexOf('iphone') > -1) {
        name = 'Iphone'
    } else if (userAgent.indexOf('mac') > -1) {
        name = 'Mac'
    } else if (userAgent.indexOf('x11') > -1 || userAgent.indexOf('unix') > -1 || userAgent.indexOf('sunname') > -1 || userAgent.indexOf('bsd') > -1) {
        name = 'Unix'
    } else if (userAgent.indexOf('linux') > -1) {
        if (userAgent.indexOf('android') > -1) {
            name = 'Android'
        } else {
            name = 'Linux'
        }
    } else {
        name = 'Unknown'
    }
    var os = new Object()
    os.name = name
    os.version = version
    return os
        // document.write("系统：" + os.name + "版本:" + os.version)
}

/**
 * 输入校验
 */
export const inputCheckout = function(obj) {
        i18n.locale = JSON.parse(window.localStorage.lang).lang // 再来一下,才稳定
        let bol = false
        switch (obj.who) {
            case 'enApplicant':
                {
                    if (obj.val != '' && !this.isInputEn(obj.val)) {
                        bol = false
                    } else {
                        bol = true
                    }
                    if (!bol && obj.msg) {
                        this.$message('书式类型为国外时必须输入英文名称')
                    }
                    break
                }
            case 'enAddress':
                {
                    if (obj.val != '' && !this.isInputEn(obj.val)) {
                        bol = false
                    } else {
                        bol = true
                    }
                    if (!bol && obj.msg) {
                        this.$message('书式类型为国外时必须输入英文详细地址')
                    }
                    break
                }
            case 'cnApplicant':
                {
                    var arr = new Array()
                    arr = obj.val.split(' ')
                    bol = true
                    if (arr.length != 1) {
                        bol = false
                    }
                    if (!bol && obj.msg) {
                        this.$message('申请人名称不能有空格、只能是汉字，申请人是企业时必须大于等于1个汉字')
                    }
                    break
                }
            case 'cnAddress':
                {
                    if (this.fomat_qh(obj.val)) {
                        bol = true
                    } else {
                        bol = false
                    }
                    break
                }
            case 'number':
                {
                    let reg = /^[0-9A-Za-z]+$/
                    bol = reg.test(obj.val)
                    if (!bol && obj.msg) {
                        // Message(i18n.t('follow_up_FE_lang.Cannot_contain_special_characters'));//只能数字字母
                    }
                    break
                }
            case 'teshu':
                {
                    let reg = /^[\u0391-\uFFE5A-Za-z0-9\s\+]+$/
                    bol = reg.test(obj.val)
                    if (!bol && obj.msg) {
                        Message(i18n.t('follow_up_FE_lang.Cannot_contain_special_characters')) // 不能包含特殊字符
                    }
                    break
                }
            case 'enterpriseCredit':
                {
                    let reg1 = /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/
                    let reg2 = /^[1-9A-GY]{1}[1239]{1}[1-5]{1}[0-9]{5}[0-9A-Z]{10}$/
                    bol = reg1.test(obj.val) || reg2.test(obj.val)
                    if (!bol && obj.msg) {
                        Message(i18n.t('reg_query_lang.Social_unified_coding')) // 请输入正确的社会统一编码
                    }
                    break
                }
            case 'email':
                {
                    bol = /^[_a-zA-Z0-9\-\.]+@([\-_a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,3}$/.test(obj.val)
                    if (!bol && obj.msg) {
                        this.$message('邮箱格式错误')
                    }
                    break
                }
            case 'telephone':
                { // 固定电话
                    bol = /^(\d{3,4}-?)?\d{7,9}$/g.test(obj.val)
                    if (!bol && obj.msg) {
                        this.$message('电话格式错误')
                        return
                    }
                    break
                }
            case 'cellphone':
                { // 手机
                    bol = /^(((13[0-9]{1})|(15[0-35-9]{1})|(17[0-9]{1})|(18[0-9]{1}))+\d{8})$/.test(obj.val)
                    if (!bol && obj.msg) {
                        this.$message('手机格式错误')
                        return
                    }
                    break
                }
            case 'QQ':
                { // QQ
                    bol = /^[1-9]\d{4,12}/.test(obj.val)
                    if (!bol && obj.msg) {
                        this.$message('QQ格式错误')
                        return
                    }
                    break
                }
            case 'zipCode':
                {
                    if (!obj.val && obj.msg) { // 0628添加
                        this.$message('请输入邮编')
                        return
                    }
                    bol = /^[0-9]{6}$/.test(obj.val)
                    if (!bol && obj.msg) {
                        this.$message('邮编格式错误') // 不用翻译
                    }
                    break
                }
            case 'phone':
                {
                    if (!obj.val && obj.msg) { // 0628添加
                        this.$message('请输入联系电话')
                        return
                    }
                    let telephone = /^(\d{3,4}-?)?\d{7,9}$/g
                    let cellPhone = /^(((13[0-9]{1})|(15[0-35-9]{1})||(17[0-9]{1})|(18[0-9]{1}))+\d{8})$/
                    bol = telephone.test(obj.val) || cellPhone.test(obj.val)
                    if (!bol && obj.msg) {
                        this.$message('电话号码格式错误') // 不用翻译
                    }
                    break
                }
            case 'idCard':
                {
                    let reg1 = /^(\d{6})()?(\d{2})(\d{2})(\d{2})(\d{2})(\w)$/
                    let reg2 = /^(\d{6})()?(\d{4})(\d{2})(\d{2})(\d{3})(\w)$/
                    bol = reg1.test(obj.val) || reg2.test(obj.val)
                    if (!bol && obj.msg) {
                        this.$message(this.$t('reg_query_lang.Certificate_number_format_error')) // 证件号码格式错误
                    }
                    break
                }
            case 'passport':
                {
                    let reg = /^1[45][0-9]{7}|([P|p|S|s]\d{7})|([S|s|G|g]\d{8})|([Gg|Tt|Ss|Ll|Qq|Dd|Aa|Ff]\d{8})|([H|h|M|m]\d{8，10})$/
                    bol = reg.test(obj.val)
                    if (!bol && obj.msg) {
                        // this.$message(this.$t('reg_query_lang.Certificate_number_format_error'))// 证件号码格式错误
                    }
                    break
                }
        }
        return bol
    }
    /**
     * 来自appUtils.js,用于输入校验
     */
export const isInputEn = function(str) {
    var strSource = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789,./\?;:'!<>{}[]+《》、-()@%&*＇。（）~- ：.\"’“”#"
    var ch
    var i
    var temp
    var flag = true
    if (str.length == 0) {
        flag = false
    } else {
        for (i = 0; i <= (str.length); i++) {
            ch = str.charAt(i)
            temp = strSource.indexOf(ch)
            if (ch != ' ' && temp == -1) {
                flag = false
            }
        }
    }

    return flag
}

export const fomat_qh = function(quhua) {
        return true // 英文版,直接返回true;
        var addr = quhua.toString()
        var s = document.getElementById('appGjdq')
        if (s == null || undefined == s) {
            // console.log("获取国籍内容失败,请检查国籍命名");
            return false
        }
        if (undefined == s.options || s.options[s.selectedIndex] == null) {
            // console.log("中文地址栏已被锁住无法修改");
            return true
        }
        var a = s.options[s.selectedIndex].innerText
        var reFlag
        if (a.indexOf('国外') != -1) { // 是国外
            reFlag = true
        } else {
            if (addr.indexOf('(') > -1) {
                addr = addr.replace('(', '（')
            }
            if (addr.indexOf(')') > -1) {
                addr = addr.replace(')', '）')
            }
            var ary = new Array('北京市', '天津市', '河北省', '山西省', '内蒙古自治区', '内蒙古省', '辽宁省', '吉林省', '黑龙江省', '上海市', '江苏省', '浙江省', '安徽省', '福建省', '江西省', '山东省', '河南省', '湖北省', '湖南省', '广东省', '广西壮族自治区', '广西省', '海南省', '重庆市', '四川省', '贵州省', '云南省', '西藏自治区', '西藏省', '陕西省', '甘肃省', '青海省', '宁夏回族自治区',
                '宁夏省', '新疆维吾尔自治区', '新疆省', '香港特别行政区', '香港', '中国香港', '澳门特别行政区', '中国澳门', '澳门', '台湾省', '中国台湾', '台湾', '新疆生产建设兵团', '中国（上海）自由贸易试验区', '中国（广东）自由贸易试验区', '中国（天津）自由贸易试验区', '中国（福建）自由贸易试验区', '中国（辽宁）自由贸易试验区', '中国（浙江）自由贸易试验区', '中国（河南）自由贸易试验区', '中国（湖北）自由贸易试验区', '中国（重庆）自由贸易试验区', '中国（四川）自由贸易试验区', '中国（陕西）自由贸易试验区')
            reFlag = false
            for (var i = 0; i < ary.length; i++) {
                if (addr.indexOf(ary[i]) > -1) {
                    reFlag = true
                    break
                }
            }
        }
        return reFlag
    }
    /**
     * 深拷贝
     */
export const deepClone = function(obj) {
    //判断拷贝的要进行深拷贝的是数组还是对象，是数组的话进行数组拷贝，对象的话进行对象拷贝
    var objClone = Array.isArray(obj) ? [] : {};
    //进行深拷贝的不能为空，并且是对象或者是
    if (obj && typeof obj === "object") {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (obj[key] && typeof obj[key] === "object") {
                    objClone[key] = deepClone(obj[key]);
                } else {
                    objClone[key] = obj[key];
                }
            }
        }
    }
    return objClone;
}
// export const translateCountry = async function(arr) {
//     const data = await tbOrder.queryAllCountry()
//     let arr1 = []
//     for (var j = 0; j < arr.length; j++) {
//         for (var i = 0; i < data.length; i++) {
//             if (arr[j] == data[i].counKey) {
//                 arr1.push(data[i].counName)
//             }
//         }
//     }
//     return arr1
// }





//     const  data  =  await  tbOrder.queryAllCountry()    
//     console.log(data, 'data---utils')
//     let obj = {};    
//     data.map(item  =>  {        
//         obj[item.counKey] = obj[item.counName]        
//         return  item    
//         console.log(item, 'item')
//     });
//     return  arr.map(item  =>  obj[item]).join('or');
