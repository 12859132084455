import {get, post } from './http'
export const SMS = params => get('/admin/mobile/sendCode/' + params)
export const VXCHECK = params => post('/admin/user/vxCheck',params)
//用户名账号密码登录
export const account = params => get('/auth/oauth/token',{params,
  header:{
    Authorization: 'Basic aXBpeGVsOmlwaXhlbA=='
  }
})
//用户注册接口
export const regist = params => post('/auth/user/regist',params)
//手机号短信验证
export const smsVerification = params => post('/auth/user/check',params)
//找回密码接口
export const retrieve = params => post('/auth/user/retrieved',params)
//快捷登陆验证用户是否存在
export const loginCheck = params => get('/auth/user/loginCheck',params)
// 获取验证图片  以及token
export const reqGet = params => get('/code',params)
// 滑动或者点选验证
export const reqCheck = params => post('/code/check',params)
