export default {
    classifyChoosed: state => {
        let arr = [];
        let arrAll = [];
        state.queryData.classify.map((item) => {
            if (item.isQuery) arr.push(item.no);
            arrAll.push(item.no);
        });
        return arr;
        /*
          //如果用此,则查询按钮中,0408代码则为冗余;
          if (arr.length) return arr;
            else return arrAll;
        */
    },
    /*190129添加便于查询已选中的类别,未选中的不查询*/
    classifyStatus: state => {
        let showArr = [],
            chosenArr = [],
            allArr = [];
        state.queryData.classify.map((item) => {
            if (item.show0129) showArr.push(item);
            if (item.chosen0129) chosenArr.push(item);
            allArr.push(item);
        });
        let queryKey = "";
        if (!showArr.length) {
            queryKey = "allStr";
        } else if (chosenArr.length) {
            queryKey = "chosenStr";
        } else {
            queryKey = "showStr";
        }
        return {
            queryKey,
            showArr,
            chosenArr,
            allArr,
            showLength: showArr.length,
            chosenLength: chosenArr.length,
            showStr: showArr.map(item => item.no).join(";"),
            chosenStr: chosenArr.map(item => item.no).join(";"),
            allStr: allArr.map(item => item.no).join(";"),
        }
    },
    viennaChoosedObj: state => {
        let arr = [];
        state.queryData.viennaChoosedArr.map((item) => {
            if (item.indexOf('.') !== item.lastIndexOf('.')) {
                item = item.substring(0, item.lastIndexOf('.'))
            }
            let arr1 = state.noReset.viennaSecondObj.find(i => i.zlbm == item);
            arr1 && arr.push(arr1);
        });
        return arr.map(i => i.isCheck = i.isQuery = true);
    },
}