<template>
  <div id="app">

    <headers  v-if="!$route.meta.activity"></headers>

    <keep-alive>
      <!-- 需要缓存的视图组件 -->
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <!-- 不需要缓存的视图组件 -->
    <router-view v-if="!$route.meta.keepAlive"></router-view>

    <loading v-if='LOADING' />
  </div>
</template>

<script>
import headers from "@/components/common/header";
import loading from '@/components/common/loading'
import { mapState, mapMutations } from "vuex";
import { USER, NOTIFY } from "@/request/common";
import { getUserInfos } from "@/request/active";

export default {
  name: "App",
  components: {
    headers, loading
  },
  mounted () {

    document.onkeydown = () => {
      //禁用F12
      if (window.event && window.event.keyCode == 123) {
        return false;
        //禁用ctrl+shift+i,
      } else if (window.event.ctrlKey && window.event.shiftKey && window.event.keyCode == 73) {
        return false;
        //屏蔽Shift+F10
      } else if (window.event.shiftKey && window.event.keyCode == 121) {
        return false;
      }
    };
    let _this = this;
      if (_this.tool.getCookie("auth")) {
        _this.getUserInfo();
        _this.pageAjax();
      } else {
        _this.setMe("");
        _this.setNoticeList("");
      }
    this.getWechatConfig();
  },
  computed: {
    ...mapState([
      'LOADING'
    ])
  },
  methods: {
    ...mapMutations(["setMe", "setNoticeList"]),
    // 获取微信配置信息
    getWechatConfig(){
      getUserInfos({
        url:location.href.split('#')[0],
        appId:'wx803c8df5d9baf30f'
      }).then(function(response){
        if(response.code == 0){
          let data = response.data;
          wx.config({
            appId: data.appId, // 必填，公众号的唯一标识
            timestamp: data.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.nonceStr, // 必填，生成签名的随机串
            signature: data.signature,// 必填，签名
            jsApiList:  [
              // 所有要调用的 API 都要加到这个列表中
              'checkJsApi',
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'onMenuShareQQ',
              'onMenuShareQZone',
              'updateAppMessageShareData',
              'updateTimelineShareData'
            ]// 必填，需要使用的JS接口列表
          })
          wx.ready(()=>{
            // util.initShareInfo(wx);
            let shareInfo = {
              title: '2021知识产权年会&世界知识产权日活动（3.25-4.30）', // 分享标题
              desc: '活动期间下单购买产品，全场5折；同时关注公众号，购买产品即享折上8折', // 分享描述
              link: `${window.location.origin}/#/activity`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: `${window.location.origin}/images/logos.png`, // 分享图标
            }
            // wx.onMenuShareAppMessage(shareInfo);
            // wx.onMenuShareTimeline(shareInfo);
            // wx.onMenuShareQQ(shareInfo);
            // wx.onMenuShareQZone(shareInfo);

            wx.updateAppMessageShareData(shareInfo);
            wx.updateTimelineShareData(shareInfo);

          })
        }
      })
    },
    // 获取用户信息
    async getUserInfo () {
      let user = await USER();
      if (user.code == 0) {
        this.setMe(user.data);
      }
    },
    // 获取通知消息
    async pageAjax () {
      let notify = await NOTIFY();
      if (notify.code == 0) {
        this.setNoticeList(notify.data.records);
      }
    }
  }
};
</script>

<style lang="less">
#app{
  font-size: 16px;
}
body{
  font-size: 16px;
}
html,body{
  font-family: SourceHanSansCN-Light!important;
}
.vxe-table--render-default{
  font-family: SourceHanSansCN-Light!important;
}

</style>
