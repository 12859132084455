import tool from '@/assets/js/tool'
// 提交 mutations是更改Vuex状态的唯一合法方法
export const modifyAName = (state, name) => { // A组件点击更改餐馆名称为 A餐馆
  state.resturantName = name // 把方法传递过来的参数，赋值给state中的resturantName
}
export const modifyBName = (state, name) => { // B组件点击更改餐馆名称为 B餐馆
  state.resturantName = name
}

export const mutations = {
  /** 设置当前设备标记 */
  setDevice(state, isMobile) {
    state.isMobile = isMobile;
  },
  // 设置token
  setToken(state, token) {
    state.token = token
    tool.setCookie("auth", token, 30)
  },
  /** 设置当前登录用户信息 */
  setMe(state, me) {
    state.me = me;
  },
  /** 设置当前语言 */
  setLocale(state, locale) {
    if (state.locales.includes(locale)) {
      state.locale = locale;
      tool.setCookie('locale', locale);
      this.app.i18n.locale = locale;
    }
  },
  /** 通知消息 */
  setNoticeList(state, noticeList) {
    state.noticeList = noticeList;
  },
  // 导航背景颜色
  setHeaderBgBool(state, bool) {
    state.headerBgBool = bool
  },
  loadingBool(state, bool) {
    state.LOADING = bool
  },

  //设置高级检索日志
  ADSEARCHLOG(state, data) {
    state.adSearchLogs = data
    localStorage.setItem("adSearchLogs", JSON.stringify(data));
  },

  //专利翻译
  setPatentTranslation(state, value) {
    state.patentTranslation = value;
  },

  //商标
  setTrademark(state, value) {
    state.trademark = value;
  },

  //专利检索
  setPatentSearch(state, value) {
    state.patentSearch = value;
  },

  //设置来源
  setUserSource(state, value) {
    state.userSource = value;
  },

  //监控添加参数
  setTmPay(state, value) {
    state.tmPay = value;
  }
}
