import axios from 'axios'
import store from "@/store"
import tool from '@/assets/js/tool'
import router from '@/router'
import { Message } from 'element-ui';
//当前API接口地址
// axios.defaults.baseURL = process.env.API_ROOT;
//延迟时间
axios.defaults.timeout = 125 * 1000 * 5000; // 60 * 60 * 1000
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8;multipart/form-data'
//
axios.defaults.headers.common['TENANT-ID'] = 4;


let loaingArr = [
  '/common/query/analyzeReportV2',
  '/aigc/app/new',
  '/custody/home/applicant/info/summary',
  '/custody/business/query/trademark/order/task/for/admin/page',
  '/custody/business/query/annuity/order/task/for/admin/page',
  '/custody/home/remind/list',
  '/custody/trademark/renewal/five/trademark/count',
  '/custody/renewal/order/page',
  '/custody/home/save/update/custody',
  '/custody/home/save/custody',
  '/custody/trademark/renewal/payment/order',
  '/custody/home/applicant/info/summary',
  '/custody/trademark/renewal/modify/manage',
  '/custody/software/cancel',
  '/custody/certificate/queryCertificateList',
  '/custody/trademark/renewal/page',
  '/custody/certificate/save',
  '/custody/certificate/cancel',
  '/custody/agent/queryArea',
  '/custody/software/querySoftwareList',
  '/custody/software/save',
  '/custody/software/setSoftwareUrl',
  '/custody/certificate/setCertificateUrl',
  '/custody/home/applicant/name/summary',
  '/custody/agent/queryAgent',
  '/custody/home/applicant/info/summary',
  '/custody/home/applicant/name/summary',
  '/trademark/monitoring/exportManyCountryMonitoringDetail',
  '/common/query/idListQuery',
  '/common/query/invalidNoticeExcelDownload',
  '/common/query/getInvalidNotice',
  '/trademark/monitoringRenewal/batch/update/amount',
  '/trademark/monitoringRenewal/batch/save',
  '/trademark/monitoringRenewal/page',
  '/trademark/monitoring/createMonitoringImageOrWord',
  '/trademark/iphouseApi/ReviewCaseDetail',
  '/trademark/iphouseApi/judgementDocumentDetail',
  '/trademark/iphouseApi/courtNoticeDetail',
  '/lantern/lanresource/list',
  '/trademark/iphouseApi/judgementDocumentDetail',
  '/trademark/iphouseApi/reviewCase',
  '/trademark/iphouseApi/judgementDocument',
  '/trademark/iphouseApi/courtNotice',
  '/trademark/iphouseApi/reviewCaseAdvanced',
  //商标对比xls下载
  '/common/query/tmCompare/excelExport',
  //获取订购购买服务列表接口
  '/product/productservicemanage/getPrePublishServiceList',
  //商标对比
  '/common/query/tmCompare',
  //上传文档商标对比接口
  '/lantern/api/trademark/ocr/officalfile',
  //获取公告多页pdf
  '/common/query/getNoticePages',
  //获取公告概况信息
  '/common/query/getNoticOverView',
  //获取公告数据（带公告pdf）
  '/common/query/getNoticeInfo',
  //商标查询
  //  '/common/query/groupStatistics',
  '/trademark/monitoring/getUserMonitorings',
  //类似群组查询接口
  '/common/query/similargroup',
  //添加商标监控
  '/trademark/monitoring/createMonitoring',
  '/trademark/trademark/generateUrl',
  //商品分类
  '/common/query/tmNice',
  //商标查询xls导出
  '/common/query/excelExport',
  //商标查询word导出
  '/common/query/wordExportForLantern',
  //获取近似群组
  '/common/query/tmCrossSearch',
  //商标监控删除
  '/trademark/monitoring/delMonitoringTask',
  //图片上传接口
  '/admin/sys-file/upload',
  //监控详情结果导出
  '/trademark/monitoring/monitoringResultExport',
  //获取监控列表页详情
  '/trademark/monitoring/getUserMonitoringDetails',
  //商标监控报告导出
  '/trademark/monitoring/userMonitoringExport',
  //商标监控结果列表
  '/trademark/monitoring/getUserMonitoringReport',
  //商标监控列表
  '/trademark/monitoringcountry/queryAllCountry',
  '/common/query/countryStatisic',
  '/patent/excel/appNosOrPubNosExcelImport',
  '/common/query/countryStatisicForLantern',
  '/common/query/goodsServiceForLantern',
  '/common/query/appAndRegForLantern',
  '/common/query/countryStatisicAllForLantern',
  '/order/invoicetitle',
  '/lantern/jurisdiction/getTrademarkServiceAuthority',
  '/lantern/translateLan/verificationDoc',
  '/lantern/translateLan/docTanslate',
  '/patent/search/getVecSimilarWord',
  '/patent/search/searchAggregation',
  '/patent/search/searchPatent',
  '/patent/excel/dimensionExcelExport',
  '/patent/download/searchPatentReport',
  '/common/query/searchByIds',
  '/common/query/search/v2',
  '/common/graph/search/v2',
  '/common/query',
  '/common/query/analyzeSuccessRate',
  '/admin/contact/getContact',
  '/product/servicemanage/getUserServiceManages',
  '/order/order/getOrderLanternfishPage',
  '/lantern/lanlog/page',
  '/common/file',
  '/patent/download/exportReport',
  '/admin/article/getArticleList',
  '/admin/article/getArticleRecord'
];


let noTokenArr = [
  '/common/code/sms'
]
// 请求拦截器
axios.interceptors.request.use(
  config => {
    let responseURL = config.url.trim();
    //判断请求的接口是否需要loading
    if (loaingArr.includes(responseURL)) {
      store.commit("loadingBool", true);
    }

    //增加几口时间戳
    if (config.method == 'get') {
      config.params = {
        _t: Date.parse(new Date()) / 1000,
        ...config.params
      }
    }

    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    // const token = store.state.token;
    let token = tool.getCookie("auth");
    if (token) {
      //给需要的接口增加token
      if (!noTokenArr.includes(responseURL)) {
        config.headers.common['Authorization'] = `Bearer ${token}`
      }
    } else {
      //如果token不存在----------清空个人信息
      store.commit('setMe', '');
      //清空消息提示
      store.commit('setNoticeList', '');
    }
    return config
  },
  error => {
    return Promise.error(error)
  })

// 响应拦截器
axios.interceptors.response.use(
  response => {
    if (response.status) {

      switch (response.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 424:
          tool.clearCookie('auth');
          store.commit('setMe', '');
          store.commit('setNoticeList', '');
          Message.error('请先登录！');
          // Toast({
          //     message: '网络请求不存在',
          //     duration: 1500,
          //     forbidClick: true
          // })
          break
        case 401:
          tool.clearCookie('auth');
          store.commit('setMe', '');
          store.commit('setNoticeList', '');
          Message.error('请先登录！');
          break
        // 404请求不存在
        case 404:
          // Toast({
          //     message: '网络请求不存在',
          //     duration: 1500,
          //     forbidClick: true
          // })
          break
        // 其他错误，直接抛出错误提示
        default:
        // Toast({
        //     message: error.response.data.message,
        //     duration: 1500,
        //     forbidClick: true
        // })
      }
    }
    //获取接口名和参数的数组
    let responseURL = response.config.url ? response.config.url.trim().split("?")[0] : "";
    //取消loading
    if (loaingArr.includes(responseURL)) {
      store.commit("loadingBool", false);
    }
    if (response.status === 200) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  // 服务器状态码不是200的情况
  error => {
    let responseURL = error.request.responseURL ? error.request.responseURL.trim().split("?")[0] : "";
    // if (loaingArr.includes(responseURL)) {
      store.commit("loadingBool", false);
    // }
    if (error.status) {

      switch (error.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 424:
          tool.clearCookie('auth');
          store.commit('setMe', '');
          store.commit('setNoticeList', '');
          Message.error('请先登录！');
          // Toast({
          //     message: '网络请求不存在',
          //     duration: 1500,
          //     forbidClick: true
          // })
          break
        case 401:
          tool.clearCookie('auth');
          store.commit('setMe', '');
          store.commit('setNoticeList', '');
          Message.error('请先登录！');
          break
        // 404请求不存在
        case 404:
          // Toast({
          //     message: '网络请求不存在',
          //     duration: 1500,
          //     forbidClick: true
          // })
          break
        // 其他错误，直接抛出错误提示
        default:
          console.log(error);
        // Toast({
        //     message: error.response.data.message,
        //     duration: 1500,
        //     forbidClick: true
        // })
      }
    }
    return Promise.reject(error)
  }
)

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err.data)
    })
  })
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, params)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function put(url, params) {
  return new Promise((resolve, reject) => {
    axios.put(url, params)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}
