<template>
    <transition name="el-fade-in-linear">
        <div class="container">
            <div class="spinner">
                <div class="dot1"></div>
                <div class="dot2"></div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    methods: {
    },

}
</script>

<style scoped>
.container{
  position: fixed;
  top: 90px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 90px);
  background: hsla(0, 0%, 100%, .5);
  z-index: 3000;
  display: flex;
}
.spinner::before{
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    filter:blur(20px);
}
.spinner {
  margin: auto;
  width: 60px;
  height: 60px;
  position: relative;
  text-align: center;

  -webkit-animation: sk-rotate 2.0s infinite linear;
  -moz-animation: sk-rotate 2.0s infinite linear;
  -o-animation: sk-rotate 2.0s infinite linear;
  animation: sk-rotate 2.0s infinite linear;
}

.dot1, .dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  z-index: 100;
  background-color: #2f56de;
  border-radius: 200px;

  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  -moz-animation: sk-bounce 2.0s infinite ease-in-out;
  -o-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1.0s;
  -moz-animation-delay: -1.0s;
  -o-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-rotate { 100% { -webkit-transform: rotate(360deg) }}
@-moz-keyframes sk-rotate { 100% { -webkit-transform: rotate(360deg) }}
@-o-keyframes sk-rotate { 100% { -webkit-transform: rotate(360deg) }}
@keyframes sk-rotate { 100% { transform: rotate(360deg); -webkit-transform: rotate(360deg) }}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@-moz-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}
@-o-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}
@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}
</style>
