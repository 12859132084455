import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import { classifyData } from '../../../components/combinedQuery/data'

// stateBase可用来重置;
/**
 目前记录查询模式的字段有:
 queryItemsShow.fastOrHigh//查询模式,0快速查询,1高级查询,
 queryData.fastMode//快速查询的查询模式,近似商标0还是申请号1,申请人2
 othersItem.queryRecently//最近的查询模式,,0快速查询,1高级查询 long-0711.1
 queryData.queryWay记录高级查询的是近似查询还是?
 **/
export let stateBase = {
    /* 显隐控制 */
    queryItemsShow: {
        condition: false, // 查询条件,
        more: false, // 更多按钮的显示
        moreType: true, // 显示更多按钮的类型,true显示更多,false,显示收起
        analyze: false, // 可注册性分析,图片查询的时候才显示,与result一同显示
        result: false, // 查询结果
        cameraBtn: true, // 搜索栏,相机按钮
        classifyBtn: true, // 搜索栏,分类按钮
        fastOrHigh: 0, // 查询模式,0快速查询,1高级查询
        isShow: { // 快速查询中,模块的显示隐藏
            classify: false,
            upload: false,
            progressBar: false
        },
        interval: '', /// /快速查询中,定时器
        progressNum: 0, // 进度值
        zfOne: false,
        noDataBol: false // 暂无数据是否显示
    },
    /* 非显隐控制类型的数据 */
    queryData: {
        countryStatusVuex: 0, // 选中国家后的状态值，0为tm5国家，1为其余国家，2为既有tm5国家又有其余国家
        tmstatuscode: '', // 商标状态
        isFirstQuery: false, // 是否第一次查询
        imgOriginalBase64: '', // 1012上传图片的原始base64
        position: {}, // 1012图片剪切后,左上角坐标,选框宽高;
        imgStyle: {},
        queryMode: 0, // 查询模式,近似商标（图片或者名称）0,申请号1,申请人2
        isCanUploadImg: true,
        imgLocalUrl: '', // 查询图片的file,0201用来本地显示,放入this.updataUrl()并生成可视的图片
        imgFile: { url: '' }, // 存储上传的图片文件,file格式;
        lastImgFile: null, // 存储最近的图片,file格式;
        imgDefault: '/images/default.png', // 默认查询图片,
        littlePicUrl: '',
        imgLineUrl: '', // 查询图片的url,0201用来存储峰哥返回的group1/M00/00/1C/hoYCzFpyco6AKxpjAAAysZsrydo415.jpg,用于传给后台;
        classify: JSON.parse(JSON.stringify(classifyData)), // 分类,45大类
        classifyDefault: classifyData, // 使用的时候,请深度复制
        degree: [
            { level: 'high', isQuery: false, 'name': '高', name_cn: '高', name_en: 'High' },
            { level: 'middle', isQuery: false, 'name': '中', name_cn: '中', name_en: 'Medium' },
            { level: 'low', isQuery: false, 'name': '低', name_cn: '低', name_en: 'Low' }
        ], // 近似度,all为所有,high,middle,low
        statusList: {
            //中国大陆法律状态
            TM5_list: [{
                    zhName: "已注册",
                    enName: "Registered",
                    index: "1",
                    isCheck: false,
                    isQuery: true
                },
                {
                    zhName: "无效",
                    enName: "Invalid",
                    index: "2",
                    isCheck: false,
                    isQuery: true
                },
                {
                    zhName: "公告中",
                    enName: "Published for Opposition",
                    index: "3",
                    isCheck: false,
                    isQuery: true
                },
                {
                    zhName: "驳回复审审查中",
                    enName: "Appeal of Refusal Pending",
                    index: "4",
                    isCheck: false,
                    isQuery: true
                },
                {
                    zhName: "异议审查中",
                    enName: "Opposition Pending",
                    index: "5",
                    isCheck: false,
                    isQuery: true
                },
                {
                    zhName: "注册申请审查中",
                    enName: "Under Examination",
                    index: "6",
                    isCheck: false,
                    isQuery: true
                },
                {
                    zhName: "撤销/无效宣告申请审查中",
                    enName: "Cancellation/Invalidation Pending",
                    index: "7",
                    isCheck: false,
                    isQuery: true
                },
                {
                    zhName: "其他情形",
                    enName: "Others",
                    index: "8",
                    isCheck: false,
                    isQuery: true
                }
            ],
            OTHER_list: [{
                    zhName: "无效",
                    enName: "Invalid",
                    index: "9",
                    isCheck: false,
                    isQuery: true
                },
                {
                    zhName: "已注册",
                    enName: "Registered",
                    index: "10",
                    isCheck: false,
                    isQuery: true
                },
                {
                    zhName: "公告中",
                    enName: "Publication",
                    index: "11",
                    isCheck: false,
                    isQuery: true
                },
                {
                    zhName: "审查中",
                    enName: "Pending",
                    index: "12",
                    isCheck: false,
                    isQuery: true
                }
            ],
            COMBINE_list: [{
                    zhName: "已注册",
                    enName: "Registered",
                    index: "1;10",
                    isCheck: false,
                    isQuery: true
                },
                {
                    zhName: "无效",
                    enName: "Invalid",
                    index: "2;9",
                    isCheck: false,
                    isQuery: true
                },
                {
                    zhName: "公告中",
                    enName: "Published for Opposition",
                    index: "3;11",
                    isCheck: false,
                    isQuery: true
                },
                {
                    zhName: "驳回复审审查中",
                    enName: "Appeal of Refusal Pending",
                    index: "4",
                    isCheck: false,
                    isQuery: true
                },
                {
                    zhName: "异议审查中",
                    enName: "Opposition Pending",
                    index: "5",
                    isCheck: false,
                    isQuery: true
                },
                {
                    zhName: "注册申请审查中",
                    enName: "Under Examination",
                    index: "6",
                    isCheck: false,
                    isQuery: true
                },
                {
                    zhName: "撤销/无效宣告申请审查中",
                    enName: "Cancellation/Invalidation Pending",
                    index: "7",
                    isCheck: false,
                    isQuery: true
                },
                {
                    zhName: "其他情形",
                    enName: "Others",
                    index: "8",
                    isCheck: false,
                    isQuery: true
                },
                {
                    zhName: "审查中",
                    enName: "Pending",
                    index: "12",
                    isCheck: false,
                    isQuery: true
                }
            ]
        },
        status: '1;2;3;4;5;6;7', // 选中的商标状态
        statusEurope: "1;2;3;4;5;6;7;8;9;10;11;12;13",
        countryList: [],
        country: ['CN'],
        countryName: '中国大陆',
        showTmUpdateDate: 'CN',
        // highMode:0,//高级查询的文字查询模式,0.Identical search,1.Contained search,2.Similar search
        tradeMarkText: '', // 商标文字
        // tradeMarkPattern: "",//商标图形,暂时使用imgLineUrl
        // patternElement: "",//图形要素编码
        // classify: "",//分类
        viennaChoosedStr: '', // 选择的维也纳分类拼成的串
        // vienna: [],//见noReset
        // viennaThirdText:[],//见noReset
        viennaFromAI: [],
        viennaChoosedArr: [], // 选中的图形像素数组
        niceChoosedStr: '', // 选择的尼斯分类
        applicationNumber: '', // 申请号
        registerNumber: '', //注册号
        tmapplicantArr: [], //申请人查询结果数组
        tmApplicant: '', // 申请人名称
        tmAddress: '', // 申请人地址
        trialDate: '', // 初审公告日
        regDate: '', // 注册日
        privateDate: '', // 专用权到期日
        tmAgent: '', // 代理机构
        queryWay: 'similar', // 精确查询term,近似查询ai,包含查询match
        fastSearch: '', // 快速查询的关键词.
        fastWordConfirm: "", //点击查询按钮,才改变的关键词;
        applyPerson: null,
        highQueryConditions: null,
        tabCountryName: '', // 查询结果页tab标签上选择的国家
    },
    /* 接口返回 */
    ajaxResponse: {
        queryTmInfo: [{ isQuery: false }], // 邱峰接口商标查询
        aiData: null, // AI接口返回所有数据
        screening: [], // 存储ai返回的申请号,近似度筛选后会更新此为满足近似度条件的注册号,进而分页后仍是当前近似度
        screeningAiGroup: [], // 将ai接口screening进行分组,每组othersItem.pageSize个;
        screeningAiTotal: 0, // ai接口返回数据总数量
        appnos: [], // 无论什么接口,都把注册号拿过来,目前只有图片查询导出到excel表格可使用
        ids: [] // 峰哥的接口,都把id拿过来,目前只有是申请人查询导出到excel表格使用;

    },
    /* 其他,如分页 */
    othersItem: {
        pageNum: 1,
        pageSize: 1,
        total: 0,
        total2: 0, // 实时查询的结果条目数,和分页无关;
        queryRecently: '', // 最近的查询模式
        checkAll: false, // 快速查询的全选按钮
        whereFast: false, // 快速查询的位置,false在中间,true在上面
        isRequest: false // 是否发起请求,
    },
    /* 暂时放外面 */
    pageNum_: 1,
    pageSize_: 56,
    /* 不可reset的数据 */
    noReset: {
        isPaied: '', // 是否付费
        vienna: [], // 接口获取到整个维也纳分类,,区别于老版本的维也纳
        viennaSecondText: [], // 包含所有的维也纳的二级的编码
        viennaSecondObj: [], // 包含所有的维也纳的二级
        viennaThirdText: [] // 包含所有的维也纳的最后一级
    },
    queryBtnClickNum: 0, // 按钮点击次数
    queryHistory: [], // 历史记录数据
    responseData: [], // 接口返回的数据（所有数据）
    applyPersonNum: 10, // 每次更新多少条申请人数据
    asideForm: { // 二次筛选条件参数
        country: [], // 国家
        applicant: [], // 申请人名称
        niceList: [], // 类号
        imageFactory: [], // 图形要素编码
        appYear: [], // 申请日
        regYear: [], // 注册日
        trailYear: [], // 初审公告日
        isvalid: [], // 商标有效性
        ggBeginYear: [], // 注册公告日
        priorityYear: [], // 优先权日
        rightPeriod: [], // 专用权期限
        tmstatuscode: [], // 商标状态
    },
}

export default {
    namespaced: true,
    state: JSON.parse(JSON.stringify(stateBase)),
    mutations,
    actions,
    getters
}
